import { useContext ,useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import AppContext from "../../../../../../partials/AppContext"
import DangerousAlert from "../../../../../../Shared/DangerousAlert"

const Delete = ({cancel ,reload ,id ,name ,gymId}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/sessions/${gymId}/${id}`
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .delete(entityUrl)
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} deleted successfully`)
            reload()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`Are you sure you want to permanently delete this "${name}"? Please note you can’t undo this action`}
                cancel={cancel}
                confirm={saveChanges}
                confirmLabel="Yes Delete"
            />
        </div>
    )
}

export default Delete