import "./style.css"

const Loading = props => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="data-loader-component"></div>
        </div>
    )
}

export default Loading