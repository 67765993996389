const DangerousAlert = (props) => {
    return (
        <div id="delete-alert" className="form-container">
            <div className="contain">
                <div className="header">
                    <div className="title">
                        <h4 className="heading">
                            {props.heading ? props.heading : 'Delete Alert'}
                        </h4>
                    </div>
                </div>
                <form>
                    <div className="section" style={{marginBottom: 0}}>
                        <p className="alert-message">
                            {props.message}
                        </p>
                        {props.children}
                    </div>
                    <div className="confirm">
                        <button
                            type="button"
                            className="btn btn-default col-sm-6"
                            onClick={props.cancel}>
                            Cancel
                        </button> 
                        <button
                            disabled={props.disabled ? props.disabled : false}
                            type="button"
                            className="btn btn-light col-sm-6"
                            onClick={props.confirm}>
                            {props.confirmLabel ? props.confirmLabel : 'Save changes'}
                        </button>
                    </div>
                </form>
        
            </div>
        </div>
    )
}

export default DangerousAlert