const ModalHeader = (props) => {
    return (
        <div className="header">
            <div className="title">
                <h4 className="heading"> {props.title ? props.title : 'Data Modal'} </h4>
            </div>
            <div className="header-icon" onClick={props.cancel}>
                <img alt="Gymme" src="/assets/svg/times-solid-form.svg"/>
            </div>
        </div>
    )
}

export default ModalHeader