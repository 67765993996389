const MessageActions = (props) => {
    return (
        <div className="actions">
            <button onClick={props?.print}>
                <i className="fas fa-print"></i>
            </button>
            <button onClick={props?.solve}>
                <i className="fas fa-check-circle"></i>
            </button>
            <button onClick={props?.spam}>
                <i className="fas fa-exclamation-circle"></i>
            </button>
        </div>
    )
}

export default MessageActions