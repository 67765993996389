import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import DangerousAlert from "../../../../Shared/DangerousAlert"

const Reject = ({cancel ,id ,name ,reload}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/member-request-gym`

    const confirm = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .put(`${entityUrl}/reject/${id}`)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            cancel()
            reload()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={`Reject "${name}"`}
                message={`Are you sure you want to permanently reject this gym "${name}" ? Please note you can’t undo this action`}
                confirmLabel="Yes Reject"
                confirm={confirm}
                cancel={cancel}
            />
        </div>
    )
}

export default Reject