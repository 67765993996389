import { useContext, useEffect, useState } from 'react'
import Navbar from '../../../partials/Navbar'
import axios from "../../../../Axios"
import Config from '../../../../Config'
import SearchBox from '../../../Shared/SearchBox'
import PrintButton from '../../../Shared/PrintButton'
import AccountCard from './AccountCard'
import { useAlert } from 'react-alert'
import './accounts.css'
import DeleteAccount from './DeleteAccount'
import AddAccount from './AddAccount'
import EditAccount from './EditAccount'
import AppContext from '../../../partials/AppContext'

const Accounts = (props) => {
    const alert = useAlert()
    const entity_url = `${Config.apiBaseUrl}/admin-accounts/admins`
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    let [accounts ,setAccounts] = useState([])
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeAdd ,setInvokeAdd] = useState(undefined)
    const {clearUserData ,userToken} = useContext(AppContext)

    const editItem = (itemId) => {
        setInvokeAdd(undefined)
        setInvokeDelete(undefined)
        setInvokeEdit(itemId)
    }

    const deleteItem = (itemId ,itemName) => {
        setInvokeEdit(undefined)
        setInvokeAdd(undefined)
        setInvokeDelete({itemId ,itemName})
    }

    const addItem = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(true)
    }

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(undefined)
    }

    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        axios
        .setAuthToken(userToken)
        .get(`${entity_url}?limit=100${keyword ? '&keyword=' + keyword : ''}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const rolesCount = response.data.data.length
            setAccounts(response.data.data)
            setHeaderTitle(`${rolesCount} Account${rolesCount > 1 ? 's' : ''}`)
        })
        .catch(err => {
            const error = {...err} ,response = error.response.data
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setAccounts ,setHeaderTitle ,entity_url ,alert])

    const searchTrigger = () => loadData()

    const printClick = () => Config.printByToken(`${entity_url}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar   navbarTitle="Accounts"/>
            </div>
            <div className="page-content">
                <div className="page-section header-box">
                    <div className="row header">
                        <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <button onClick={addItem} className="btn btn-light">Add Account</button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <div className="table-container">
                            {
                                accounts.map(
                                    account => <AccountCard editItem={editItem}
                                        deleteItem={deleteItem} key={account.id} account={account}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
                { invokeAdd ? <AddAccount cancel={cancelModal} reloadData={loadData} /> : '' }
                { invokeEdit ? <EditAccount accountId={invokeEdit} cancel={cancelModal} reloadData={loadData} /> : '' }
                { invokeDelete ? <DeleteAccount itemId={invokeDelete.itemId} cancel={cancelModal} reloadData={loadData} itemName={invokeDelete.itemName} /> : ''}
            </div>
        </div>
    )
}

export default Accounts