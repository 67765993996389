import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"

const Delete = ({cancel ,reloadData ,entityUrl ,id ,name}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const confirm = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${id}` ,{_method: 'delete'})
        .then(response => {
            setDisabled(false)
            const message = response.data?.message
            alert.success(`${message ? message : 'Banner Ad deleted successfully'}`)
            reloadData()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`Are you sure you want to permanently delete this "${name}"? Please note you can’t undo this action`}
                cancel={cancel}
                confirm={confirm}
                confirmLabel="Yes Delete"
            />
        </div>
    )
}

export default Delete