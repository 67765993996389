import { useContext, useEffect, useState } from 'react'
import ModalHeader from '../../../../FormShared/ModalHeader'
import ModalButtons from '../../../../FormShared/ModalButtons'
import GymInput from './GymInput'
import Axios from '../../../../../Axios'
import { useAlert } from 'react-alert'
import AppContext from '../../../../partials/AppContext'
import Config from '../../../../../Config'

const Edit = ({cancel ,reload ,id}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym`
    const [disabled ,setDisabled] = useState(false)
    const [photo ,setPhoto] = useState(undefined)
    const [data ,setData] = useState({
        name: '' ,name_ar: '' ,description_ar: '' ,description_en: '' ,email: '' ,
        longitude: '' ,latitude: '' ,phone: '' ,address: '' ,bar: [] ,type: [] ,defaultCover: undefined
    })
    const [errors ,setErrors] = useState({
        name: '' ,name_ar: '' ,description_ar: '' ,description_en: '' ,email: '' ,
        longitude: '' ,latitude: '' ,phone: '' ,address: '' ,bar: '' ,type: '' ,cover: ''
    })
    const [loaded ,setLoaded] = useState(false)

    const confirm = () => {
        const formData = new FormData()
        Object.keys(data).forEach(key => {
            if (key === 'defaultCover') return;
            else if (key !== 'bar' && key !== 'type') formData.append(key ,data[key])
            else data[key]?.forEach?.((value ,indx) => formData.append(`${key}[${indx}]` ,value))
        })
        if (photo) formData.append('cover' ,photo)
        formData.append('_method', 'PUT')
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${id}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            reload()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeAttr = (value ,attr) => {
        const tempErrors = {...errors}
        tempErrors[attr] = ''
        setErrors(tempErrors)
        if (attr === 'cover') {
            setPhoto(value)
            return
        }
        const tempData = {...data}
        tempData[attr] = value
        setData(tempData)
    }

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setLoaded(true)
            setData(response?.data?.data)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    } ,[id ,userToken ,entityUrl ,alert ,clearUserData])

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Edit Gym" cancel={cancel}/>
                    <form>
                        {
                            loaded ?
                            <>
                                <GymInput
                                    defaultImg={data?.defaultCover}
                                    data={data}
                                    setData={setData}
                                    errors={errors}
                                    setErrors={setErrors}
                                    changeAttr={changeAttr}
                                />
                                <ModalButtons disabled={disabled} cancel={cancel} confirm={confirm} confirmLabel="Edit Gym" />
                            </>
                            : <h3> Loading.. </h3>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit