import {NavLink} from 'react-router-dom'

const GymRow = (props) => {
    return (
        <tr>
            <td>
                {
                    props?.gym?.logo ?
                    <img alt={props?.gym?.name} src={props?.gym?.logo} className="gym-logo" />
                    : ''
                }
            </td>
            <td>
                <NavLink to={`/gyms/${props?.gym?.id}`} exact>
                    { props?.gym?.name }
                </NavLink>
            </td>
            <td> { props?.gym?.registration_date } </td>
            <td> { props?.gym?.phone } </td>
            <td> { props?.gym?.email } </td>
            <td> { props?.gym?.renewal_date } </td>
            <td> { props?.gym?.members } </td>
            <td> { props?.gym?.subscription_name } </td>
            <td> { props?.gym?.subscription_price } </td>
            <td className="actions">
                <button onClick={() => props.renewal(props?.gym?.id ,props?.gym?.name)}>
                    <i className="fas fa-expand-arrows-alt"></i>
                </button>
                <button onClick={() => props.deactivate(props?.gym?.id ,props?.gym?.name)}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default GymRow