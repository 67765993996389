import FieldError from '../../../../FormShared/FieldError'
import Input from '../../../../FormShared/Input'
import PhotoInput from '../../../MoreSection/Accounts/PhotoInput'
import BarInput from './BarInput'
import TypeInput from './TypeInput'

const GymInput = ({data ,setData ,errors ,setErrors ,changeAttr ,defaultImg = undefined}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignContent: 'center'
        }}>
            <div className='flex-grid-row'>
                <div className='form-group flex-grid-col-50'>
                    <PhotoInput title="Cover Image" message="Cover`ll be here" defaultImg={defaultImg}
                        updatePhoto={(img) => changeAttr(img ,'cover')} error={errors.cover} />
                </div>
                <div className="form-group flex-grid-col-50">
                    <div style={{
                        width: '100%',
                        height: 305,
                        backgroundColor: 'rgba(221 ,221 ,221)',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center'
                    }}>
                        map here
                    </div>
                </div>
            </div>
            <div className='flex-grid-row'>
                <div className="form-group flex-grid-col-50">
                    <Input placeholder="Gym Name" value={data?.name}
                        onChange={(e) => changeAttr(e.target.value ,'name')}/>
                    {errors.name ? <FieldError message={errors.name}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <Input isAr={true} placeholder="Arabic Gym Name" value={data?.name_ar}
                        onChange={(e) => changeAttr(e.target.value ,'name_ar')}/>
                    {errors.name_ar ? <FieldError ar={true} message={errors.name_ar}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <textarea
                        className='rounded-box'
                        placeholder="About Gym English"
                        value={data?.description_en}
                        onChange={(e) => changeAttr(e.target.value ,'description_en')}
                    ></textarea>
                    {errors.description_en ? <FieldError message={errors.description_en}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <textarea
                        className='rounded-box ar'
                        placeholder="About Gym Arabic"
                        value={data?.description_ar}
                        onChange={(e) => changeAttr(e.target.value ,'description_ar')}
                    ></textarea>
                    {errors.description_ar ? <FieldError ar={true} message={errors.description_ar}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <Input placeholder="Branch Longitude" value={data?.longitude}
                        onChange={(e) => changeAttr(e.target.value ,'longitude')}/>
                    {errors.longitude ? <FieldError ar={true} message={errors.longitude}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <Input placeholder="Branch Latitude" value={data?.latitude}
                        onChange={(e) => changeAttr(e.target.value ,'latitude')}/>
                    {errors.latitude ? <FieldError ar={true} message={errors.latitude}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <Input placeholder="Phone Number" value={data?.phone}
                        onChange={(e) => changeAttr(e.target.value ,'phone')}/>
                    {errors.phone ? <FieldError message={errors.phone}/> : ''}
                </div>
            </div>
            <div className='flex-grid-row'>
                <div className="form-group flex-grid-col-50">
                    <Input placeholder="Email" value={data?.email}
                        onChange={(e) => changeAttr(e.target.value ,'email')}/>
                    {errors.email ? <FieldError message={errors.email}/> : ''}
                </div>
                <div className="form-group flex-grid-col-50">
                    <Input placeholder="Address" value={data?.address}
                        onChange={(e) => changeAttr(e.target.value ,'address')}/>
                    {errors.address ? <FieldError message={errors.address}/> : ''}
                </div>
            </div>
            <div>
                <div className="form-group" style={{paddingLeft: 10}}>
                    <span style={{fontWeight: 'bold'}}> Gym Type </span>
                    <TypeInput changeType={v => {
                        setErrors({...errors ,type: ''})
                        if (data?.type?.includes(v))
                            setData({...data ,type: [...data.type.filter(b => b !== v)]})
                        else setData({...data ,type: [...data.type ,v]})
                    }} chosenType={data?.type}/>
                    {errors.type ? <FieldError message={errors.type}/> : ''}
                </div>
                <div className="form-group" style={{paddingLeft: 10}}>
                    <span style={{fontWeight: 'bold'}}> Bar </span>
                    <BarInput changeBar={v => {
                        setErrors({...errors ,bar: ''})
                        if (data?.bar?.includes(v))
                            setData({...data ,bar: [...data.bar.filter(b => b !== v)]})
                        else setData({...data ,bar: [...data.bar ,v]})
                    }} chosenBar={data?.bar}/>
                    {errors.bar ? <FieldError message={errors.bar}/> : ''}
                </div>
            </div>
        </div>
    )
}

export default GymInput