const imgStyle = {
    width: 50,
    height: 50
}

const Row = ({banner ,editBanner ,deleteBanner}) => {
    return (
        <tr>
            <td>
                <img alt="Gymme" src={banner?.photo} style={imgStyle}/>
            </td>
            <td>
                {banner?.description_en?.substring?.(0 ,50)}...
            </td>
            <td>
                {banner?.ads_for}
            </td>
            <td>
                {banner?.start_date} ~ {banner?.end_date}
            </td>
            <td className="actions">
                <button onClick={editBanner}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={deleteBanner}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row