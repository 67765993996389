import axios from './Axios'
import { useEffect, useState } from 'react'
import AppTemplate from './components/AppTemplate';
import AuthTemplate from './components/Authenticate/AuthTemplate'
import Config from './Config'
import AppContext from './components/partials/AppContext'
import { positions, Provider } from "react-alert"
import AlertTemplate from "react-alert-template-basic"
import $ from 'jquery'

const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER
}

const App = (props) => {
    const [appLoaded ,setAppLoaded] = useState(false)
    const [user ,setUser] = useState({username: '', email: '', avatar: '', name: '', phone: ''})
    const [token ,setToken] = useState(localStorage.getItem('userToken') ? localStorage.getItem('userToken') : undefined)

    const clearUserData = () => {
        localStorage.removeItem('userToken')
        setUser({username: '', email: '', avatar: '', name: '', phone: ''})
        setToken(undefined)
    }

    const updateToken = (token ,remember = false) => {
        setToken(token)
        if (remember) localStorage.setItem('userToken' ,token)
    }

    useEffect(() => {
        if (appLoaded) return
        const savedToken = localStorage.getItem('userToken')
        if (savedToken) {
            axios.post(`${Config.apiBaseUrl}/auth-layer/admin/token-valid` ,{token: savedToken, remember: 'yes'})
            .then(response => {
                const _user = response?.data?.admin ,token = response?.data?.token
                setUser({
                    username: _user?.username,
                    email: _user?.email,
                    avatar: _user?.avatar,
                    name: _user?.name,
                    phone: _user?.phone
                })
                setToken(token)
                setAppLoaded(true)
            })
            .catch(err => {
                clearUserData()
                setAppLoaded(true)
            })
        } else {
            setAppLoaded(true)
            setToken(undefined)
        }
    })

    const providerValue = {
        setToken: (token ,remember = false) => updateToken(token ,remember),
        clearUserData: () => clearUserData(),
        setUserData: (userData) => setUser({
            username: userData?.username,
            email: userData?.email,
            avatar: userData?.avatar,
            name: userData?.name,
            phone: userData?.phone
        }),
        userToken: token,
        user: user,
        toggleAsideMenu : (isShow = false) => {
            if (isShow) {
                $('#aside-menu').addClass('show-aside-menu')
                $('#aside-menu-overlay').addClass('show')
            } else {
                $('#aside-menu').removeClass('show-aside-menu')
                $('#aside-menu-overlay').removeClass('show')
            }
        },
        closeMenu: () => document.getElementById('close-aside-menu').click()
    }

    return (
        <Provider template={AlertTemplate} {...options}>
            {
                appLoaded ?
                <AppContext.Provider value={ providerValue }>
                    { token ? <AppTemplate/> : <AuthTemplate/> }
                </AppContext.Provider>
                : ''
            }
        </Provider>
    )
}

export default App