import { useRef } from 'react'
import './SearchBox.css'

const SearchBox = (props) => {
    const triggerSearchRef = useRef()

    const changeKeyword = e => {
        if (e.key === 'Enter') props.searchTrigger()
    }

    const clearSearch = async () => {
        await props.setKeyword('')
        triggerSearchRef.current.click()
    }

    return (
        <div className="search-box">
            <input
                style={{padding: 15, paddingLeft: 30, paddingRight: 85, width: '100%', maxWidth: '100%', minWidth: '100%'}}
                className={`rounded-box ${props.extraClass}`}
                type="text"
                placeholder={props?.placeholder ? props?.placeholder : 'Search'}
                onChange={e => props.setKeyword(e.target.value)}
                onKeyDown={changeKeyword}
                value={props.keyword}/>
            <i ref={triggerSearchRef} onClick={props.searchTrigger} className="en i-button fa fa-search"></i>
            <span> | </span>
            <i onClick={clearSearch} className="en close-button fa fa-times"></i>
        </div>
    )
}

export default SearchBox