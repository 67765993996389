const Row = (props) => {
    const earning = props?.earning
    
    return (
        <tr>
            <td> { earning?.gym_name } </td>
            <td> { earning?.payment_date } </td>
            <td> { earning?.subscription_name } </td>
            <td> { earning?.subscription_price } </td>
        </tr>
    )
}

export default Row