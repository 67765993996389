import axios from "../../../../Axios"
import Config from "../../../../Config"
import { useAlert } from 'react-alert'
import { useContext, useState } from "react"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"

const DeleteAccount = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const confirmDelete = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/admin-accounts/admins/${props.itemId}` ,{_method: 'delete'})
        .then(response => {
            setDisabled(false)
            const message = response.data?.message
            alert.success(`${message ? message : 'Account deleted successfully'}`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            props.cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading=""
                message={`Are you sure you want to permanently delete this "${props.itemName}"? Please note you can’t undo this action`}
                cancel={props.cancel}
                confirm={confirmDelete}
                confirmLabel="Yes Delete"
            />
        </div>
    )
}

export default DeleteAccount