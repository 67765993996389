import PhotoInput from "../../MoreSection/Accounts/PhotoInput"

const ChangePhoto = (props) => {
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <div className="header">
                        <div className="title">
                            <h4 className="heading"> Change Photo </h4>
                        </div>
                        <div className="header-icon" onClick={props.cancel}>
                            <img alt="Gymme" src="/assets/svg/times-solid-form.svg"/>
                        </div>
                    </div>
                    <form>
                        <div className="section" style={{marginBottom: 10, gap: 5, display: 'flex', flexDirection: 'column'}}>
                            <PhotoInput updatePhoto={(img) => props?.changePhoto(img)} error={props?.error} />
                        </div>
                        <div className="confirm">
                            <button type="button" className="btn btn-default" onClick={props.cancel}>Cancel</button> 
                            <button disabled={props.disabled ? props.disabled : false} type="button" className="btn btn-light" onClick={props.confirm}>
                                Save changes
                            </button>
                        </div>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangePhoto