import { useState } from 'react'
import Config from '../../../../../../../Config'
import DropDown from '../../../../../../FormShared/DropDown'
import FieldError from '../../../../../../FormShared/FieldError'
import './ClassTimes.css'

const ClassTimes = props => {
    const [endError ,setEndError] = useState('')

    const tt = []
    for(let i = 0; i <= 23; i++) {
        if (i < 12) {
            tt.push({value: (i < 10 ? `0${i}` : `${i}`) + ':00 AM'})
        } else {
            const y = i - 12 === 0 ? 12 : i - 12
            tt.push({value: (y < 10 ? `0${y}` : `${y}`) + ':00 PM'})
        }
    }

    const days = props.start && props.end ? Config.getDaysArray(new Date(props.start) ,new Date(props.end)) : []

    let __day = 'Day'
    if (props?.classTime?.day) {
        __day = new Date(props?.classTime?.day).toDateString()
    }

    const changeTimTo = endTime => {
        let _time = parseInt(endTime) ,startTime = parseInt(props?.classTime?.start)
        if (endTime.includes('PM')) _time += 12
        if (props?.classTime?.start.includes('PM')) startTime += 12
        if (startTime >= _time) {
            setEndError('Great than start')
            return
        }
        setEndError('')
        props.changeEnd(endTime)
    }

    return (
        <div className="row close-row">
            <div className="col-sm-6 pl-0">
                <DropDown
                    selectedName={__day}
                    textColorBlack={__day !== 'Day'}
                    list={days}
                    itemName="humanRead"
                    select={_day => {
                        if (props?.fullMembers) return
                        props.changeDay(_day.date)
                    }} />
            </div>
            <div className="col-sm-3">
                <DropDown
                    selectedName={props?.classTime?.start ? props?.classTime?.start : 'From'}
                    textColorBlack={props?.classTime?.start}
                    list={tt}
                    itemName="value"
                    select={_time => {
                        if (props?.fullMembers) return
                        props.changeStart(_time.value)
                    }} />
            </div>
            <div className="col-sm-3 pr-0">
                <DropDown
                    selectedName={props?.classTime?.end ? props?.classTime?.end : 'To'}
                    textColorBlack={props?.classTime?.end}
                    list={tt}
                    itemName="value"
                    select={_time => {
                        if (props?.fullMembers) return
                        changeTimTo(_time.value)
                    }} />
                {endError ? <FieldError message={endError} /> : ''}
            </div>
            <i className="fas fa-times close-btn" onClick={props?.removeDay}></i>
        </div>
    )
}

export default ClassTimes