import axios from "../../../../Axios"
import { useContext, useEffect, useState } from "react"
import Config from '../../../../Config'
import './form.css'
import {useAlert} from 'react-alert'
import ModalHeader from '../../../FormShared/ModalHeader'
import FieldError from '../../../FormShared/FieldError'
import Input from '../../../FormShared/Input'
import ModalButtons from '../../../FormShared/ModalButtons'
import RolePermittedEntities from './RolePermittedEntities'
import AppContext from "../../../partials/AppContext"

const AddRole = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/admin-accounts/roles`
    const [disabled ,setDisabled] = useState(false)

    const [errors ,setErrors] = useState({name_en: '', name_ar: '', permissions: ''})
    const [item ,setItem] = useState({name_ar: '' ,name_en: '' ,permissions: []})

    const changeNameEn = (e) => {
        setItem({...item ,name_en: e.target.value})
        setErrors({...errors ,name_en: ''})
    }

    const changeNameAr = (e) => {
        setItem({...item ,name_ar: e.target.value})
        setErrors({...errors ,name_ar: ''})
    }

    const storeItem = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(entityUrl ,item)
        .then(response => {
            setDisabled(false)
            props.cancel()
            alert.success(`Role "${item.name_en}" stored successfully`)
            props.reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error.response.data
            if (response.errors) setErrors(response.errors)
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const entitiesUrl = `${Config.apiBaseUrl}/admin-accounts/roles/entities`
    const [entities ,setEntities] = useState([])
    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(entitiesUrl ,{cancelToken: axiosSource.token})
        .then(response => setEntities(response.data.entities))
        .catch(err => {
            const error = {...err} ,response = error.response.data
            props.cancel()
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setEntities ,entitiesUrl ,props ,alert])

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Role" cancel={props.cancel}/>
                    <form>
                        <div className="section" style={{paddingBottom: 10}}>
                            <div className="row">
                                <div className="col-sm-6 pl-0">
                                    <Input placeholder="Role's Name" value={item.name_en} onChange={changeNameEn}/>
                                    {errors.name_en ? <FieldError message={errors.name_en}/> : ''}
                                </div>
                                <div className="col-sm-6 pr-0">
                                    <Input placeholder="الوظيفة بالعربي" isAr={true} value={item.name_ar} onChange={changeNameAr}/>
                                    {errors.name_ar ? <FieldError message={errors.name_ar}/> : ''}
                                </div>
                            </div>
                        </div>
                        <div className="toggle-section mb-4">
                            <div className="input-label mb-2">
                                <label htmlFor="branch-adding">Role Permissions</label>
                                {errors.permissions ? <FieldError message={errors.permissions}/> : ''}
                            </div>
                            <RolePermittedEntities
                                itemPermissions={item.permissions}
                                cancel={props.cancel}
                                entities={entities}
                                updateItemPermission={(permissions) => setItem({...item ,permissions})}/>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={storeItem} confirmLabel="Add Role" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddRole