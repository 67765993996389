import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import AppContext from "../../../../../../partials/AppContext"
import PrintButton from "../../../../../../Shared/PrintButton"
import SearchBox from "../../../../../../Shared/SearchBox"
import Pagination from "../../../../../../Shared/Pagination"
import Add from "./Add"
import ClassCard from "./ClassCard"
import Delete from "./Delete"
import Edit from "./Edit"
import '../../../../../../Shared/membership-styles.css'

const Index = ({data ,setData ,visible ,gymId}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/classes/${gymId}`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [addClick ,setAddClick] = useState(undefined)
    const [editClick ,setEditClick] = useState(undefined)
    const [deleteClick ,setDeleteClick] = useState(undefined)
    const [actionMenu ,setActionMenu] = useState(undefined)

    const cancel = () => {
        setAddClick(undefined)
        setEditClick(undefined)
        setDeleteClick(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${responseData?.length <= 1 ? 'Class' : 'Classes'}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[userToken ,alert ,dataPerPage ,currentPage ,clearUserData ,userToken])

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (data.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    const deleteClass = classData => {
        cancel()
        setDeleteClick({
            id: classData.id,
            name: classData.title,
            totalCost: parseFloat(classData?.price) * parseFloat(classData?.registered_members)
        })
    }

    const editClass = id => {
        cancel()
        setEditClick(id)
    }

    return (
        <div style={{display: visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddClick(true)
                    }}> Add Class </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <div className="membership-cards-container">
                        {
                            dataList?.map(
                                classData => <ClassCard key={Math.random()}
                                    classData={classData}
                                    actionMenu={actionMenu}
                                    setActionMenu={setActionMenu}
                                    deleteClass={deleteClass}
                                    editClass={editClass}
                                />
                            )
                        }
                    </div>
                    <Pagination
                        selectStyle={{width: '100%', maxWidth: '100%' ,minWidth: '100%'}}
                        meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {addClick ? <Add gymId={gymId} reload={reloadForAdd} cancel={cancel} /> : ''}
            {
                deleteClick && deleteClick.id ?
                <Delete
                    gymId={gymId}
                    id={deleteClick.id}
                    name={deleteClick.name}
                    cancel={cancel}
                    totalCost={deleteClick.totalCost}
                    reload={reloadForDelete}
                />
                : ''
            }
            {editClick ? <Edit gymId={gymId} id={editClick} reload={reloadForAdd} cancel={cancel} /> : ''}
        </div>
    )
}

export default Index