import axios from '../../../../../Axios'
import { useContext, useEffect, useState } from 'react'
import { useAlert } from "react-alert"
import Config from '../../../../../Config'
import AppContext from '../../../../partials/AppContext'
import DropDown from '../../../../FormShared/DropDown'
import ModalButtons from '../../../../FormShared/ModalButtons'
import ModalHeader from '../../../../FormShared/ModalHeader'
import Input from '../../../../FormShared/Input'
import FieldError from '../../../../FormShared/FieldError'

const SubscribeGym = ({cancel ,reload ,gymId}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [subscriptions ,setSubscriptions] = useState([])
    const [selectedSubscription ,setSelectedSubscription] = useState({
        name: 'Choose Subscription', branches: 0, employees: 0, members: 0, price: 0, duration: ''
    })
    const [membershipId ,setMembershipId] = useState('')
    const [membershipType ,setMembershipType] = useState('month')
    const [disabled ,setDisabled] = useState(false)
    const [cred ,setCred] = useState({username: '', password: '' ,branch_username: '', branch_password: ''})
    const [errors ,setErrors] = useState({username: '' ,password: '' ,branch_username: '', branch_password: ''})

    const apiUrl = Config.apiBaseUrl
    const entityUrl = `${apiUrl}/gym-membership/free-gym`

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${apiUrl}/gym-membership/gym-membership/small-details` ,{cancelToken: axiosSource.token})
        .then(response => setSubscriptions(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSubscriptions ,apiUrl ,userToken ,alert ,clearUserData])

    const confirm = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${gymId}/${membershipId}` ,{...cred ,gym_membership_type: membershipType})
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            cancel()
            reload()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) setErrors({...error?.response?.data?.errors})
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeSubscription = (subscriptionItem) => {
        setSelectedSubscription({
            name: subscriptionItem.name_en,
            branches: subscriptionItem.max_number_of_branches,
            employees: subscriptionItem.max_number_of_employees,
            members: subscriptionItem.max_number_of_members,
            price: subscriptionItem.price,
            yearly_price: subscriptionItem.yearly_price,
            duration: `${subscriptionItem.duration} Month${subscriptionItem.duration > 1 ? 's' : ''}`
        })
        setMembershipId(subscriptionItem.id)
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Subscrine in a plan" cancel={cancel}/>
                    <form>
                        <div className="section">
                            <h3>Make sure to receive the payment in cash</h3>
                            <div className='flex-grid-row'>
                                <div className="form-group flex-grid-col-50">
                                    <Input placeholder="Owner Username" value={cred?.username}
                                        onChange={(e) => {
                                            setErrors({...errors ,username: ''})
                                            setCred({...cred ,username: e.target.value})
                                        }}/>
                                    {errors.username ? <FieldError message={errors.username}/> : ''}
                                </div>
                                <div className="form-group flex-grid-col-50">
                                    <Input placeholder="Owner Password" type="password" value={cred?.password}
                                        onChange={(e) => {
                                            setErrors({...errors ,password: ''})
                                            setCred({...cred ,password: e.target.value})
                                        }}/>
                                    {errors.password ? <FieldError message={errors.password}/> : ''}
                                </div>
                                <div className="form-group flex-grid-col-50">
                                    <Input placeholder="Branch Username" value={cred?.branch_username}
                                        onChange={(e) => {
                                            setErrors({...errors ,branch_username: ''})
                                            setCred({...cred ,branch_username: e.target.value})
                                        }}/>
                                    {errors.branch_username ? <FieldError message={errors.branch_username}/> : ''}
                                </div>
                                <div className="form-group flex-grid-col-50">
                                    <Input placeholder="Branch Password" type="password" value={cred?.branch_password}
                                        onChange={(e) => {
                                            setErrors({...errors ,branch_password: ''})
                                            setCred({...cred ,branch_password: e.target.value})
                                        }}/>
                                    {errors.branch_password ? <FieldError message={errors.branch_password}/> : ''}
                                </div>
                            </div>
                            <DropDown
                                styles={{maxWidth: '100%'}}
                                selectedName={selectedSubscription.name}
                                list={subscriptions}
                                itemName="name_en"
                                select={changeSubscription} />
                            <div className="table-responsive" style={{marginTop: 15}}>
                                <table className="table table-unbordered">
                                    <tr>
                                        <td>Price :</td>
                                        <td> { membershipType === "year" ? selectedSubscription.yearly_price : selectedSubscription.price } </td>
                                    </tr>
                                    <tr>
                                        <td>Maximum Branches :</td>
                                        <td> { selectedSubscription.branches } </td>
                                    </tr>
                                    <tr>
                                        <td>Maximum Employees :</td>
                                        <td> { selectedSubscription.employees } </td>
                                    </tr>
                                    <tr>
                                        <td>Maximum Members :</td>
                                        <td> { selectedSubscription.members } </td>
                                    </tr>
                                    <tr>
                                        <td>Duration :</td>
                                        <td>
                                            <DropDown
                                                styles={{margin: 0}}
                                                selectedName={membershipType}
                                                list={[ {name: "month" ,value: 'month'}, {name: "year" ,value: 'year'} ]}
                                                itemName="name"
                                                select={e => {
                                                    setMembershipType(e.value)
                                                }} />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={confirm} confirmLabel="Subscribe" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SubscribeGym