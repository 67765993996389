import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import BarForm from "../BarForm"

const EditBrand = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [error ,setError] = useState({name_en: '' ,name_ar: ''})
    const [drink ,setDrink] = useState()

    const entityUrl = `${Config.apiBaseUrl}/bar/drink-brand`
    const id = props?.id

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => setDrink(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[alert ,clearUserData ,userToken ,entityUrl ,setDrink])

    const editBrand = () => {
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/bar/drink-brand/${id}` ,{...drink ,_method: 'PUT'})
        .then(response => {
            alert.success(`Drink brand "${response?.data?.data?.name_en}" updated successfully`)
            props?.cancel()
            props?.loadData()
        })
        .catch(err => {
            const error = {...err} ,errors = {name_en: '' ,name_ar: ''}
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors?.name_ar)
                    errors.name_ar = error?.response?.data?.errors?.name_ar[0]
                if (error?.response?.data?.errors?.name_en)
                    errors.name_en = error?.response?.data?.errors?.name_en[0]
                setError(errors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <BarForm
            data={{name_en: drink?.name_en ,name_ar: drink?.name_ar}}
            title="Edit Brand"
            placeholder_en="Drink Brand"
            placeholder_ar="ماركة المشروبات"
            cancel={props?.cancel}
            confirmLabel={`Edit Brand`}
            error={error}
            setError={(field ,errorMsg) => {
                const _tempErr = {...error}
                _tempErr[field] = errorMsg
                setError(_tempErr)
            }}
            setData={(field ,value) => {
                const _tempData = {...drink}
                _tempData[field] = value
                setDrink(_tempData)
            }}
            confirmAction={editBrand}
        />
    )
}

export default EditBrand