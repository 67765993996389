import ModalHeader from "../../../FormShared/ModalHeader"
import ModalButtons from "../../../FormShared/ModalButtons"
import { useContext, useEffect, useState } from "react"
import PhotoInput from "../../../FormShared/PhotoInput"
import SubscriptionInputs from "./SubscriptionInputs"
import AppContext from "../../../partials/AppContext"
import axios from "../../../../Axios"
import { useAlert } from "react-alert"
import FeatureInputs from "./FeatureInputs"

const Edit = (props) => {
    const alert = useAlert()
    const {userToken ,clearUserData} = useContext(AppContext)
    const entityUrl = props?.entityUrl
    const id = props?.id
    const [subscription ,setSubscription] = useState({})
    const [features ,setFeatures] = useState([])
    const [errors ,setErrors] = useState({})
    const [disabled ,setDisabled] = useState(false)
    const [featureErrors ,setFeatureErrors] = useState([])

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const __subscription = response?.data?.data
            setFeatures(__subscription?.features)
            delete __subscription?.features
            setSubscription({...__subscription ,icon: '' ,defaultIcon: __subscription.icon})
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSubscription ,setFeatures ,clearUserData ,entityUrl ,id ,userToken ,alert])

    const updateSubscription = () => {
        const formData = new FormData()
        formData.set('_method' ,'PUT')
        Object.keys(subscription).forEach(attr => {
            if (attr !== 'icon')
                formData.set(attr ,subscription[attr])
        })
        features.map((feature ,index) => {
            formData.set(`feature_en[${index}]` ,feature.name_en)
            formData.set(`feature_ar[${index}]` ,feature.name_ar)
        })
        formData.set('short_description_ar' ,subscription.short_description_ar)
        formData.set('short_description_en' ,subscription.short_description_en)

        if (subscription.icon) formData.set('icon' ,subscription.icon)
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${id}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`Subscription ${response?.data?.data?.name_en} updated successfully`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.data?.errors) {
                const inputErrors = error?.response?.data?.errors ,__errors = {...errors} ,__featureErrors = [...featureErrors]
                for(let key of Object.keys(inputErrors)) {
                    __errors[key] = inputErrors[key][0]
                    if (key.includes('feature')) {
                        __featureErrors.push(inputErrors[key])
                    }
                }
                setErrors(__errors)
                setFeatureErrors(__featureErrors)
            }
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeFiled = (value ,attributeName) => {
        const tempSubscription = {...subscription} ,tempErrors = {...errors}
        tempSubscription[attributeName] = value
        tempErrors[attributeName] = ''
        setSubscription(tempSubscription)
        setErrors(tempErrors)
    }

    const changeNumberField = (value ,name) => {
        if ((isNaN(value) || value < 0) && value !== '') {
            const tempErrors = {...errors}
            tempErrors[name] = 'Positive number only'
            setErrors(tempErrors)
            return
        }
        changeFiled(value ,name)
    }

    const changeFeature = (index ,value ,key) => {
        const feature = {...features[index]} ,tempFeatures = [...features]
        feature[key] = value
        tempFeatures[index] = feature
        setFeatures(tempFeatures)
        setFeatureErrors([])
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Edit Subscription" cancel={props.cancel}/>
                    <form>
                        <div>
                            <div className="section" style={{paddingBottom: 10}}>
                                <PhotoInput
                                    updatePhoto={(img) => changeFiled(img ,'icon')}
                                    error={errors?.icon}
                                    defaultImg={subscription.defaultIcon}
                                    description="Subscription Image Here"/>
                                <SubscriptionInputs subscription={subscription} changeFiled={changeFiled} changeNumberField={changeNumberField} errors={errors} />
                                <FeatureInputs features={features} errors={featureErrors} setFeatures={setFeatures} changeFeature={changeFeature}/>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={updateSubscription} confirmLabel="Update Subscription" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit