const RequestRow = (props) => {
    return (
        <tr>
            <td>
                {
                    props?.request?.logo ?
                    <img alt={props?.request?.name} src={props?.request?.logo} className="gym-logo" />
                    : ''
                }
            </td>
            <td>
                <span className="clickable" onClick={() => props.showDetails(props.request)} >
                    { props?.request?.name }
                </span>
            </td>
            <td> { props?.request?.request_date } </td>
            <td> { props?.request?.phone } </td>
            <td> { props?.request?.email } </td>
            <td> { props?.request?.subscription_name } </td>
            <td> { props?.request?.subscription_price } </td>
            <td>
                <button className="status-action-btn success" onClick={() => props.approve(props?.request?.id ,props?.request?.name)}>
                    <i className="fas fa-check"></i>
                </button>
                <button className="status-action-btn danger" onClick={() => props.reject(props?.request?.id ,props?.request?.name)}>
                    <i className="fas fa-times"></i>
                </button>
            </td>
        </tr>
    )
}

export default RequestRow