import ModalHeader from "../../../FormShared/ModalHeader"
import ModalButtons from "../../../FormShared/ModalButtons"
import { useContext, useState } from "react"
import PhotoInput from "../../../FormShared/PhotoInput"
import SubscriptionInputs from "./SubscriptionInputs"
import AppContext from "../../../partials/AppContext"
import axios from "../../../../Axios"
import { useAlert } from "react-alert"
import FeatureInputs from "./FeatureInputs"

const Add = (props) => {
    const alert = useAlert()
    const {userToken ,clearUserData} = useContext(AppContext)
    const entityUrl = props?.entityUrl
    const [subscription ,setSubscription] = useState({
        icon: '' ,name_en: '' ,name_ar: '' ,price: '' ,duration: '' ,
        max_number_of_members: '' ,max_number_of_employees: '' ,max_number_of_branches: '',
        short_description_en: '' ,short_description_ar: '' ,status: 1 ,yearly_price: ''
    })
    const [features ,setFeatures] = useState([{name_en: '' ,name_ar: ''}])
    const [errors ,setErrors] = useState({})
    const [disabled ,setDisabled] = useState(false)
    const [featureErrors ,setFeatureErrors] = useState([])

    const storeSubscription = () => {
        const formData = new FormData()
        Object.keys(subscription).forEach(attr => formData.set(attr ,subscription[attr]))
        features.map((feature ,index) => {
            formData.set(`feature_en[${index}]` ,feature.name_en)
            formData.set(`feature_ar[${index}]` ,feature.name_ar)
        })
        formData.set('short_description_ar' ,subscription.short_description_ar)
        formData.set('short_description_en' ,subscription.short_description_en)
        formData.set('icon' ,subscription.icon)
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(entityUrl ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`Subscription ${response?.data?.data?.name_en} created successfully`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.data?.errors) {
                const inputErrors = error?.response?.data?.errors ,__errors = {...errors} ,__featureErrors = [...featureErrors]
                for(let key of Object.keys(inputErrors)) {
                    __errors[key] = inputErrors[key][0]
                    if (key.includes('feature')) {
                        __featureErrors.push(inputErrors[key])
                    }
                }
                setErrors(__errors)
                setFeatureErrors(__featureErrors)
            }
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeFiled = (value ,attributeName) => {
        const tempSubscription = {...subscription} ,tempErrors = {...errors}
        tempSubscription[attributeName] = value
        tempErrors[attributeName] = ''
        setSubscription(tempSubscription)
        setErrors(tempErrors)
    }

    const changeFeature = (index ,value ,key) => {
        const feature = {...features[index]} ,tempFeatures = [...features]
        feature[key] = value
        tempFeatures[index] = feature
        setFeatures(tempFeatures)
        setFeatureErrors([])
    }

    const changeNumberField = (value ,name) => {
        if ((isNaN(value) || value < 0) && value !== '') {
            const tempErrors = {...errors}
            tempErrors[name] = 'Positive number only'
            setErrors(tempErrors)
            return
        }
        changeFiled(value ,name)
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Subscription" cancel={props.cancel}/>
                    <form>
                        <div>
                            <div className="section" style={{paddingBottom: 10}}>
                                <PhotoInput
                                    updatePhoto={(img) => changeFiled(img ,'icon')}
                                    error={errors?.icon}
                                    description="Subscription Image Here"/>
                                <SubscriptionInputs subscription={subscription} changeFiled={changeFiled} changeNumberField={changeNumberField} errors={errors} />
                                <FeatureInputs features={features} errors={featureErrors} setFeatures={setFeatures} changeFeature={changeFeature}/>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={storeSubscription} confirmLabel="Add Subscription" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add