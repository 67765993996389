const Row = props => {
    const row = props.row
    const cancel = props.cancel
    const setEditClick = props.edit
    const setDeleteClick = props.delete

    return (
        <tr key={Math.random()}>
            <td colSpan="3" width="60%"> { row.name } </td>
            <td colSpan="1" width="20%"> { row.price } </td>
            <td colSpan="1" width="20%" className="actions">
                <button onClick={() => {
                    cancel()
                    setEditClick({id: row.id ,title: row.name ,price: row.price})
                }}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => {
                    cancel()
                    setDeleteClick({id: row.id ,name: row.name})
                }}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default Row