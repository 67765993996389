import axios from '../../../../../Axios'
import { useContext, useEffect, useState } from 'react'
import { useAlert } from "react-alert"
import Config from '../../../../../Config'
import AppContext from '../../../../partials/AppContext'
import DropDown from '../../../../FormShared/DropDown'
import ModalButtons from '../../../../FormShared/ModalButtons'
import ModalHeader from '../../../../FormShared/ModalHeader'

const RenewalGym = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [subscriptions ,setSubscriptions] = useState([])
    const [selectedSubscription ,setSelectedSubscription] = useState({
        name: 'Choose Subscription', branches: 0, employees: 0, members: 0, price: 0, duration: ''
    })
    const [membershipId ,setMembershipId] = useState('')
    const [membershipType ,setMembershipType] = useState('month')
    const apiUrl = Config.apiBaseUrl
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${apiUrl}/gym-membership/gym-membership/small-details` ,{cancelToken: axiosSource.token})
        .then(response => setSubscriptions(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSubscriptions ,apiUrl ,userToken ,alert ,clearUserData])

    const confirmRenewalGym = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${props.entityUrl}/gyms/renewal/${props.gym.id}` ,{gym_membership_id: membershipId ,gym_membership_type: membershipType})
        .then(response => {
            setDisabled(false)
            alert.success(`Gym "${response?.data?.data?.name}" renewal proceed successfully`)
            props.cancel()
            props.loadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeSubscription = (subscriptionItem) => {
        setSelectedSubscription({
            name: subscriptionItem.name_en,
            branches: subscriptionItem.max_number_of_branches,
            employees: subscriptionItem.max_number_of_employees,
            members: subscriptionItem.max_number_of_members,
            price: subscriptionItem.price,
            yearly_price: subscriptionItem.yearly_price,
            duration: `${subscriptionItem.duration} Month${subscriptionItem.duration > 1 ? 's' : ''}`
        })
        setMembershipId(subscriptionItem.id)
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Renewal" cancel={props.cancel}/>
                    <form>
                        <div className="section">
                            <DropDown
                                selectedName={selectedSubscription.name}
                                list={subscriptions}
                                itemName="name_en"
                                select={changeSubscription} />
                            <div className="table-responsive" style={{marginTop: 15}}>
                                <table className="table table-unbordered">
                                    {
                                        selectedSubscription.price ?
                                        <tr>
                                            <td>Price :</td>
                                            <td> { membershipType === "month" ? selectedSubscription.price : selectedSubscription.yearly_price } </td>
                                        </tr>
                                        :''
                                    }
                                    {
                                        selectedSubscription.branches ?
                                        <tr>
                                            <td>Maximum Branches :</td>
                                            <td> { selectedSubscription.branches } </td>
                                        </tr>
                                        :''
                                    }
                                    {
                                        selectedSubscription.employees ?
                                        <tr>
                                            <td>Maximum Employees :</td>
                                            <td> { selectedSubscription.employees } </td>
                                        </tr>
                                        :''
                                    }
                                    {
                                        selectedSubscription.members ?
                                        <tr>
                                            <td>Maximum Members :</td>
                                            <td> { selectedSubscription.members } </td>
                                        </tr>
                                        :''
                                    }
                                    {
                                        selectedSubscription.duration ?
                                        <tr>
                                            <td>Duration :</td>
                                            <td>
                                                <DropDown
                                                    styles={{margin: 0}}
                                                    selectedName={membershipType}
                                                    list={[ {name: "month" ,value: 'month'}, {name: "year" ,value: 'year'} ]}
                                                    itemName="name"
                                                    select={e => {
                                                        setMembershipType(e.value)
                                                    }} />
                                            </td>
                                        </tr>
                                        :''
                                    }
                                </table>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={confirmRenewalGym} confirmLabel="Renew" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RenewalGym