import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import { NavLink, useParams } from "react-router-dom"
import axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Navbar from '../../../partials/Navbar'
import useQuery from "../../../Shared/useQuery"
import MessageActionConfirm from "./MessageActionConfirm"
import MessageActions from "./MessageActions"
import MessageReply from "./MessageReply"

const ShowMessage = (props) => {
    const screen = useQuery().get('screen') ,page = useQuery().get('page')
    const alert = useAlert()
    const {userToken ,clearUserData} = useContext(AppContext)
    const params = useParams()
    const [message ,setMessage] = useState({})
    const [actionMessage ,setActionMessage] = useState(undefined)
    const baseUrl = `${Config.apiBaseUrl}/help-center/help`
    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        axios
        .setAuthToken(userToken)
        .get(`${baseUrl}/${params.id}` ,{cancelToken: axiosSource.token})
        .then(response => setMessage(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[userToken ,alert ,clearUserData ,setMessage])

    const goTo = (link) => {
        window.open(link ,'_blank')
    }

    const invokeSolve = () => changeStatus(true)
    const invokeSpam = () => changeStatus(false)
    const changeStatus = (isSolved = true) => setActionMessage({...actionMessage ,updateStatus: isSolved ? 1 : 2})

    const invokePrint = () => window.open(`${baseUrl}/print/${params.id}?token=${userToken}` ,'_blank')

    const navbarComp = <>
        <NavLink to={`/help-center?screen=${screen}&page=${page}`} exact style={{marginRight: 10}}>
            <i className="fas fa-arrow-left" style={{color: 'black'}}></i>
        </NavLink>
        Help Center
    </>

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={navbarComp} />
            </div>
            <div className="page-content small-content">
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title"> {message?.title} </span>
                            <MessageActions print={invokePrint} spam={invokeSpam} solve={invokeSolve}/>
                        </div>
                    </div>
                    <div className="data-content" style={{width: '100%'}}>
                        <div>
                            <span className="bold-text"> {message?.name} </span>
                            <span className="gray-text"> ({message?.email}) </span>
                        </div>
                        <p> {message?.description} </p>
                        <div className="section">
                            <span className="gray-text"> Details </span>
                            <div>
                                <span className="bold-text"> Type : </span>
                                <span> {message?.type} </span>
                            </div>
                            <div>
                                <span className="bold-text"> Source : </span>
                                <span> {message?.source} </span>
                            </div>
                            <div>
                                <span className="bold-text"> Phone : </span>
                                <span> {message?.phone} </span>
                            </div>
                        </div>
                        {
                            message?.media?.length > 0 ?
                                <div className="section">
                                    <span className="gray-text"> Attachments </span>
                                    {
                                        message?.media?.map(eachMedia => 
                                            <div className="attachment-line">
                                                <span> {eachMedia?.name} </span>
                                                <span className="red-text clickable" onClick={() => goTo(eachMedia?.link)}>
                                                    Download
                                                </span>
                                            </div>
                                        )
                                    }
                                </div>
                            : ''
                        }
                        <MessageReply id={params.id} baseUrl={baseUrl}/>
                    </div>
                </div>
            </div>
            <MessageActionConfirm
                message={message}
                actionMessage={actionMessage}
                setActionMessage={setActionMessage}
                baseUrl={baseUrl}
                id={params.id}
                loadData={loadData} />
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default ShowMessage