import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Navbar from "../../../partials/Navbar"
import Pagination from "../../../Shared/Pagination"
import PrintButton from "../../../Shared/PrintButton"
import SerachBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import BarDelete from "../BarDelete"
import GymSuggest from "../GymSuggest"
import AddFood from "./AddFood"
import EditFood from "./EditFood"
import FoodRow from "./FoodRow"

const FoodData = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/bar/food-category`
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeAdd ,setInvokeAdd] = useState(undefined)
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [foods ,setFoods] = useState([])
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(props.defaultPage ? props.defaultPage : 1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [gymSuggest ,setGymSuggest] = useState({})
    const axiosSource = axios.CancelToken.source()

    const loadData = () => {
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}?limit=${dataPerPage}&page=${currentPage}&keyword=${keyword}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const foodsCount = response?.data?.data?.length,
                foods = response?.data?.data,
                headerTitle = `${foodsCount} ${foodsCount > 1 ? 'Food Categories' : 'Food Category'}`,
                meta = response?.data?.meta, gymSuggest = response?.data?.gym_suggest
            setFoods(foods)
            setHeaderTitle(headerTitle)
            executeScroll()
            setMeta(meta)
            if (gymSuggest && currentPage === 1)
                setGymSuggest({
                    category: gymSuggest?.category_name_en,
                    gym: gymSuggest?.gym?.name,
                    id: gymSuggest?.id
                })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setFoods ,clearUserData ,alert ,userToken ,currentPage ,dataPerPage ,entityUrl])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const searchTrigger = () => {
        if (currentPage !== 1) setCurrentPage(1)
        else loadData()
    }

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeAdd(undefined)
        setInvokeEdit(undefined)
    }

    const addCategory = () => {
        cancelModal()
        setInvokeAdd(true)
    }

    const setItemIdForEdit = (id) => {
        cancelModal()
        setInvokeEdit(id)
    }
    const deleteFood = (id ,name) => {
        cancelModal()
        setInvokeDelete({id ,name})
    }
    
    const callGymSuggest = (id ,type) => {
        if (gymSuggest) {
            const apiUrl = `${entityUrl}/${type === 'add' ? '' : 'skip-'}gym-suggest/${id}`
            axios
            .setAuthToken(userToken)
            .post(apiUrl)
            .then(response => {
                if (type === 'skip') {
                    alert.show(response?.data?.message)
                    setGymSuggest(undefined)
                } else {
                    alert.success(`${response?.data?.data?.name_en} created successfuly`)
                    if (currentPage !== 1) setCurrentPage(1)
                    else loadData()
                }
            })
            .catch(err => {
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
                else alert.error('Call technical support')
                if (error?.response?.status === 401) clearUserData()
            })
        }
    }

    const headers = [{value: 'English Name'}, {value: 'Arabic Name'}, {value: 'Added Date'}, {className: "actions" ,value: 'Actions'}]

    const loadDataForDelete = () => {
        if (foods.length <= 1 && currentPage > 1) setCurrentPage(currentPage - 1)
        else loadData()
    }

    const loadDataAdterAdd = () => {
        if (currentPage > 1) setCurrentPage(1)
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Food"/>
            </div>
            <div className="page-content">
                <div className="page-section header-box" ref={elRef}>
                    <div className="row header">
                        <SerachBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <button onClick={addCategory} className="btn btn-light">Add Category</button>
                    </div>
                </div>
                {gymSuggest ? <GymSuggest callGymSuggest={callGymSuggest} gym_suggest={gymSuggest}/> : ''}
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                { foods.map(item => <FoodRow key={item.id} item={item}
                                    changeItemForEdit={setItemIdForEdit}
                                    deleteFood={deleteFood} />
                                ) }
                            </tbody>
                        </Table>
                        <Pagination currentPage={currentPage} dataPerPage={dataPerPage} meta={meta}
                            changeDataPerPage={setDataPerPage} changeCurrentPage={setCurrentPage}/>
                    </div>
                </div>
                {invokeAdd ? <AddFood cancel={cancelModal} loadData={loadDataAdterAdd} /> : ''}
                {invokeEdit ? <EditFood id={invokeEdit} cancel={cancelModal} loadData={loadData}/> : ''}
                {invokeDelete ? <BarDelete cancel={cancelModal} entity="Food" loadData={loadDataForDelete} data={invokeDelete} url={entityUrl}/> : ''}
            </div>
        </div>
    )
}

export default FoodData