import Navbar from "../../../partials/Navbar"
import SearchBox from "../../../Shared/SearchBox"
import PrintButton from "../../../Shared/PrintButton"
import axios from "../../../../Axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import SubscriptionCard from "./SubscriptionCard"
import Add from "./Add"
import Edit from "./Edit"
import './styles.css'
import DangerousAlert from "../../../Shared/DangerousAlert"
import Offer from "./Offer"

const Index = (props) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/gym-membership`
    const {clearUserData ,userToken} = useContext(AppContext)
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [subscriptions ,setSubscriptions] = useState([])
    const [addAction ,setAddAction] = useState(undefined)
    const [editId ,setEditId] = useState(undefined)
    const [deleted ,setDeleted] = useState(undefined)
    const [actionMenu ,setActionMenu] = useState(undefined)
    const [offer ,setOffer] = useState({id: undefined ,start: '' ,end: '' ,discount: '' ,yearly_discount: ''})
    const [membershipOffer ,setMembershipOffer] = useState({price: 0 ,yearly_price: 0 ,id: 0 ,status: undefined})

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()
    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=1000`, {cancelToken: axiosSource.token})
        .then(response => {
            const subscriptionsCount = response?.data?.data?.length,
                subscriptions = response?.data?.data,
                headerTitle = `${subscriptionsCount} Subscription${subscriptionsCount > 1 ? 's' : ''}`
            setSubscriptions(subscriptions)
            setHeaderTitle(headerTitle)
            executeScroll()
            
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setSubscriptions ,alert ,entityUrl])

    const cancelModal = () => {
        setAddAction(undefined)
        setEditId(undefined)
        setDeleted(undefined)
        setMembershipOffer({price: 0 ,id: 0 ,status: undefined ,yearly_price: 0})
        setOffer({...offer ,isCanceled: false})
    }

    const deleteSubscription = (subscriptionId) => {
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${subscriptionId}` ,{_method: 'DELETE'})
        .then(response => {
            alert.success(`Subscription ${response?.data?.data?.name_en} is deleted successfully`)
            loadData()
            cancelModal()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const cancelOffer = () => {
        if (!offer.id) {
            alert.show('Select offer first')
            return
        }
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/cancelOffer/${offer.id}` ,{_method: 'PUT'})
        .then(response => {
            alert.success(`Offer canceled successfully`)
            setActionMenu(undefined)
            loadData()
            cancelModal()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Subscriptions"/>
            </div>
            <div className="page-content">
                <div className="page-section header-box" ref={elRef}>
                    <div className="row header">
                        <SearchBox searchTrigger={loadData} setKeyword={setKeyword} keyword={keyword}/>
                        <button className="btn btn-light" onClick={() => {
                            cancelModal()
                            setAddAction(true)
                        }}>Add Subscription</button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <div className="membership-cards-container">
                            {
                                subscriptions.map(
                                    subscription => <SubscriptionCard
                                        entityUrl={entityUrl}
                                        key={subscription.id}
                                        subscription={subscription}
                                        actionMenu={actionMenu}
                                        setActionMenu={setActionMenu}
                                        cancel={cancelModal}
                                        deleted={setDeleted}
                                        edited={setEditId}
                                        reloadData={loadData}
                                        setOffer={setOffer}
                                        setMembershipOffer={setMembershipOffer}
                                        cancelOffer={cancelOffer} />
                                )
                            }
                        </div>
                    </div>
                </div>
                {
                    addAction ?
                        <Add cancel={cancelModal} entityUrl={entityUrl} reloadData={loadData} />
                        : ''
                }
                {
                    editId ?
                        <Edit cancel={cancelModal} entityUrl={entityUrl} reloadData={loadData} id={editId} />
                        : ''
                }
                {
                    deleted ? 
                        <div id="forms">
                            <DangerousAlert
                                heading={`Delete "${deleted?.name}"`}
                                message={`Are you sure you want to permanently delete this subscription "${deleted?.name}" ? Please note you can’t undo this action`}
                                confirmLabel="Delete"
                                confirm={(() => deleteSubscription(deleted.id))}
                                cancel={cancelModal}
                            />
                        </div>
                        : ''
                }
                {
                    membershipOffer.status ? 
                        <Offer
                            subscriptionPrice={membershipOffer.price}
                            subscriptionYearlyPrice={membershipOffer.yearly_price}
                            offerId={offer.id}
                            membershipId={membershipOffer.id}
                            offer={offer}
                            cancel={cancelModal}
                            reloadData={loadData}
                        />
                        :''
                }
                {
                    offer && offer.isCanceled ?
                    <div id="forms">
                        <DangerousAlert
                            heading={`Cancel "${offer?.name}"`}
                            message={`Are you sure you want to permanently cancel this offer "${offer?.name}" ? Please note you can’t undo this action`}
                            confirmLabel="Yes Cancel"
                            confirm={(cancelOffer)}
                            cancel={cancelModal}/>
                    </div>
                    : ''
                }
            </div>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default Index