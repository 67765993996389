import { useState } from "react"
import Navbar from "../../../partials/Navbar"
import CrudIndexDS from "../../../Shared/CrudIndexDS"
import ListData from "./ListData"

const Supplements = (props) => {
    const [showSection ,setShowSection] = useState('supplements')

    const [supplementsIndexData ,setSupplementsIndexData] = useState({...CrudIndexDS})
    const [brandIndexData ,setBrandIndexData] = useState({...CrudIndexDS})

    const SupplementsIndexComponent = <ListData
        entity='category'
        entityUpper='Categories'
        printUrl='print'
        visible={showSection === 'supplements'}
        data={supplementsIndexData}
        setData={setSupplementsIndexData}
    />,
    BrandsIndexComponent = <ListData
        entity='brand'
        entityUpper='Brands'
        printUrl='print'
        visible={showSection === 'brands'}
        data={brandIndexData}
        setData={setBrandIndexData}
    />
    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Supplements"/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'supplements' ? 'active' : ''}
                    onClick={() => setShowSection('supplements')}>
                    Supplements
                </button>
                <button className={showSection === 'brands' ? 'active' : ''}
                    onClick={() => setShowSection('brands')}>
                    Brands
                </button>
            </div>
            <div className="page-content">
                {SupplementsIndexComponent}
                {BrandsIndexComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Supplements