import { useContext } from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import AppContext from './partials/AppContext'

import Aside from './partials/Aside'
import PortalContent from './partials/PortalContent'

const AppTemplate = (props) => {
    const {closeMenu} = useContext(AppContext)
    return (
        <Router>
            <Aside/>
            <PortalContent  />
            <div onClick={closeMenu} id="aside-menu-overlay"></div>
        </Router>
    )
}

export default AppTemplate