import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import AppContext from "../../../../../../partials/AppContext"
import DangerousAlert from "../../../../../../Shared/DangerousAlert"
import PrintButton from "../../../../../../Shared/PrintButton"
import SearchBox from "../../../../../../Shared/SearchBox"
import Add from "./Add"
import Delete from "./Delete"
import Edit from "./Edit"
import Offer from "./Offer"
import SubscriptionCard from "./SubscriptionCard"

const Index = ({visible ,gymId}) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/subscription`
    const {clearUserData ,userToken} = useContext(AppContext)
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [subscriptions ,setSubscriptions] = useState([])
    const [addAction ,setAddAction] = useState(undefined)
    const [editId ,setEditId] = useState(undefined)
    const [deleted ,setDeleted] = useState(undefined)
    const [actionMenu ,setActionMenu] = useState(undefined)
    const [offer ,setOffer] = useState({id: undefined ,start: '' ,end: '' ,discount: ''})
    const [membershipOffer ,setMembershipOffer] = useState({price: 0 ,id: 0 ,status: undefined})
    const [disabled ,setDisabled] = useState(false)

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${gymId}?keyword=${keyword}&limit=1000` ,{cancelToken: axiosSource.token})
        .then(response => {
            const subscriptionsCount = response?.data?.data?.length,
                subscriptions = response?.data?.data,
                headerTitle = `${subscriptionsCount} Subscription${subscriptionsCount > 1 ? 's' : ''}`
            setSubscriptions(subscriptions)
            setHeaderTitle(headerTitle)
            executeScroll()
            
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setSubscriptions ,alert ,entityUrl])

    const cancelModal = () => {
        setAddAction(undefined)
        setEditId(undefined)
        setDeleted(undefined)
        setMembershipOffer({price: 0 ,id: 0 ,status: undefined})
        setOffer({...offer ,isCanceled: false})
    }

    const cancelOffer = () => {
        if (!offer.id) {
            alert.show('Select offer first')
            return
        }
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${gymId}/cancelOffer/${offer.id}` ,{_method: 'PUT'})
        .then(response => {
            setDisabled(false)
            alert.success(`Offer canceled successfully`)
            setActionMenu(undefined)
            loadData()
            cancelModal()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const printClick = () => Config.printByToken(`${entityUrl}/${gymId}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div style={{display: visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox searchTrigger={loadData} setKeyword={setKeyword} keyword={keyword}/>
                    <button className="btn btn-light" onClick={() => {
                        cancelModal()
                        setAddAction(true)
                    }}>Add Subscription</button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                    <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <div className="membership-cards-container">
                        {
                            subscriptions?.map?.(
                                subscription => <SubscriptionCard
                                    entityUrl={entityUrl}
                                    key={subscription.id}
                                    subscription={subscription}
                                    actionMenu={actionMenu}
                                    setActionMenu={setActionMenu}
                                    cancel={cancelModal}
                                    deleted={setDeleted}
                                    edited={setEditId}
                                    reloadData={loadData}
                                    setOffer={setOffer}
                                    setMembershipOffer={setMembershipOffer} />
                            )
                        }
                    </div>
                </div>
            </div>
            {deleted ? <Delete gymId={gymId} subscription={deleted} reload={loadData} cancel={cancelModal} /> : ''}
            {addAction ? <Add gymId={gymId} reload={loadData} cancel={cancelModal} /> : ''}
            {editId ? <Edit gymId={gymId} id={editId} reloadData={loadData} cancel={cancelModal} /> : ''}
            {
                membershipOffer.status ? 
                <Offer
                    gymId={gymId}
                    subscriptionPrice={membershipOffer.price}
                    offerId={offer.id}
                    subscription_id={membershipOffer.id}
                    offer={offer}
                    cancel={cancelModal}
                    reloadData={loadData}
                />
                :''
            }
            {
                offer && offer.isCanceled ?
                <div id="forms">
                    <DangerousAlert
                        disabled={disabled}
                        heading={`Cancel Offer`}
                        message={`Are you sure you want to permanently cancel this offer "${offer?.name}" ? Please note you can’t undo this action`}
                        confirmLabel="Yes Cancel"
                        confirm={(cancelOffer)}
                        cancel={cancelModal}/>
                </div>
                : ''
            }
        </div>
    )
}

export default Index