import { useContext, useEffect, useState } from 'react'
import { useAlert } from 'react-alert'
import axios from "../../../../Axios"
import Input from '../../../FormShared/Input'
import FieldError from '../../../FormShared/FieldError'
import Config from '../../../../Config'
import AppContext from '../../../partials/AppContext'
import DropDown from '../../../FormShared/DropDown'

const AccountCommonInputs = (props) => {
    const alert = useAlert()
    const [roles ,setRoles] = useState([])
    const {clearUserData ,userToken} = useContext(AppContext)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${Config.apiBaseUrl}/admin-accounts/roles/getAll` ,{cancelToken: axiosSource.token})
        .then(response => setRoles(response.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setRoles ,alert])

    return (
        <div className="row">
            <div className="col-sm-6 pad0 left-textfiled">
                <div className="form-group">
                    <Input placeholder="Username" value={props.account?.username}
                        onChange={(e) => props.updateAccountAttr(e.target.value ,'username')}/>
                </div>
                {props.errors.username ? <FieldError message={props.errors.username}/> : ''}
            </div>
            <div className="col-sm-6 pad0 right-textfiled">
                <div className="form-group">
                    <Input placeholder="Password" type="password"
                        value={props.account?.password} onChange={(e) => props.updateAccountAttr(e.target.value ,'password')}/>
                </div>
                {props.errors.password ? <FieldError message={props.errors.password}/> : ''}
            </div>
            <div className="col-sm-6 pad0 left-textfiled">
                <DropDown
                    selectedName={props?.account?.role?.name ? props?.account?.role?.name : 'Role'}
                    list={roles}
                    itemName="name_en"
                    select={(role) => props.addRole(role.id ,role.name_en)} />
                {props.errors.role ? <FieldError message={props.errors.role}/> : ''}
            </div>
            <div className="col-sm-6 pad0 right-textfiled">
                <div className="form-group">
                    <Input placeholder="E-Mail" value={props.account?.email} onChange={(e) => props.updateAccountAttr(e.target.value ,'email')}/>
                </div>
                {props.errors.email ? <FieldError message={props.errors.email}/> : ''}
            </div>
            <div className="col-sm-6 pad0 left-textfiled">
                <div className="form-group">
                    <Input placeholder="Name"
                        value={props.account?.name} onChange={(e) => props.updateAccountAttr(e.target.value ,'name')}/>
                </div>
                {props.errors.name ? <FieldError message={props.errors.name}/> : ''}
            </div>
            <div className="col-sm-6 pad0 right-textfiled">
                <div className="form-group">
                    <Input placeholder="Phone"
                        value={props.account?.phone} onChange={(e) => props.updateAccountAttr(e.target.value ,'phone')}/>
                </div>
                {props.errors.phone ? <FieldError message={props.errors.phone}/> : ''}
            </div>
        </div>
    )
}

export default AccountCommonInputs