const SubscriptionCard = (props) => {
    const subscription = props.subscription
    const price = subscription?.activeOffer ? `${subscription?.activeOffer.price_after_discount} EGP` : subscription?.price
    const oldPrice = subscription?.activeOffer ? true : false
    const addOffer = () => {
        props?.setActionMenu(undefined)
        props?.setMembershipOffer({price: subscription?.real_price ,id: subscription.id ,status: true})
        props?.setOffer({id: undefined ,start: '' ,end: '' ,discount: '' ,title: '' ,title_ar: '' ,quantity: ''})
    }
    const editOffer = () => {
        props?.setActionMenu(undefined)
        props?.setMembershipOffer({price: subscription?.real_price ,id: subscription.id ,status: true})
        props?.setOffer({
            id: subscription?.activeOffer?.id,
            start: subscription?.activeOffer?.offer_start,
            end: subscription?.activeOffer?.offer_end,
            discount: subscription?.activeOffer?.discount_percentage,
            title: subscription?.activeOffer?.offer_title,
            title_ar: subscription?.activeOffer?.offer_title_ar,
            quantity: subscription?.activeOffer?.offer_quantity,
        })
    }

    const invokeEdit = () => {
        props?.setActionMenu(undefined)
        props?.edited(subscription.id)
    }

    const invokeDelete = () => {
        props?.setActionMenu(undefined)
        props?.deleted({name: subscription.title ,id: subscription.id})
    }

    const toggleMenuAction = () => {
        props?.setActionMenu(props.actionMenu === subscription.id ? undefined : subscription.id)
    }

    const cancelOffer = () => {
        props?.setActionMenu(undefined)
        props?.setOffer({
            name: `${subscription?.activeOffer?.discount_percentage} % Extra Discount`,
            id: subscription?.activeOffer?.id,
            start: subscription?.activeOffer?.offer_start,
            end: subscription?.activeOffer?.offer_end,
            discount: subscription?.activeOffer?.discount_percentage,
            isCanceled: true
        })
    }

    return (
        <div className="membership-card">
            <div className="membership-actions" style={{display: props?.actionMenu === subscription.id ? 'block' : 'none'}}>
                <button type="button" className="small-btn default-small-btn menu-action-hover"
                    onClick={subscription?.activeOffer ? editOffer : addOffer}>
                    <i className="fas fa-percentage"></i>
                    <span> {subscription?.activeOffer ? 'Edit' : 'Add'} Offer </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={invokeEdit}>
                <i className="fas fa-pen"></i>
                    <span> Edit </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover"
                    onClick={subscription?.activeOffer ? cancelOffer : invokeDelete}>
                <i className="fas fa-trash"></i>
                    <span> {subscription?.activeOffer ? 'Cancel Offer' : 'Delete'} </span>
                </button>
            </div>
            <img alt={subscription?.title} src="/assets/svg/offer.svg"
                className={`offer-badge en ${subscription?.activeOffer ? '' : 'no-display'}`} />
            <button className="dots en" type="button" onClick={toggleMenuAction}>
                <img alt={subscription?.title} src="/assets/svg/dots.svg" className="full-img"/>
            </button>
            <span className="subscription-title"> { subscription?.title } </span>
            <div className="main-title">
                <span className="price"> {price} </span>
                {
                    oldPrice ? <span className="old-price"> {subscription?.price} EGP </span> : ''
                }
            </div>
            <span className={`duration-left ${subscription?.activeOffer ? '' : 'no-display'}`}> {subscription?.activeOffer?.daysLeft} Days </span>
            <ul className="options">
                <li> { subscription?.number_of_days } </li>
                <li> { subscription?.number_of_invitations } </li>
                <li> { subscription?.freezing_days } </li>
                {
                    subscription?.session?.map(session => <li key={session.id} > {session.name} </li> )
                }
            </ul>
            
        </div>
    )
}

export default SubscriptionCard