import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Loading from "../../../Shared/Loading"
import AppContext from "../../../partials/AppContext"
import Inputs from "./Inputs"

const Edit = ({cancel ,reloadData ,entityUrl ,id}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [errors ,setErrors] = useState({
        description_en: "" ,description_ar: "" ,photo: "" ,start_date: "" ,end_date: "" ,ads_for: '' ,skip_days: ''
    })
    const [bannerAd ,setBannerAd] = useState({
        description_en: "" ,description_ar: "" ,start_date: "" ,end_date: "" ,ads_for: '' ,skip_days: ''})
    const [photo ,setPhoto] = useState()
    const [defaultPhoto ,setDefaultPhoto] = useState("")
    const [loaded ,setLoaded] = useState(false)

    const axiosSource = Axios.CancelToken.source()
    useEffect(() => {
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data?.data
            setBannerAd({
                description_ar: data?.description_ar,
                description_en: data?.description_en,
                start_date: data?.start_date,
                end_date: data?.end_date,
                ads_for: data?.ads_for,
                skip_days: data?.skip_days
            })
            setDefaultPhoto(data?.photo)
            setLoaded(true)
        })
        .catch(err => {
            setLoaded(true)
            const error = {...err} ,response = error?.response?.data
            if (response?.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setBannerAd ,entityUrl ,id ,alert])

    const confirm = () => {
        const data = new FormData()
        if (photo) data.set("photo" ,photo)
        Object.keys(bannerAd).forEach(key => data.set(key ,bannerAd[key]))
        data.set("_method" ,"PUT")

        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${id}` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error?.response?.data ,validations = error?.response?.data?.errors
            if (validations) setErrors(validations)
            else if (response?.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Edit Banner Ad" cancel={cancel}/>
                    <form>
                        {
                            loaded ? <>
                                <Inputs
                                    defaultImg={defaultPhoto}
                                    bannerAd={bannerAd}
                                    setBannerAd={setBannerAd}
                                    errors={errors}
                                    setErrors={setErrors}
                                    photo={photo}
                                    setPhoto={setPhoto}
                                />
                                <ModalButtons disabled={disabled} cancel={cancel} confirm={confirm} confirmLabel="Edit Banner Ad" />
                            </> : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit