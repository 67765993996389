import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../Axios"
import Config from "../../../Config"
import Navbar from "../../partials/Navbar"
import ChangeModal from "./ChangeModal"
import AppContext from "../../partials/AppContext"
import Input from "../../FormShared/Input"
import FieldError from "../../FormShared/FieldError"
import ChangePhoto from "./ChangePhoto"
import AdminData from "./AdminData"
import '../GymmeSection/Requests/gym-details.css'
import '../../FormShared/form.css'
import './Responsive.css'


const Index = (props) => {
    const entityUrl = `${Config.apiBaseUrl}/admin-accounts/admins`
    const alert = useAlert()
    const {clearUserData ,userToken ,setUserData ,user} = useContext(AppContext)
    const [admin ,setAdmin] = useState({})
    const [isModalOpen ,setIsModalOpen] = useState(false)
    const [isPhotoModal ,setIsPhotoModal] = useState(false)
    const [editedField ,setEditedField] = useState('')
    const [editedValue ,setEditedValue] = useState('')
    const [modalHeader ,setModalHeader] = useState('')
    const [error ,setError] = useState('')
    const [passError ,setPassError] = useState('')
    const [type ,setType] = useState('text')
    const [currentPass ,setCurrentPass] = useState('')
    const [confirmPass ,setConfirmPass] = useState('')
    const [photo ,setPhoto] = useState({})
    const [disabled ,setDisabled] = useState(false)
    const [photoDisabled ,setPhotoDisabled] = useState(false)
    const axiosSource = axios.CancelToken.source()
    const loadAdmin = () => {
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/getProfile` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data?.data
            setAdmin({
                id: data?.id,
                name: data?.name,
                photo: data?.photo,
                email: data?.email,
                phone: data?.phone
            })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            // if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadAdmin()
        return () => axiosSource.cancel()
    },[setAdmin ,clearUserData ,userToken ,entityUrl ,alert])

    const openChangeModal = (fieldName) => {
        setIsModalOpen(true)
        setEditedField(fieldName)
        setEditedValue(admin[fieldName])
        setModalHeader(fieldName.split('_').join(' '))
        setError('')
        if (fieldName === 'password') {
            setType('password')
            setEditedValue('')
            setCurrentPass('')
            setConfirmPass('')
        } else {
            setType('')
            setCurrentPass('')
        }
    }

    const _handleUpdateResponse = (response ,forPhoto = false) => {
        if (forPhoto === true) setPhotoDisabled(false)
        else setDisabled(false)
        alert.success(`Admin ${response?.data?.data?.name} updated successfully`)
            cancelModal()
            loadAdmin()
            setUserData({
                ...user,
                email: response?.data?.data?.email,
                avatar: response?.data?.data?.photo,
                name: response?.data?.data?.name
            })
    }

    const updateProfile = () => {
        if (!editedValue) {
            setError('Field can`t be empty')
            return
        }
        const data = {password: currentPass}
        data[editedField] = editedValue
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/updateProfile` ,data)
        .then(_handleUpdateResponse)
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors[editedField]) {
                    setError(error?.response?.data?.errors[editedField].join(" ,"))
                }
            }
        })
    }

    const changePassword = () => {
        if (!editedValue) {
            setError('Field can`t be empty')
            return
        }
        const data = {old_password: currentPass ,password: editedValue ,password_confirmation: confirmPass}
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/updateProfilePassword` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`Admin ${response?.data?.data?.name} updated successfully`)
            clearUserData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) setPassError(error?.response?.data?.errors?.password?.join(" ,"))
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
        })
    }

    const openPhotoModal = () => {
        cancelModal()
        setCurrentPass('')
        setIsPhotoModal(true)
    }

    const updatePhoto = () => {
        if (!photo) {
            alert.error('Select Photo First')
            return
        }
        const formData = new FormData()
        formData.set('photo' ,photo)
        formData.set('password' ,currentPass)
        setPhotoDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/updateProfile` ,formData)
        .then(res => _handleUpdateResponse(res ,true))
        .catch(err => {
            setPhotoDisabled(false)
            const error = {...err}
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors?.photo) {
                    alert.error(error?.response?.data?.errors?.photo?.join(" ,"))
                }
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
        })
    }

    const cancelModal = () => {
        setIsPhotoModal(false)
        setIsModalOpen(false)
    }

    const setPass = (e) => setCurrentPass(e.target.value)
    const changeConfirmPass = (e) => setConfirmPass(e.target.value)
    const changeEditedValue = (e) => setEditedValue(e.target.value)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Profile" />
            </div>
            <AdminData admin={admin} openChangeModal={openChangeModal} openPhotoModal={openPhotoModal}/>
            {
                isModalOpen ? 
                <ChangeModal 
                    disabled={disabled}
                    modalHeader={modalHeader} value={editedValue} error={error}
                    type={type} placeholder={type === 'password' ? 'Confirm Password' : ''}
                    childrenFirst={type === 'password'}
                    cancel={cancelModal}
                    changeValue={changeEditedValue}
                    confirm={type === 'password' ? changePassword : updateProfile} >
                    {
                        type === 'password' ?
                        <>
                            <Input value={currentPass} onChange={setPass} placeholder="Old Password" type="password"/>
                            {error ? <FieldError message={error} /> : ''}
                            <Input value={confirmPass} onChange={changeConfirmPass} placeholder="New Password" type="password"/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </>
                        :
                        <>
                            <Input value={currentPass} onChange={setPass} placeholder="Password" type="password"/>
                            {passError ? <FieldError message={passError} /> : ''}
                        </>
                    }
                </ChangeModal>
                : ''
            }
            {
                isPhotoModal ?
                    <ChangePhoto disabled={photoDisabled} changePhoto={setPhoto} cancel={cancelModal}
                        error="" confirm={updatePhoto} currentPass={currentPass}
                        setPass={setPass} passError={passError} />
                    : ''
            }
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default Index