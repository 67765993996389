import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import ModalButtons from "../../../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../../../FormShared/ModalHeader"
import AppContext from "../../../../../../partials/AppContext"
import Loading from "../../../../../../Shared/Loading"
import SubscriptionInputs from "./SubscriptionInputs"

const Edit = props => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/subscription`

    const [subscriptionData ,setSubscriptionData] = useState({
        title: '' ,title_ar: '' ,number_of_days: '' ,number_of_invitations: '' ,freezing_days: '' ,price: '' ,
        sessions: [
            {session_id: '' ,quantity: '' ,real_name: 'Session'}
        ]
    })
    const [errors ,setErrors] = useState({
        title: '' ,title_ar: '' ,number_of_days: '' ,number_of_invitations: '' ,freezing_days: '' ,price: '' ,sessions: ''
    })
    const [loaded ,setLoaded] = useState(false)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${props?.gymId}/show/${props?.id}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const _subscription = response?.data
            const sessions = _subscription.session && Array.isArray(_subscription.session) ?
            _subscription.session.map(_session => {
                return {
                    session_id: _session.session_id,
                    quantity: _session.quantity,
                    real_name: _session.name
                }
            })
            : []

            setSubscriptionData({
                title: _subscription.title ,
                title_ar: _subscription.title_ar ,
                number_of_days: _subscription.number_of_days ,
                number_of_invitations: _subscription.number_of_invitations ,
                freezing_days: _subscription.freezing_days ,
                price: _subscription.price ,
                sessions: [...sessions]
            })
            setLoaded(true)
        })
        return () => axiosSource.cancel()
    } ,[clearUserData ,userToken ,alert ,setSubscriptionData ,entityUrl])

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .put(`${entityUrl}/${props?.gymId}/update/${props.id}` ,{...subscriptionData})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.title} updated successfully`)
            props?.reloadData()
            props?.cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                setErrors(validation)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const [sessions ,setSessions] = useState([])
    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${props?.gymId}/getSessions` ,{cancelToken: axiosSource.token})
        .then(response => setSessions(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSessions ,clearUserData ,alert ,userToken ,entityUrl])

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Edit Subscription" cancel={props.cancel}/>
                    <form>
                        {
                            loaded ?
                            <>
                                <SubscriptionInputs
                                    subscriptionData={subscriptionData}
                                    setSubscriptionData={setSubscriptionData}
                                    errors={errors}
                                    sessions={[
                                        ...sessions.filter(
                                            eachSession => !subscriptionData.sessions.some(subscEachSession => subscEachSession.session_id === eachSession.id)
                                        )
                                    ]}
                                />
                                <div className="col-md-12">
                                    <ModalButtons disabled={disabled} cancel={props.cancel} confirm={saveChanges} confirmLabel="Edit Subscription" />
                                </div>
                            </>
                            : <Loading/>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit