import axios from "../../Axios"
import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import { NavLink, useHistory, useParams } from "react-router-dom"
import Config from "../../Config"
import FieldError from "../FormShared/FieldError"
import AppContext from "../partials/AppContext"
import './reset.css'

const Reset = (props) => {
    const history = useHistory()
    const alert = useAlert()
    const params = useParams(), token = params.token
    const [errors ,setErrors] = useState({pass: '' ,confirmPass: '' ,email: ''})
    const [pass ,setPass] = useState('')
    const [confirmPass ,setConfirmPass] = useState('')
    const [email ,setEmail] = useState('')
    const [showPass ,setShowPass] = useState(false)
    const [showConfirmPass ,setShowConfirmPass] = useState(false)
    const {clearUserData} = useContext(AppContext)

    const changePass = (e) => {
        setPass(e.target.value)
        setErrors({...errors ,pass: ''})
    }

    const changeConfirmPass = (e) => {
        setConfirmPass(e.target.value)
        setErrors({...errors ,confirmPass: ''})
    }

    const changeEmail = (e) => {
        setEmail(e.target.value)
        setErrors({...errors ,email: ''})
    }

    const confirmChangePassword = () => {
        if (pass !== confirmPass) {
            setErrors({pass: 'Most be like confirm password' ,confirmPass: 'Most be like password'})
            return
        }
        const data = {password: pass ,password_confirmation: confirmPass ,restToken: token ,email: email}
        
        axios.post(`${Config.apiBaseUrl}/auth-layer/admin/reset-password` ,data)
        .then(response => {
            alert.success(response?.data?.message)
            clearUserData()
            history.push('/')
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) {
                const _errors = {...errors}
                if (error?.response?.data?.errors?.email?.[0]) _errors.email = error?.response?.data?.errors?.email[0]
                if (error?.response?.data?.errors?.password?.[0]) _errors.email = error?.response?.data?.errors?.password[0]
                setErrors(_errors)
            }
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
        })
    }
    
    return (
        <div className="change-pass-form" style={{direction: 'ltr', overflow: 'auto'}}>
            <NavLink to="/" exact>
                <img alt="Gymme" className="logo" src="/assets/svg/logo.svg"/>
            </NavLink>
            <div className="bg">
                <img alt="Gymme" src="/assets/forgot-pw@2x.png"/>
            </div>
            <div className="form-container en">
                <form autoComplete="off">
                    <h2 id="title">Change password</h2>
                    <div className="password input-box">
                        <input className="rounded-box" type="email"
                            placeholder="Email" value={email} onChange={changeEmail}/>
                    </div>
                    <div className="password input-box">
                        <input id="new-pass" className="rounded-box" type={showPass ? "text" : "password"}
                            placeholder="New Password" value={pass} onChange={changePass}/>
                        <button className="toggler-icon" type="button" onClick={() => setShowPass(!showPass)}>
                            <img alt="Gymme" src="/assets/svg/eye-solid.svg"/>
                        </button>
                    </div>
                    {errors.pass ? <FieldError message={errors.pass} /> : ''}
                    <div className="password input-box">
                        <input id="confirm-pass" className="rounded-box" type={showConfirmPass ? "text" : "password"}
                            placeholder="Confirm Password" value={confirmPass} onChange={changeConfirmPass}/>
                        <button type="button" className="toggler-icon" onClick={() => setShowConfirmPass(!showConfirmPass)}>
                            <img alt="Gymme" src="/assets/svg/eye-solid.svg"/>
                        </button>
                    </div>
                    {errors.confirmPass ? <FieldError message={errors.confirmPass} /> : ''}
                    <button type="button" onClick={confirmChangePassword} className="btn btn-light">Change password</button>
                </form>
            </div>
        </div>
    )
}

export default Reset