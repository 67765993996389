const Table = (props) => {
    return (
        <div className="table-container">
            <div className="table">
                <table>
                    {props.children}
                </table>
            </div>              
        </div>
    )
}

export default Table