import { Fragment } from "react"
import Input from "../../../FormShared/Input"
import FieldError from "../../../FormShared/FieldError"

const SubscriptionInputs = (props) => {
    const subscription = props?.subscription
    const errors = props?.errors
    const changeFiled = props?.changeFiled
    const changeNumberField = props?.changeNumberField

    return (
        <Fragment>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder="English Name" value={subscription?.name_en}
                        onChange={(e) => changeFiled(e.target.value ,'name_en')}/>
                    {errors?.name_en ? <FieldError message={errors?.name_en}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder="الإسم بالعربي" isAr={true} value={subscription?.name_ar}
                        onChange={(e) => changeFiled(e.target.value ,'name_ar')}/>
                    {errors?.name_ar ? <FieldError message={errors?.name_ar}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder="Monthly Price" value={subscription?.price}
                        onChange={(e) => changeNumberField(e.target.value ,'price')}/>
                    {errors?.price ? <FieldError message={errors?.price}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder="Yearly Price" value={subscription?.yearly_price}
                        onChange={(e) => changeNumberField(e.target.value ,'yearly_price')}/>
                    {errors?.yearly_price ? <FieldError message={errors?.yearly_price}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder="# OF Employees" type="number" value={subscription?.max_number_of_employees}
                        onChange={(e) => changeNumberField(e.target.value ,'max_number_of_employees')}/>
                    {errors?.max_number_of_employees ? <FieldError message={errors?.max_number_of_employees}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <Input placeholder="# OF Branches" type="number" value={subscription?.max_number_of_branches}
                        onChange={(e) => changeNumberField(e.target.value ,'max_number_of_branches')}/>
                    {errors?.max_number_of_branches ? <FieldError message={errors?.max_number_of_branches}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <Input placeholder="# OF Members" type="number" value={subscription?.max_number_of_members}
                        onChange={(e) => changeNumberField(e.target.value ,'max_number_of_members')}/>
                    {errors?.max_number_of_members ? <FieldError message={errors?.max_number_of_members}/> : ''}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6 pl-0">
                    <textarea placeholder="English Short Description"
                        rows={4}
                        value={subscription?.short_description_en}
                        className="rounded-box"
                        onChange={(e) => changeFiled(e.target.value ,'short_description_en')}></textarea>
                    {errors?.short_description_en ? <FieldError message={errors?.short_description_en}/> : ''}
                </div>
                <div className="col-sm-6 pr-0">
                    <textarea placeholder="الوصف المختصر بالعربي"
                        rows={4}
                        value={subscription?.short_description_ar}
                        className="rounded-box ar"
                        onChange={(e) => changeFiled(e.target.value ,'short_description_ar')}></textarea>
                    {errors?.short_description_ar ? <FieldError message={errors?.short_description_ar}/> : ''}
                </div>
            </div>
        </Fragment>
    )
}

export default SubscriptionInputs