import { useContext, useState } from 'react'
import '../../../../FormShared/small-form.css'
import ModalButtons from '../../../../FormShared/ModalButtons'
import ModalHeader from '../../../../FormShared/ModalHeader'
import Input from '../../../../FormShared/Input'
import FieldError from '../../../../FormShared/FieldError'
import axios from "../../../../../Axios"
import Config from '../../../../../Config'
import { useAlert } from 'react-alert'
import AppContext from '../../../../partials/AppContext'

const AddCategory = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const [category ,setCategory] = useState({name_en: '' ,name_ar: ''})
    const [errors ,setErrors] = useState({name_en: '' ,name_ar: ''})
    const [disabled ,setDisabled] = useState(false)

    const changeNameEn = (e) => {
        setCategory({...category ,name_en : e.target.value})
        setErrors({...errors ,name_en: ''})
    }

    const changeNameAr = (e) => {
        setCategory({...category ,name_ar : e.target.value})
        setErrors({...errors ,name_ar: ''})
    }

    const storeCategory = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/expenses-categories` ,category)
        .then(response => {
            setDisabled(false)
            props.cancel()
            props.reloadData()
            alert.success(`Expense category ${response?.data?.data?.name_en} ,created successfully`)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Expense Category" cancel={props.cancel}/>
                    <form>
                        <div className="section">
                            <div className="col-sm-12 pad0">
                                <div className="form-group">
                                    <Input placeholder="Expense Category" value={category.name_en} onChange={changeNameEn} />
                                </div>
                                {errors.name_en ? <FieldError message={errors.name_en}/> : ''}
                            </div>
                            <div className="col-sm-12 pad0">
                                <div className="form-group">
                                    <Input isAr={true} placeholder="إسم الفئة" value={category.name_ar} onChange={changeNameAr} />
                                </div>
                                {errors.name_ar ? <FieldError message={errors.name_ar}/> : ''}
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={storeCategory} confirmLabel="Add Category" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddCategory