import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import Pagination from "../../../../Shared/Pagination"
import PrintButton from "../../../../Shared/PrintButton"
import SearchBox from "../../../../Shared/SearchBox"
import Table from "../../../../Shared/Table"
import Thead from "../../../../Shared/Thead"
import Add from "./Add"
import Delete from "./Delete"
import Edit from "./Edit"
import SubscribeGym from "./SubscribeGym"
import UploadCsv from "./UploadCsv"
import ManageModal from './Management/Modal'

const Index = ({visible ,data ,setData}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym`

    const [addClick ,setAddClick] = useState(undefined)
    const [uploadFile ,setUploadFile] = useState(undefined)
    const [deleteClick ,setDeleteClick] = useState(undefined)
    const [editClick ,setEditClick] = useState(undefined)
    const [subscribeClick ,setSubscribeClick] = useState(undefined)
    const [showInfoClick ,setShowInfoClick] = useState(undefined)

    const dataList = data?.list,
        keyword = data?.keyword,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        headerTitle = data?.headerTitle,
        meta = data?.meta

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()
    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        let urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}`
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const gymsCount = response?.data?.data?.length,
                gyms = response?.data?.data,
                headerTitle = `${gymsCount} Gym${gymsCount > 1 ? 's' : ''}`,
                meta = response?.data?.meta
            setData({
                ...data ,
                list: gyms,
                meta: meta,
                headerTitle: headerTitle
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setData ,clearUserData ,dataPerPage ,currentPage ,userToken ,entityUrl])

    const cancelModal = () => {
        setAddClick(undefined)
        setUploadFile(undefined)
        setDeleteClick(undefined)
        setShowInfoClick(undefined)
        setSubscribeClick(undefined)
        setEditClick(undefined)
    }

    const searchTrigger = () => {
        if (currentPage !== 1) setData({...data ,currentPage: 1})
        else loadData()
    }

    const headers = [
        {value: 'Gym`s Name'} ,{value: 'Registration Date'},
        {value: 'Phone'} ,{value: 'Email'} ,{value: 'Address'},
        {value: 'Gender'} ,{value: 'Bar'} ,{value: 'Actions' ,className: 'actions'}
    ]

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    const reloadForDelete = () => {
        if (dataList === 1 && currentPage > 1) setData({...data ,currentPage: currentPage - 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage !== 1) setData({...data ,currentPage: 1})
        else loadData()
    }

    return (
        <div style={{display: visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox searchTrigger={searchTrigger} setKeyword={v => setData({...data ,keyword: v})} keyword={keyword}/>
                    <button className="btn btn-light" onClick={() => {
                        cancelModal()
                        setAddClick(true)
                    }}> Add Gym </button>
                    <button className="btn-dark"
                        style={{
                            width: 48,
                            height: 48,
                            borderRadius: '50%',
                            fontSize: 22,
                            backgroundColor: '#191919'
                        }}
                        onClick={() => {
                            cancelModal()
                            setUploadFile(true)
                        }}>
                        <i className="fas fa-file-upload"></i>
                    </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={headers}/>
                        <tbody>
                            {dataList?.map?.((row ,index) => <tr key={index}>
                                <td> { row?.name } </td>
                                <td> { row?.date } </td>
                                <td> { row?.phone } </td>
                                <td> { row?.email } </td>
                                <td> { row?.address } </td>
                                <td> { row?.gender } </td>
                                <td> { row?.bar } </td>
                                <td className="actions">
                                    <button title="Gym Information" onClick={() => {
                                        cancelModal()
                                        setShowInfoClick({id: row?.id ,name: row?.name})
                                    }}>
                                        <i className="fas fa-info-circle"></i>
                                    </button>
                                    <button title="Gym Subscribe" onClick={() => {
                                        cancelModal()
                                        setSubscribeClick({id: row?.id ,name: row?.name})
                                    }}>
                                        <i className="fas fa-expand-arrows-alt"></i>
                                    </button>
                                    <button title="Edit Gym" onClick={() => {
                                        cancelModal()
                                        setEditClick(row?.id)
                                    }}>
                                        <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                                    </button>
                                    <button title="Delete Gym" onClick={() => {
                                        cancelModal()
                                        setDeleteClick({id: row?.id ,name: row?.name})
                                    }}>
                                        <img alt="Gymme" src="/assets/svg/delete.svg"/>
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                    <Pagination
                        changeCurrentPage={v => setData({...data ,currentPage: v})} currentPage={currentPage}
                        dataPerPage={dataPerPage} changeDataPerPage={v => setData({...data ,dataPerPage: v})} meta={meta}/>
                </div>
            </div>
            {addClick ? <Add cancel={cancelModal} reload={reloadForAdd}/> : ''}
            {editClick ? <Edit id={editClick} cancel={cancelModal} reload={loadData}/> : ''}
            {deleteClick ? <Delete name={deleteClick?.name} id={deleteClick?.id} cancel={cancelModal} reload={reloadForDelete}/> : ''}
            {uploadFile ? <UploadCsv cancel={cancelModal} /> : ''}
            {subscribeClick ? <SubscribeGym cancel={cancelModal} reload={loadData} gymId={subscribeClick?.id}/> : null}
            {showInfoClick ? <ManageModal cancel={cancelModal} gym={showInfoClick}/> : null}
        </div>
    )
}

export default Index