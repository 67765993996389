const DateInput = props => {
    return (
        <input className={props.className ? props.className : `rounded-box ${props.isAr ? 'ar' : ''}`}
            placeholder={props.placeholder ? props.placeholder : ''}
            value={props.value ? props.value : ''}
            min={props?.min}
            max={props?.max}
            type={props.type ? props.type : "date"}
            disabled={props.isDisabled ? props.isDisabled : false}
            onChange={props.onChange}/>
    )
}

export default DateInput