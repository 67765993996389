import { useAlert } from 'react-alert'
import { useContext, useState } from 'react'
import axios from "../../../../Axios"
import Config from '../../../../Config'
import '../../../FormShared/form.css'
import ModalHeader from '../../../FormShared/ModalHeader'
import ModalButtons from '../../../FormShared/ModalButtons'
import AccountCommonInputs from './AccountCommonInputs'
import PhotoInput from './PhotoInput'
import AppContext from '../../../partials/AppContext'

const AddAccount = (props) => {
    const alert = useAlert()
    const [account ,setAccount] = useState({username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,photo: {} ,role: {id: '' ,name: ''}})
    const [errors ,setErrors] = useState({username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,role: '' ,photo: ''})
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    
    const updateAccountAttr = (value ,attr) => {
        const _account = {...account}
        _account[attr] = value
        setAccount(_account)
        const _errors = errors
        _errors[attr] = ''
        setErrors(_errors)
    }

    const addRole = (id ,name) => {
        setAccount({...account ,role: {id ,name}})
    }

    const storeAccount = () => {
        const formData = new FormData()
        Object.keys(account).forEach(attr => formData.append(attr ,account[attr]))
        formData.set('roles[0]' ,account.role.id)
        formData.set('photo' ,account.photo)
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/admin-accounts/admins` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`Account : ${response?.data?.data?.name} ,is created successfully`)
            props.reloadData()
            props.cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errors = {...errors ,...validation}
                Object.keys(validation).forEach(_error => {
                    if (_error.includes('roles')) {
                        _errors.role = validation[_error]
                    }
                })
                setErrors(_errors)
            } else if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Account" cancel={props.cancel}/>
                    <form>
                        <PhotoInput updatePhoto={(img) => updateAccountAttr(img ,'photo')} error={errors.photo} />
                        <div className="section">
                            <AccountCommonInputs addRole={addRole} account={account} updateAccountAttr={updateAccountAttr} errors={errors} />
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={storeAccount} confirmLabel="Add Account" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddAccount