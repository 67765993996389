import FieldError from "../../../FormShared/FieldError"
import PhotoInput from "../../MoreSection/Accounts/PhotoInput"
import Input from "../../../FormShared/Input"
import DropDown from "../../../FormShared/DropDown"
import { useState } from "react"

const Inputs = ({
    bannerAd ,
    setBannerAd ,
    errors ,
    setErrors ,
    photo ,
    setPhoto ,
    defaultImg = undefined
}) => {
    const [selectedType ,setSelectedType] = useState({name: "Both" ,value: 'both'})
    const types = [
        {name: "Both" ,value: 'both'},
        {name: "Owner" ,value: 'owner'},
        {name: "Branch" ,value: 'branch'}
    ]

    return (
        <>
            <PhotoInput
                defaultImg={defaultImg}
                title="Upload Banner Image"
                updatePhoto={(img) => {
                    setPhoto(img)
                    setErrors({...errors ,photo: ""})
                }}
                error={errors.photo} />
            <div className="section" style={{paddingBottom: 10}}>
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <Input
                            type="number"
                            placeholder="Skip Days"
                            value={bannerAd?.skip_days}
                            onChange={e => {
                                setErrors({...errors ,skip_days: ""})
                                setBannerAd({...bannerAd ,skip_days: e.target.value})
                            }}/>
                        {errors.skip_days ? <FieldError message={errors.skip_days}/> : ''}
                    </div>
                    <div className="col-sm-6 pr-0">
                        <DropDown
                            styles={{maxWidth: '100%'}}
                            selectedName={selectedType.name}
                            list={types}
                            itemName="name"
                            select={type => {
                                setSelectedType({name: type?.name ,value: type?.value})
                                setErrors({...errors ,ads_for: ""})
                                setBannerAd({...bannerAd ,ads_for: type?.value})
                            }} />
                        {errors.ads_for ? <FieldError message={errors.ads_for}/> : ''}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <Input
                            type="date"
                            placeholder="Start Date"
                            value={bannerAd?.start_date}
                            onChange={e => {
                                setErrors({...errors ,start_date: ""})
                                setBannerAd({...bannerAd ,start_date: e.target.value})
                            }}/>
                        {errors.start_date ? <FieldError message={errors.start_date}/> : ''}
                    </div>
                    <div className="col-sm-6 pr-0">
                        <Input
                            type="date"
                            placeholder="End Date"
                            value={bannerAd?.end_date}
                            onChange={e => {
                                setErrors({...errors ,end_date: ""})
                                setBannerAd({...bannerAd ,end_date: e.target.value})
                            }}/>
                        {errors.end_date ? <FieldError message={errors.end_date}/> : ''}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 pl-0">
                        <textarea
                            className='rounded-box'
                            placeholder="English Description"
                            value={bannerAd?.description_en}
                            onChange={e => {
                                setErrors({...errors ,description_en: ""})
                                setBannerAd({...bannerAd ,description_en: e.target.value})
                            }}
                        ></textarea>
                        {errors.description_en ? <FieldError message={errors.description_en}/> : ''}
                    </div>
                    <div className="col-sm-6 pr-0">
                        <textarea
                            className='rounded-box ar'
                            placeholder="الوصف بالعربي"
                            value={bannerAd?.description_ar}
                            onChange={e => {
                                setErrors({...errors ,description_ar: ""})
                                setBannerAd({...bannerAd ,description_ar: e.target.value})
                            }}
                        ></textarea>
                        {errors.description_ar ? <FieldError ar={true} message={errors.description_ar}/> : ''}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Inputs