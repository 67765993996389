import Config from "../../../../Config"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Input from "../../../FormShared/Input"
import FieldError from "../../../FormShared/FieldError"
import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import AppContext from "../../../partials/AppContext"
import axios from "../../../../Axios"

const Offer = (props) => {
    const {userToken ,clearUserData} = useContext(AppContext)
    const alert = useAlert()
    const [disabled ,setDisabled] = useState(false)

    const subscriptionPrice = props?.subscriptionPrice,
        subscriptionYearlyPrice = props?.subscriptionYearlyPrice
    //, yearly_discount
    const offerId = props.offerId ? props.offerId : undefined

    const [offer ,setOffer] = useState({
        gym_membership_id: props.membershipId,
        offer_start: props?.offer?.start,
        offer_end: props?.offer?.end,
        discount_percentage: props?.offer?.discount,
        price_after_discount: subscriptionPrice - (props?.offer?.discount ? subscriptionPrice * props?.offer?.discount / 100 : 0),
        yearly_discount_percentage: props?.offer?.yearly_discount,
        yearly_price_after_discount: subscriptionYearlyPrice - (props?.offer?.yearly_discount ? subscriptionYearlyPrice * props?.offer?.yearly_discount / 100 : 0),
    })

    const entityUrl = `${Config.apiBaseUrl}/gym-membership/gym-membership/${offerId ? `editOffer/${offerId}` : 'addOffer'}`
    const [errors ,setErrors] = useState({offer_start: '', offer_end: '', discount_percentage: '', price_after_discount: ''})

    const changeDiscount = (e) => {
        const v = e.target.value
        if (v < 0 || v > 100) {
            alert.error('Discount most be a valid percentage')
            return
        }
        setOffer({...offer ,discount_percentage: v ,price_after_discount: subscriptionPrice - (subscriptionPrice * v / 100)})
        setErrors({...errors ,discount_percentage : '' ,price_after_discount: ''})
    }

    const changeYearlyDiscount = e => {
        const v = e.target.value
        if (v < 0 || v > 100) {
            alert.error('Yearly discount most be a valid percentage')
            return
        }
        setOffer({...offer ,yearly_discount_percentage: v ,yearly_price_after_discount: subscriptionYearlyPrice - (subscriptionYearlyPrice * v / 100)})
        setErrors({...errors ,yearly_discount_percentage : '' ,yearly_price_after_discount: ''})
    }

    const changeStart = (e) => {
        setOffer({...offer ,offer_start: e.target.value})
        setErrors({...errors ,offer_start : ''})
    }

    const changeEnd = (e) => {
        setOffer({...offer ,offer_end: e.target.value})
        setErrors({...errors ,offer_end : ''})
    }

    const submitOfferData = () => {
        const data = {...offer ,status: 1}
        if (offerId) data._method = 'PUT'
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`Offer ${offerId ? 'updated' : 'created'} successfully`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`${offerId ? 'Update' : 'Add'} Offer`} cancel={props.cancel}/>
                    <form>
                        <div>
                            <div className="section" style={{paddingBottom: 10}}>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="number" placeholder="Monthly Discount Percentage" value={offer.discount_percentage}
                                        onChange={changeDiscount}/>
                                    {errors?.discount_percentage ? <FieldError message={errors?.discount_percentage}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="number" placeholder="Yearly Discount Percentage" value={offer.yearly_discount_percentage}
                                        onChange={changeYearlyDiscount}/>
                                    {errors?.yearly_discount_percentage ? <FieldError message={errors?.yearly_discount_percentage}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="date" placeholder="Start Date" value={offer.offer_start}
                                        onChange={changeStart}/>
                                    {errors?.offer_start ? <FieldError message={errors?.offer_start}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="date" placeholder="End Date" value={offer.offer_end}
                                        onChange={changeEnd}/>
                                    {errors?.offer_end ? <FieldError message={errors?.offer_end}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input placeholder="Monthly Price After Discount" value={offer.price_after_discount} readOnly={true}/>
                                    {errors?.price_after_discount ? <FieldError message={errors?.price_after_discount}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input placeholder="Yearly Price After Discount" value={offer.yearly_price_after_discount} readOnly={true}/>
                                    {errors?.yearly_price_after_discount ? <FieldError message={errors?.yearly_price_after_discount}/> : ''}
                                </div>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled}
                            cancel={props.cancel} confirm={submitOfferData} confirmLabel={`${offerId ? 'Update' : 'Add'} Offer`} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Offer