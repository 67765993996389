const DropDown = (props) => {
    return (
        <div className="dropdown form-group custom-dropdown-js">
            <button style={{...props.styles}} className="rounded-box" type="button"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="dropdown-title"> {props?.selectedName} </span>
                <img alt="Gymme" className="dropdown-icon" src="/assets/svg/chevron-down-solid-dark.svg"/>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                    props?.list?.map((item) => {
                        return (
                            <button key={Math.random()}
                                type="button"
                                className="dropdown-item"
                                onClick={() => props?.select(item)}>
                                {item[props.itemName]}
                            </button>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DropDown