import { useContext, useState } from "react"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"
import Axios from "../../../../Axios"
import { useAlert } from "react-alert"
import AppContext from "../../../partials/AppContext"
import Inputs from "./Inputs"

const Add = ({cancel ,reloadData ,entityUrl}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const [errors ,setErrors] = useState({
        description_en: "" ,description_ar: "" ,photo: "" ,start_date: "" ,end_date: "" ,ads_for: '' ,skip_days: ''
    })
    const [bannerAd ,setBannerAd] = useState({
        description_en: "" ,description_ar: "" ,start_date: "" ,end_date: "" ,ads_for: '' ,skip_days: ''
    })
    const [photo ,setPhoto] = useState()

    const confirm = () => {
        const data = new FormData()
        data.set("photo" ,photo)
        Object.keys(bannerAd).forEach(key => data.set(key ,bannerAd[key]))
        
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            cancel()
            reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,response = error?.response?.data ,validations = error?.response?.data?.errors
            if (validations) setErrors(validations)
            else if (response?.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Banner Ad" cancel={cancel}/>
                    <form>
                        <Inputs
                            bannerAd={bannerAd}
                            setBannerAd={setBannerAd}
                            errors={errors}
                            setErrors={setErrors}
                            photo={photo}
                            setPhoto={setPhoto}
                        />
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={confirm} confirmLabel="Add Banner Ad" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add