import { useContext, useEffect, useState } from 'react'
import '../../../../FormShared/small-form.css'
import ModalButtons from '../../../../FormShared/ModalButtons'
import ModalHeader from '../../../../FormShared/ModalHeader'
import Input from '../../../../FormShared/Input'
import FieldError from '../../../../FormShared/FieldError'
import axios from "../../../../../Axios"
import Config from '../../../../../Config'
import { useAlert } from 'react-alert'
import AppContext from '../../../../partials/AppContext'
import DropDown from '../../../../FormShared/DropDown'

const AddSubCategory = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const [subCategory ,setSubCategory] = useState({name_en: '' ,name_ar: '' ,category: {id: '' ,name: 'Category'}})
    const [errors ,setErrors] = useState({name_en: '' ,name_ar: '' ,category_id: ''})
    const [categories ,setCategories] = useState([])
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${Config.apiBaseUrl}/expenses-categories/getAll` ,{cancelToken: axiosSource.token})
        .then(response => setCategories(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.show(`Call technical support`)
            props.cancel()
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setCategories ,alert])

    const changeNameEn = (e) => {
        setSubCategory({...subCategory ,name_en : e.target.value})
        setErrors({...errors ,name_en: ''})
    }

    const changeNameAr = (e) => {
        setSubCategory({...subCategory ,name_ar : e.target.value})
        setErrors({...errors ,name_ar: ''})
    }

    const changeCategory = (category) => {
        setSubCategory({...subCategory ,category: {id: category.id ,name: category.name_en}})
        setErrors({...errors ,category_id: ''})
    }

    const storeSubCategory = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/expenses-sub-categories` ,{...subCategory ,category_id : subCategory.category.id})
        .then(response => {
            setDisabled(false)
            props.cancel()
            props.reloadData()
            alert.success(`Expense sub-category ${response?.data?.data?.name_en} ,created successfully`)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Expense Sub-Category" cancel={props.cancel}/>
                    <form>
                        <div className="section">
                            <div className="col-sm-12 pad0">
                                <div className="form-group">
                                    <Input placeholder="Expense Sub-Category" value={subCategory.name_en} onChange={changeNameEn} />
                                </div>
                                {errors.name_en ? <FieldError message={errors.name_en}/> : ''}
                            </div>
                            <div className="col-sm-12 pad0">
                                <div className="form-group">
                                    <Input isAr={true} placeholder="إسم الفئة" value={subCategory.name_ar} onChange={changeNameAr} />
                                </div>
                                {errors.name_ar ? <FieldError message={errors.name_ar}/> : ''}
                            </div>
                            <div className="col-sm-12 pad0">
                                <DropDown
                                    selectedName={subCategory?.category?.name}
                                    list={categories}
                                    itemName="name_en"
                                    select={changeCategory} />
                                {errors.category_id ? <FieldError message={errors.category_id}/> : ''}
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={storeSubCategory} confirmLabel="Add Sub-Category" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddSubCategory