import { useContext ,useState } from "react"
import axios from '../../../../../Axios'
import DangerousAlert from "../../../../Shared/DangerousAlert"
import { useAlert } from "react-alert"
import AppContext from "../../../../partials/AppContext"

const DeactivateGym = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const confirmDeactivate = (id) => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .put(`${props.entityUrl}/${id}`)
        .then(response => {
            setDisabled(false)
            alert.success(`Gym "${response?.data?.data?.name}" is deactivated successfully`)
            props.cancel()
            props.loadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={`Deactivate Gym`}
                message={`Are you sure you want to permanently deactivate this gym "${props.gym.name}" ? Please note you can’t undo this action`}
                confirmLabel="Yes Deactivate"
                confirm={() => confirmDeactivate(props.gym.id)}
                cancel={props.cancel}
            />
        </div>
    )
}

export default DeactivateGym