const Input = (props) => {
    const showSpan = props.placeholder && props.value

    const spanStyle = {
        position: 'absolute' ,top: 13 ,color: 'gray' ,fontSize: 12
    }

    if (props.isAr) {
        spanStyle.right = 25
    } else {
        spanStyle.left = 25
    }



    if (props.readOnly) {
        return (
            <div style={{position: 'relative' ,width: '100%'}}>
                {showSpan ? <span style={spanStyle}> {props.placeholder} </span> : null}
                <input className={`rounded-box ${props.isAr ? 'ar' : ''}`}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    value={props.value ? props.value : ''}
                    type={props.type ? props.type : 'text'}
                    readOnly={true}/>
            </div>
        )
    }
    return (
        <div style={{position: 'relative' ,width: '100%'}}>
            {showSpan ? <span style={spanStyle}> {props.placeholder} </span> : null}
            <input className={`rounded-box ${props.isAr ? 'ar' : ''}`}
                placeholder={props.placeholder ? props.placeholder : ''}
                value={props.value ? props.value : ''}
                type={props.type ? props.type : 'text'}
                onChange={props.onChange}/>
        </div>
    )
}

export default Input