import './login.css'
import $ from 'jquery'
import { useContext, useState } from 'react'
import {NavLink} from 'react-router-dom'
import axios from '../../Axios'
import Config from '../../Config'
import FieldError from '../FormShared/FieldError'
import AppContext from '../partials/AppContext'

const Login = (props) => {
    const {user ,setUserData ,clearUserData ,setToken} = useContext(AppContext)
    const [showPass ,setShowPass] = useState(false)
    const [username ,setUsername] = useState('')
    const [pass ,setPass] = useState('')
    const [error ,setError] = useState('')
    const [btnDisabled ,setBtnDisabled] = useState(false)
    const tokenizeUser = () => {
        setBtnDisabled(true)
        setError('')
        const credentials = {
            user: username, password: pass, remember: $("#remember-me").prop('checked')
        }
        axios.post(`${Config.apiBaseUrl}/auth-layer/admin/login` ,credentials)
        .then(response => {
            const token = response?.data?.token ,user = response?.data?.admin
            if (token && user) {
                setUserData({
                    ...user,
                    email: user?.email,
                    avatar: user?.avatar,
                    name: user?.name,
                    username: user?.username,
                    phone: user?.phone
                })
                setToken(token ,credentials.remember)
            } else {
                setError('Sorry you can`t login for now ,call technical support')
                setBtnDisabled(false)
                clearUserData()
            }
        })
        .catch(err => {
            setBtnDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) setError(error?.response?.data?.message)
        })
    }

    return (
        <div className="sign-in-form" style={{direction: 'ltr', overflow: 'auto'}}>
            <div className="bg">
                <img alt="Gymme" src="/assets/contact-us@2x.png"/>
            </div>
            <div className="login-form-container en">
                <form autoComplete="off">
                    <img alt="Gymme" src="/assets/svg/logo.svg"/>
                    <input className="rounded-box input-box"
                        type="text" placeholder="Username or Email"
                        value={username} onChange={(e) => setUsername(e.target.value)}/>
                    <div className="password input-box">
                        <input id="sign-in-pass" className="rounded-box"
                            type={showPass ? 'text' : 'password'} placeholder="Password"
                            value={pass} onChange={(e) => setPass(e.target.value)}/>
                        <button type="button" className="toggler-icon"
                            onClick={() => setShowPass(!showPass) }><i className="fas fa-eye"></i></button>
                    </div>
                    {error ? <FieldError message={error}/> : ''}
                    <label className="checkbox en">
                        <div className="checkbox-btn">
                            <input id="remember-me" type="checkbox"/>
                            <span className="input-checkbox"></span>
                        </div>
                        <h6 className="checkbox-text">Remember Me</h6>
                    </label>
                    <button type="button" className="btn btn-light"
                        disabled={btnDisabled}
                        onClick={tokenizeUser}>sign in</button>
                    <NavLink to="/forget-password" exact> forget your password? </NavLink>
                </form>
            </div>
            <footer>© All Right Reserved © {new Date().getFullYear()}</footer>
        </div>
    )
}

export default Login