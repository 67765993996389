import axios from "../../../../../Axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import PrintButton from "../../../../Shared/PrintButton"
import SearchBox from "../../../../Shared/SearchBox"
import Pagination from "../../../../Shared/Pagination"
import DeactivateGym from "./DeactivateGym"
import GymRow from "./GymRow"
import RenewalGym from "./RenewalGym"
import Thead from "../../../../Shared/Thead"
import Table from "../../../../Shared/Table"

const Gyms = ({visible ,data ,setData}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/gym`

    const [renewalGym ,setRenewalGym] = useState(undefined)
    const [deactivateGym ,setDeactivateGym] = useState(undefined)
    const gyms = data?.list,
        keyword = data?.keyword,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        headerTitle = data?.headerTitle,
        meta = data?.meta

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()
    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        let urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}`
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const gymsCount = response?.data?.data?.length,
                gyms = response?.data?.data,
                headerTitle = `${gymsCount} Gym${gymsCount > 1 ? 's' : ''}`,
                meta = response?.data?.meta
            setData({
                ...data ,
                list: gyms,
                meta: meta,
                headerTitle: headerTitle
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setData ,clearUserData ,dataPerPage ,currentPage ,userToken ,entityUrl])

    const renewal = (id ,name) => {
        setDeactivateGym(undefined)
        setRenewalGym({id ,name})
    }

    const deactivate = (id ,name) => {
        setRenewalGym(undefined)
        setDeactivateGym({id ,name})
    }

    const cancelModal = () => {
        setDeactivateGym(undefined)
        setRenewalGym(undefined)
    }

    const searchTrigger = () => {
        if (currentPage !== 1) setData({...data ,currentPage: 1})
        else loadData()
    }

    const headers = [
        {value: 'Logo'} ,{value: 'Gym`s Name'} ,{value: 'Registration Date'},
        {value: 'Phone'} ,{value: 'Email'} ,{value: 'Renwal'} ,{value: 'Members'} ,{value: 'Subscription'},
        {value: 'Price'} ,{value: 'Actions' ,className: 'actions'}
    ]

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div style={{display: visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox searchTrigger={searchTrigger} setKeyword={v => setData({...data ,keyword: v})} keyword={keyword}/>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={headers}/>
                        <tbody>
                            {gyms?.map?.(gym => <GymRow renewal={renewal} deactivate={deactivate} key={gym.id} gym={gym}/>)}
                        </tbody>
                    </Table>
                    <Pagination
                        changeCurrentPage={v => setData({...data ,currentPage: v})} currentPage={currentPage}
                        dataPerPage={dataPerPage} changeDataPerPage={v => setData({...data ,dataPerPage: v})} meta={meta}/>
                </div>
            </div>
            {renewalGym ? <RenewalGym gym={renewalGym} entityUrl={entityUrl} cancel={cancelModal} loadData={loadData} /> : ''}
            {deactivateGym ? <DeactivateGym gym={deactivateGym} entityUrl={entityUrl} cancel={cancelModal} loadData={loadData} /> : ''}
        </div>
    )
}

export default Gyms