import axios from "../../../../Axios"
import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Navbar from "../../../partials/Navbar"
import PrintButton from "../../../Shared/PrintButton"
import SearchBox from "../../../Shared/SearchBox"
import Pagination from "../../../Shared/Pagination"
import ApproveRequest from "./ApproveRequest"
import GymDetails from "./GymDetails"
import RejectRequest from "./RejectRequest"
import RequestRow from "./RequestRow"
import Thead from "../../../Shared/Thead"
import Table from "../../../Shared/Table"

const GymRequests = (props) => {
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/gym-request`
    const {clearUserData ,userToken} = useContext(AppContext)
    const [invokeApprove ,setInvokeApprove] = useState(undefined)
    const [invokeReject ,setInvokeReject] = useState(undefined)
    const [subscription ,setSubscription] = useState('')
    const [subscriptions ,setSubscriptions] = useState([])
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [requests ,setRequests] = useState([])
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(props.defaultPage ? props.defaultPage : 1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [requestDetails ,setRequestDetails] = useState(undefined)

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        let urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}`
        urlQuery += `&subsKey=${subscription !== 'Subscriptions' ? subscription : ''}`
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const requestsCount = response?.data?.data?.length,
                requests = response?.data?.data,
                headerTitle = `${requestsCount} Request${requestsCount > 1 ? 's' : ''}`,
                meta = response?.data?.meta
            setRequests(requests)
            setMeta(meta)
            setHeaderTitle(headerTitle)
            executeScroll()
            
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setRequests ,setMeta ,alert ,dataPerPage ,currentPage ,entityUrl ,subscription])

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/subscriptions` ,{cancelToken: axiosSource.token})
        .then(response => {
            setSubscriptions(['Subscriptions' ,...response?.data?.subscriptions])
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSubscriptions])

    const approveRequest = (requestId ,requestName) => {
        setInvokeReject(undefined)
        setInvokeApprove({requestId ,requestName})
    }

    const rejectRequest = (requestId ,requestName) => {
        setInvokeApprove(undefined)
        setInvokeReject({requestId ,requestName})
    }

    const cancelModal = () => {
        setInvokeReject(undefined)
        setInvokeApprove(undefined)
    }

    const searchTrigger = () => {
        if (currentPage !== 1) setCurrentPage(1)
        else loadData()
    }

    const showDetails = (requestDetails) => setRequestDetails(requestDetails)

    const navbarTitle = requestDetails ?
    <> <i className="fas fa-arrow-left clickable" onClick={() => showDetails(undefined)}></i> {requestDetails.name} </>
    : 'Request'

    const headers = [
        {value: 'Logo'}, {value: 'Gym`s Name'}, {value: 'Request Date'}, {value: 'Phone'},
        {value: 'Email'}, {value: 'Subscription'}, {value: 'Price'}, {value: 'Actions' ,className: "actions"}
    ]

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={navbarTitle}/>
            </div>
            <div className="page-content" style={{display: requestDetails ? 'none' : 'block'}}>
                <div className="page-section header-box" ref={elRef}>
                    <div className="row header">
                        <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <div className="pad0">
                            <div className="dropdown form-group custom-dropdown-js">
                                <button className="rounded-box" type="button"  data-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <span className="dropdown-title"> {subscription ? subscription : 'Subscriptions'} </span> 
                                    <img alt="Gymme" className="dropdown-icon" src="/assets/svg/chevron-down-solid-dark.svg"/>
                                </button>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    {subscriptions.map(subs =>
                                        <button type="button" className="dropdown-item" key={Math.random()}
                                            onClick={() => setSubscription(subs)}>
                                            {subs}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                {requests.map( request =>
                                    <RequestRow showDetails={showDetails} approve={approveRequest}
                                        reject={rejectRequest} key={request.id} request={request} />
                                )}
                            </tbody>
                        </Table>
                        <Pagination
                            changeCurrentPage={setCurrentPage} currentPage={currentPage}
                            dataPerPage={dataPerPage} changeDataPerPage={setDataPerPage} meta={meta}/>
                    </div>
                </div>
                {invokeApprove ? <ApproveRequest invokeApprove={invokeApprove} entityUrl={entityUrl} cancel={cancelModal} loadData={loadData} /> : ''}
                {invokeReject ? <RejectRequest invokeReject={invokeReject} entityUrl={entityUrl} cancel={cancelModal} loadData={loadData} /> : ''}
            </div>
            <div className="page-content" style={{display: requestDetails ? 'block' : 'none'}}>
                <div className="page-section content profile-card-content">
                    <GymDetails request={requestDetails}/>
                </div>
            </div>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default GymRequests