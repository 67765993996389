const FoodRow = (props) => {
    const item = props?.item
    return (
        <tr>
            <td>{item?.name_en}</td>
            <td>{item?.name_ar}</td>
            <td>{item?.created}</td>
            <td className="actions">
                <button onClick={() => props.changeItemForEdit(item?.id)}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => props.deleteFood(item?.id ,item?.name_en)}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default FoodRow