const GymSuggest = (props) => {
    const gymSuggest = props?.gym_suggest
    return (
        gymSuggest.id ?
        <div className="page-section header-box" style={{marginTop: 0 ,marginBottom: 15}}>
            <div className="row header">
                <div className="gym-suggest">
                    <p>
                        A New Category Name "
                        {gymSuggest?.category}
                        " has been added by gym "
                        {gymSuggest?.gym}
                        " woul you like to add it ?</p>
                    <div>
                        <span onClick={() => props?.callGymSuggest(gymSuggest?.id ,'add')}
                            className="clicked-span"> Add </span>
                        <span> | </span>
                        <span onClick={() => props?.callGymSuggest(gymSuggest?.id ,'skip')}
                            className="clicked-span"> Skip </span>
                    </div>
                </div>
            </div>
        </div>
        : ''
    )
}

export default GymSuggest