import axios from "../../../../Axios"
import { useContext, useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import Config from '../../../../Config'
import AppContext from '../../../partials/AppContext'
import Navbar from '../../../partials/Navbar'
import Pagination from '../../../Shared/Pagination'
import HistoryRow from './HistoryRow'
import FilterBox from '../FilterBox'
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"

const History = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/history-log/admin-logs`

    const [keyword ,setKeyword] = useState('')
    const [histories ,setHistories] = useState([])
    const [actions ,setActions] = useState([])
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(props.defaultPage ? props.defaultPage : 1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [selectedAction ,setSelectedAction] = useState({name: 'Action'})
    const [action ,setAction] = useState('')
    const [month ,setMonth] = useState('')
    const [year ,setYear] = useState('')

    const elRef = useRef()
    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        const urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}&action=${action}&month=${month}&year=${year}`
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            setHistories(response?.data?.data)
            setActions([{name: 'Action'} ,...response?.data?.actions])
            setMeta(response?.data?.meta)
            elRef.current.scrollIntoView()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[
        setHistories ,setMeta ,clearUserData ,setActions,
        entityUrl ,userToken ,alert ,dataPerPage ,currentPage ,action ,month ,year
    ])

    const changeAction = (action) => {
        setSelectedAction(action)
        if (action.name !== 'Action') setAction(action.name)
        else setAction('')
    }

    const triggerSearch = () => {
        if (currentPage !== 1) setCurrentPage(1)
        else loadData()
    }

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="History" />
            </div>
            <div className="page-content" style={{gap: 15}}>
                <div className="page-section header-box" ref={elRef}>
                    <FilterBox 
                        loadData={triggerSearch}
                        setKeyword={setKeyword}
                        keyword={keyword}
                        selectedAction={selectedAction}
                        actions={actions}
                        changeAction={changeAction}
                        changeMonth={setMonth}
                        month={month}
                        changeYear={setYear}
                        year={year}/>
                </div>
                <div className="page-section content" style={{marginTop: 15}}>
                    <div className="table-section">
                        <Table>
                            <Thead headers={[{value: 'Admin action' ,colSpan: 2} ,{value: 'Date'}]}/>
                            <tbody>
                                {histories.map(history => <HistoryRow key={history.id} history={history}/>)}
                            </tbody>
                        </Table>
                        <Pagination
                            changeCurrentPage={setCurrentPage} currentPage={currentPage}
                            dataPerPage={dataPerPage} changeDataPerPage={setDataPerPage} meta={meta}/>
                    </div>
                </div>
            </div>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default History