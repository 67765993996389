import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import ModalButtons from "../../../FormShared/ModalButtons"
import ModalHeader from "../../../FormShared/ModalHeader"

const AddFood = (props) => {
    const entityUrl = `${Config.apiBaseUrl}/bar/food-category`
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const [food ,setFood] = useState({name_en: '' ,name_ar: ''})
    const [errors ,setErrors] = useState({name_en: '' ,name_ar: ''})
    const [disabled ,setDisabled] = useState(false)
    
    const changeNameEn = (e) => {
        setErrors({...errors ,name_en: ''})
        setFood({...food ,name_en: e.target.value})
    }

    const changeNameAr = (e) => {
        setErrors({...errors ,name_ar: ''})
        setFood({...food ,name_ar: e.target.value})
    }

    const addFood = () => {
        const data = {name_en: food?.name_en, name_ar: food?.name_ar}
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}` ,data)
        .then(response => {
            setDisabled(false)
            alert.success("Food created successfully")
            props.cancel()
            props.loadData()
        })
        .catch(err => {
            const error = {...err}
            if (error.response?.data?.errors) setErrors(error.response.data.errors)
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error("Can`t create food for now ,try again later or call technical support")
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Food" cancel={props.cancel}/>
                    <form>
                        <div className="section">
                            <div className="row">
                                <div className="col-sm-12 pad0">
                                    <Input placeholder="Food Category" value={food?.name_en} onChange={changeNameEn}/>
                                    {errors.name_en ? <FieldError message={errors.name_en}/> : ''}
                                </div>
                                <div className="col-sm-12 pad0">
                                    <Input placeholder="قسم المأكولات" isAr={true} value={food?.name_ar} onChange={changeNameAr}/>
                                    {errors.name_ar ? <FieldError message={errors.name_ar}/> : ''}
                                </div>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={addFood} confirmLabel="Add Food" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddFood