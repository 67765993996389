import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import axios from "../../../../Axios"
import BarForm from '../BarForm'

const AddUnit = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [error ,setError] = useState({name_en: '' ,name_ar: ''})
    const [data ,setData] = useState({name_en: '' ,name_ar: ''})

    const addUnit = () => {
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/bar/unit` ,data)
        .then(response => {
            alert.success(`Unit "${response?.data?.data?.name_en}" created successfully`)
            props?.cancel()
            props?.loadData()
        })
        .catch(err => {
            const error = {...err} ,errors = {name_en: '' ,name_ar: ''}
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors?.name_ar)
                    errors.name_ar = error?.response?.data?.errors?.name_ar[0]
                if (error?.response?.data?.errors?.name_en)
                    errors.name_en = error?.response?.data?.errors?.name_en[0]
                setError(errors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <BarForm
            data={{name_en: data?.name_en ,name_ar: data?.name_ar}}
            title="Add Unit"
            placeholder_en="Unit"
            placeholder_ar="الوحدة"
            cancel={props?.cancel}
            confirmLabel={`Add Unit`}
            error={error}
            setError={(field ,errorMsg) => {
                const _tempErr = {...error}
                _tempErr[field] = errorMsg
                setError(_tempErr)
            }}
            setData={(field ,value) => {
                const _tempData = {...data}
                _tempData[field] = value
                setData(_tempData)
            }}
            confirmAction={addUnit}
        />
    )
}

export default AddUnit