import Navbar from "../../../partials/Navbar"

const Index = (props) => {
    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Notifications"/>
            </div>
            <div className="page-content">
                Coming Soon
            </div>
        </div>
    )
}

export default Index