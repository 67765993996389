import {Fragment ,useContext, useState} from 'react'
import { Link, useHistory } from 'react-router-dom'
import AppContext from './AppContext'

const Navbar = (props) => {
    const history = useHistory()
    const {clearUserData ,user ,toggleAsideMenu} = useContext(AppContext)

    const [showProfileWiddget ,setShowProfileWiddget] = useState(false)
    const [showNotificationWiddget ,setShowNotificationWiddget] = useState(false)

    const openWidget = (widget) => {
        switch(widget) {
            case "profile":
                setShowNotificationWiddget(false)
                setShowProfileWiddget(!showProfileWiddget)
            break;
            case "notification":
                setShowNotificationWiddget(!showNotificationWiddget)
                setShowProfileWiddget(false)
            break;
            default:
                console.log('Undefined widget')
            break;
        }
    }

    const logout = () => {
        clearUserData()
        history.push('/')
    }

    const profileImageStyle = {
        borderRadius: '50%'
    }

    const fireToggle = () => {
        const menuLeftValue = window.getComputedStyle(document.getElementById('aside-menu')).getPropertyValue('left')
        if (menuLeftValue === '-242px') toggleAsideMenu(true)
        else toggleAsideMenu(false)
    }

    return (
        <Fragment>
            <button id="burger" className="burger" onClick={fireToggle}>
                <img alt="Gymme" className="full-img" src="/assets/svg/bars-solid.svg"/>
            </button>

            <span className="title"> { props.navbarTitle } </span>

            <button id="open-notifs" onClick={() => openWidget('notification')}>
                <img alt="Gymme" className="full-img" src="/assets/svg/bell.svg"/>
                <span className="mark"></span>
            </button>
            <button id="open-profile" className="profile" onClick={() => openWidget('profile')}>
                <img alt="Gymme" style={profileImageStyle} className="full-img"
                    src={user.avatar ? user.avatar : "/assets/fitness-4879191@2x.png"}/>
            </button>

            <ul className="notifications en" style={{display: `${showNotificationWiddget ? 'flex' : 'none'}`}}>
                <li>
                    {/* <div className="pic">
                        <img alt="Gymme" className="full-img" src="/assets/fitness-4879191@2x.png"/>
                    </div>
                    <div className="details">
                        <span className="branch">Maadi</span>
                        <span className="content"><b>Randy Wagner</b> Added new client <b>“ Adam Jerry “</b></span>
                        <span className="time">5 hours ago</span>
                    </div> */}
                    No Notifications Yet
                </li>
            </ul>
            
            <div className="profile-popup en" style={{display: `${showProfileWiddget ? 'flex' : 'none'}`}}>
                <div className="profile-pic">
                    <img alt="Gymme" className="full-img" src={user.avatar ? user.avatar : "/assets/fitness-4879191@2x.png"} />
                </div>
                <p className="name" style={{textAlign: 'center'}}> {user?.name} </p>
                <p className="mail" style={{textAlign: 'center'}}> {user?.email} </p>
                <ul className="options">
                    <li className="option">
                        <img alt="Gymme" src="/assets/svg/profile.svg" />
                        <Link to="/profile">Personal Profile</Link>
                    </li>
                    <li className="option">
                        <img alt="Gymme" src="/assets/svg/logout.svg"/>
                        <button style={{width:'58px'}} onClick={logout} className="logout" >Log out</button>
                    </li>
                </ul>
            </div>
            
        </Fragment>
    )
}
export default Navbar