import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import FieldError from "../../../../FormShared/FieldError"
import ModalButtons from "../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../FormShared/ModalHeader"
import AppContext from "../../../../partials/AppContext"

const UploadCsv = ({cancel}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym`
    const [disabled ,setDisabled] = useState(false)
    const [file ,setFile] = useState(undefined)
    const [error ,setError] = useState(undefined)

    const confirm = () => {
        setDisabled(true)
        const form = new FormData()
        form.append('csv' ,file)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/upload-csv` ,form)
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.errors) setError(error?.response?.data?.errors?.csv?.join?.(","))
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Upload CSV" cancel={cancel}/>
                    <form>
                        <div style={{
                            width: '100%',
                            textAlign: 'center',
                            color: 'gray'
                        }}>
                            Hint: we will work on first 1000 rows only ,please download
                            <a download="demo.csv" href={`${Config.publicUrl}/gyms-demo.csv`}> Demo </a>
                             and fill data "Don`t edit header"
                        </div>
                        
                        <div className="image-upload" style={{padding: 0, backgroundColor: 'white'}}>
                            <label class="rounded-box">
                                <div class="input-file">
                                    <img src="/assets/svg/folder.svg" alt="Gymme"/>
                                    <span>Browse Files</span>
                                    <input
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files[0]) {
                                                let csv = e.target.files[0]
                                                setError(undefined)
                                                setFile(csv)
                                            } else {
                                                setError('Please upload csv file first')
                                            }
                                        }}
                                        type="file"
                                        accept=".csv"/>
                                </div>
                            </label>
                        </div>
                        {error ? <FieldError message={error}/> : ''}
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={confirm} confirmLabel="Upload File" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UploadCsv