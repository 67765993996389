import { useState } from "react"
import SearchBox from '../../Shared/SearchBox'
import DropDown from "../../FormShared/DropDown"

const FilterBox = (props) => {
    const [selectedMonth ,setSelectedMonth] = useState('Month')
    const [selectedYear ,setSelectedYear] = useState('Year')
    const years = [{name: 'Year' ,value: ''}]
    const months = [
        {name : 'Month' ,value: ''},
        {name : 'January' ,value: '01'},
        {name : 'February' ,value: '02'},
        {name : 'March' ,value: '03'},
        {name : 'April' ,value: '04'},
        {name : 'May' ,value: '05'},
        {name : 'June' ,value: '06'},
        {name : 'July' ,value: '07'},
        {name : 'August' ,value: '08'},
        {name : 'September' ,value: '09'},
        {name : 'October' ,value: '10'},
        {name : 'November' ,value: '11'},
        {name : 'December' ,value: '12'}
    ]

    const changeMonth = (month) => {
        props?.changeMonth(month.value)
        setSelectedMonth(month.name)
    }

    const changeYear = (year) => {
        props?.changeYear(year.value)
        setSelectedYear(year.name)
    }

    const currentYear = new Date().getFullYear()
    for(let i = 0; i < 10; i++) {
        years.push({name: (currentYear - i) ,value: (currentYear - i)})
    }
    return (
        <div className="row header">
            <SearchBox placeholder="Keyword" searchTrigger={props?.loadData} setKeyword={props?.setKeyword} keyword={props?.keyword}/>
            <DropDown
                selectedName={selectedMonth}
                list={months}
                itemName="name"
                select={changeMonth} />
            <DropDown
                selectedName={selectedYear}
                list={years}
                itemName="name"
                select={changeYear} />
            {
                props.actions ?
                <DropDown
                    selectedName={props?.selectedAction?.name}
                    list={props?.actions}
                    itemName="name"
                    select={props?.changeAction} />
                :''
            }
        </div>
    )
}

export default FilterBox