import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../../../../Config"
import axios from "../../../../../../../Axios"

import ModalButtons from "../../../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../../../FormShared/ModalHeader"
import Input from "../../../../../../FormShared/Input"
import FieldError from "../../../../../../FormShared/FieldError"
import AppContext from "../../../../../../partials/AppContext"

const Offer = (props) => {
    const {userToken ,clearUserData} = useContext(AppContext)
    const alert = useAlert()

    const subscriptionPrice = props?.subscriptionPrice
    const offerId = props.offerId ? props.offerId : undefined
    const gymId = props.gymId

    const [offer ,setOffer] = useState({
        subscription_id: props.subscription_id,
        offer_title: props?.offer?.title,
        offer_title_ar: props?.offer?.title_ar,
        offer_start: props?.offer?.start,
        offer_end: props?.offer?.end,
        offer_quantity: props?.offer?.quantity,
        discount_percentage: props?.offer?.discount,
        price_after_discount: subscriptionPrice - (props?.offer?.discount ? subscriptionPrice * props?.offer?.discount / 100 : 0)
    })

    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/subscription/${gymId}/addOffer${offerId ? `/${offerId}` : ''}`
    const [errors ,setErrors] = useState({
        offer_title: '',
        offer_title_ar: '',
        offer_start: '',
        offer_end: '',
        offer_quantity: '',
        discount_percentage: '',
        price_after_discount: '',
    })
    const [disabled ,setDisabled] = useState(false)

    const changeDiscount = (e) => {
        const v = e.target.value
        if (v < 0 || v > 100) {
            alert.error('Discount most be a valid percentage')
            return
        }
        setOffer({...offer ,discount_percentage: v ,price_after_discount: subscriptionPrice - (subscriptionPrice * v / 100)})
        setErrors({...errors ,discount_percentage : '' ,price_after_discount: ''})
    }

    const changeAttr = (attr ,value) => {
        const _errors = {...errors}
        _errors[attr] = ''
        setErrors(_errors)
        const _offer = {...offer}
        _offer[attr] = value
        setOffer(_offer)
    }

    const submitOfferData = () => {
        const data = {...offer ,status: 1}
        if (offerId) data._method = 'PUT'
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(entityUrl ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`Offer ${offerId ? 'updated' : 'created'} successfully`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.data?.errors) setErrors(error?.response?.data?.errors)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`${offerId ? 'Update' : 'Add'} Offer`} cancel={props.cancel}/>
                    <form>
                        <div>
                            <div className="section" style={{paddingBottom: 10}}>
                                <div className="row" style={{gap: 10}}>
                                    <Input placeholder="Title" value={offer.offer_title}
                                        onChange={e => changeAttr('offer_title' ,e.target.value)}/>
                                    {errors?.offer_title ? <FieldError message={errors?.offer_title}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input placeholder="Arabic Title" value={offer.offer_title_ar}
                                        onChange={e => changeAttr('offer_title_ar' ,e.target.value)}/>
                                    {errors?.offer_title_ar ? <FieldError message={errors?.offer_title_ar}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="date" placeholder="Start Date" value={offer.offer_start}
                                        onChange={e => changeAttr('offer_start' ,e.target.value)}/>
                                    {errors?.offer_start ? <FieldError message={errors?.offer_start}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="date" placeholder="End Date" value={offer.offer_end}
                                        onChange={e => changeAttr('offer_end' ,e.target.value)}/>
                                    {errors?.offer_end ? <FieldError message={errors?.offer_end}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10, position: 'relative'}}>
                                    <Input type="number" placeholder="Offer Limit" value={offer.offer_quantity}
                                        onChange={e => changeAttr('offer_quantity' ,e.target.value)}/>
                                    <div className="messages" style={{position: 'absolute' ,bottom: -20}}>
                                        <p className="message" style={{
                                            color: '#807F7F',
                                            fontSize: 11
                                        }}>
                                            Set the number for limited offer or set to “0” for unlimited Offer
                                        </p>
                                    </div>
                                    {errors?.offer_quantity ? <FieldError message={errors?.offer_quantity}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input type="number" placeholder="Discount Percentage" value={offer.discount_percentage}
                                        onChange={changeDiscount}/>
                                    {errors?.discount_percentage ? <FieldError message={errors?.discount_percentage}/> : ''}
                                </div>
                                <div className="row" style={{gap: 10}}>
                                    <Input placeholder="Price After Discount" value={offer.price_after_discount} readOnly={true}/>
                                    {errors?.price_after_discount ? <FieldError message={errors?.price_after_discount}/> : ''}
                                </div>
                            </div>
                        </div>
                        <ModalButtons disabled={disabled}
                            cancel={props.cancel} confirm={submitOfferData} confirmLabel={`${offerId ? 'Update' : 'Add'} Offer`} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Offer