import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import FieldError from "../../../../../../FormShared/FieldError"
import Input from "../../../../../../FormShared/Input"
import ModalButtons from "../../../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../../../FormShared/ModalHeader"
import AppContext from "../../../../../../partials/AppContext"

const Edit = ({gymId ,id ,_title ,_price ,cancel ,reload}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/sessions/${gymId}/${id}`

    const [title ,setTitle] = useState(_title)
    const [price ,setPrice] = useState(_price)
    const [errors ,setErrors] = useState({title: '' ,price: ''})
    const [disabled ,setDisabled] = useState(false)

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .put(entityUrl ,{name: title ,price: price})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.name} updated successfully`)
            reload()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errors = {...errors}
                if (validation.name) _errors.title = validation.name
                if (validation.price) _errors.price = validation.price
                setErrors(_errors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const changeTitle = e => {
        setErrors({...errors ,title: ''})
        setTitle(e.target.value)
    }

    const changePrice = e => {
        setErrors({...errors ,title: ''})
        setPrice(e.target.value)
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Edit Session" cancel={cancel}/>
                    <form>
                        <div className="section" style={{marginBottom: 10}}>
                            <Input placeholder="Title" value={title} onChange={changeTitle}/>
                            {errors.title ? <FieldError message={errors.title} /> : ''}
                            <Input placeholder="Price (EGP)" value={price} onChange={changePrice}/>
                            {errors.price ? <FieldError message={errors.price} /> : ''}
                        </div>
                        <ModalButtons disabled={disabled} cancel={cancel} confirm={saveChanges} confirmLabel="Edit Session" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Edit