import axios from "../../Axios"
import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import { NavLink } from "react-router-dom"
import FieldError from "../FormShared/FieldError"
import './forget.css'
import Config from '../../Config'
import AppContext from "../partials/AppContext"

const Forget = (props) => {
    const {clearUserData} = useContext(AppContext)
    const alert = useAlert()
    const [disabled ,setDisabled] = useState(false)
    const [email ,setEmail] = useState('')
    const [emailError ,setEmailError] = useState('')
    const [emailSent ,setEmailSent] = useState(false)

    const changeMail = (e) => {
        setEmail(e.target.value)
        setEmailError('')
    }
    const sendMail = () => {
        setDisabled(true)
        if (!email) {
            setEmailError('Email is a required field')
            setDisabled(false)
            return
        }
        clearUserData()
        axios.post(`${Config.apiBaseUrl}/auth-layer/admin/forgot-password` ,{email})
        .then(response => {
            alert.success(response?.data?.message)
            setEmailSent(true)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            try {
                setEmailError(error?.response?.data?.errors?.email[0])
            } catch (exceptionErr) {}
            if (!emailError && error?.response?.data?.message) setEmailError(error?.response?.data?.message)
        })
    }
    return (
        <div className="forget-pass-form" style={{direction: 'ltr', overflow: 'auto'}}>
            <NavLink to="/" exact>
                <img alt="Gymme" className="forget-logo" src="/assets/svg/logo.svg"/>
            </NavLink>
            <div className="forget-bg">
                <img src="/assets/forgot-pw@2x.png" alt="Gymme"/>
            </div>
            {
                !emailSent ?
                <div className="forgot-form-container en">
                    <form autoComplete="off">
                        <h2 id="title">Forget password?</h2>
                        <p  id="details">We will send you an email with instructions on how to reset your password.</p>
                        <input id="email" className="rounded-box input-box" type="text"
                            placeholder="Email" value={email} onChange={changeMail}/>
                        {emailError ? <FieldError message={emailError}/> : ''}
                        <button type="button" id="submit-btn"
                            disabled={disabled}
                            onClick={sendMail} className="btn btn-light">email me</button>
                    </form>
                </div>
                :
                <div className="forgot-form-container en">
                    <form autoComplete="off">
                        <h2 id="title">Email sent</h2>
                        <p  id="details">
                            An email with instructions on how to reset your password has been sent to you. 
                            Check your spam or junk folder if you don’t see the email in your inbox.
                        </p>
                        <NavLink to="/" exact>
                            <button type="button" className="btn btn-light">
                                Login Now
                            </button>
                        </NavLink>
                    </form>
                </div>
            }
            <footer>© All Right Reserved © {new Date().getFullYear()}</footer>
        </div>
    )
}

export default Forget