const BarInput = ({changeType ,chosenType}) => {
    return (
        <>
            <div style={{display: 'flex' ,flexDirection: 'row' ,marginTop: 5}}>
                <label class="checkbox" style={{width: '50%'}}>
                    <div class="checkbox-btn">
                        <input type="checkbox"
                            checked={chosenType?.includes('Male')}
                            onChange={() => changeType('Male')}/>
                        <span class="input-checkbox"></span>
                    </div>
                    <h6 class="checkbox-text">Male</h6>
                </label>
                <label class="checkbox">
                    <div class="checkbox-btn">
                        <input type="checkbox"
                            checked={chosenType?.includes('Female')}
                            onChange={() => changeType('Female')}/>
                        <span class="input-checkbox"></span>
                    </div>
                    <h6 class="checkbox-text">Female</h6>
                </label>
            </div>
            <label class="checkbox" style={{width: '50%'}}>
                <div class="checkbox-btn">
                    <input type="checkbox"
                        checked={chosenType?.includes('Mixed')}
                        onChange={() => changeType('Mixed')}/>
                    <span class="input-checkbox"></span>
                </div>
                <h6 class="checkbox-text">Mixed</h6>
            </label>
        </>
    )
}

export default BarInput