import { useState } from "react"

const AccountCard = (props) => {
    const [photo ,setPhoto] = useState(props.account?.photo)
    const [photoClass ,setPhotoClass] = useState('full-img')
    const getDefaultImage = () => {
        setPhoto("/assets/svg/logo-icon.svg")
        setPhotoClass("full-img default-img")
    }

    const name = props?.account?.name ? props?.account?.name : ''
    const username = props?.account?.username ? props?.account?.username : ''
    const email = props?.account?.email ? props?.account?.email : ''
    const phone = props?.account?.phone ? props?.account?.phone : ''
    let role = ''
    try {
        role = props.account.roles[0].name_en
    } catch (err) {}

    return (
        <div className="account-item">
            <div className="img">
                <img alt="Gymme" src={photo} onError={getDefaultImage} className={photoClass}/>
            </div>
            <div className="name-section">
                <span className="name"> {name.substring(0, 18) + (name.length >= 17 ? '...' : '')} </span>
                <div className="controls">
                    <button onClick={() => props.editItem(props.account?.id)}>
                        <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                    </button>
                    <button onClick={() => props.deleteItem(props.account?.id ,props.account?.name)}>
                        <img alt="Gymme" src="/assets/svg/delete.svg"/>
                    </button>
                </div>
            </div>
            <div className="content">
                <span className="title">Roles </span>
                <span className="data"> { role } </span>
                
                <span className="title">Username </span>
                <span className="data"> {username.substring(0, 15) + (username.length >= 15 ? '...' : '')} </span>

                <span className="title">Email </span>
                <span className="data"> {email.substring(0, 15) + (email.length >= 15 ? '...' : '')} </span>
                
                <span className="title">phone </span>
                <span className="data"> {phone.substring(0, 15) + (phone.length >= 15 ? '...' : '')} </span>
            </div>
        </div>
    )
}

export default AccountCard