import { useContext, useState } from "react"
import axios from "../../../../Axios"
import DangerousAlert from "../../../Shared/DangerousAlert"
import FieldError from "../../../FormShared/FieldError"
import Input from "../../../FormShared/Input"
import { useAlert } from "react-alert"
import AppContext from "../../../partials/AppContext"

const RejectRequest = (props) => {
    const alert = useAlert()
    const [rejectReason ,setRejectReason] = useState('')
    const [error ,setError] = useState(false)
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const changeReason = (e) => {
        setRejectReason(e.target.value)
        setError(false)
    }

    const confirmRejectRequest = (requestId) => {
        if (!rejectReason) {
            setError(true)
            return
        }
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .put(`${props.entityUrl}/reject/${props.invokeReject.requestId}` ,{rejection_reason: rejectReason})
        .then(response => {
            setDisabled(false)
            alert.success(`Gym "${response?.data?.data?.name}" request is rejected successfully`)
            props.cancel()
            props.loadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={`Reject "${props.invokeReject.requestName}"`}
                message={`Are you sure you want to permanently reject this gym "${props.invokeReject.requestName}" ? Please note you can’t undo this action`}
                confirmLabel="Yes Reject"
                confirm={() => confirmRejectRequest(props.invokeReject.requestId)}
                cancel={props.cancel}
            >
                <Input placeholder="Reject reason" value={rejectReason} onChange={changeReason} />
                {error ? <FieldError message="Reject reason is a required field"/> : ''}
            </DangerousAlert>
        </div>
    )
}

export default RejectRequest