import { useState } from "react"
import ModalHeader from "../../../../../FormShared/ModalHeader"
import Subscription from "./Subscriptions/Index"
import Sessions from "./Sessions/Index"
import Classes from "./Classes/Index"
import CrudIndexDS from "../../../../../Shared/CrudIndexDS"

import './Modal.css'

const Modal = ({cancel ,gym}) => {
    const [showSection ,setShowSection] = useState('subscriptions')

    const [sessionsData ,setSessionsData] = useState({...CrudIndexDS})
    const [classesData ,setClassesData] = useState({...CrudIndexDS})

    const SubscriptionIndex = <Subscription visible={showSection === 'subscriptions'} gymId={gym?.id}/>
    const SessionIndex = <Sessions setData={setSessionsData} data={sessionsData} visible={showSection === 'sessions'} gymId={gym?.id}/>
    const ClassesIndex = <Classes setData={setClassesData} data={classesData} visible={showSection === 'classes'} gymId={gym?.id}/>

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain admin-manage-gym-modal">
                    <ModalHeader title={`Manage ${gym?.name}`} cancel={cancel}/>
                    <div className="crud-in-modal" style={{padding: 0 ,paddingBottom: 15 ,background: 'var(--white-color)' ,borderRadius: '0 0 10px 10px'}}>
                        <div className="section admin-section">
                            <div className="pages-navbar pad0">
                                <button className={showSection === 'subscriptions' ? 'active' : ''}
                                    onClick={() => setShowSection('subscriptions')}>
                                    Subscriptions
                                </button>
                                <button className={showSection === 'classes' ? 'active' : ''}
                                    onClick={() => setShowSection('classes')}>
                                    Classes
                                </button>
                                <button className={showSection === 'sessions' ? 'active' : ''}
                                    onClick={() => setShowSection('sessions')}>
                                    Sessions
                                </button>
                            </div>
                            {SubscriptionIndex}
                            {SessionIndex}
                            {ClassesIndex}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal