import axios from "../../../../Axios"
import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import './change-plan.css'
import DropDown from "../../../FormShared/DropDown";

const ChangePlanModal = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [subscriptions ,setSubscriptions] = useState([])
    const gymId = props.gymId
    const [membershipId ,setMembershipId] = useState(props.gymMembershipId)
    const [disabled ,setDisabled] = useState(false)
    const [membershipType ,setMembershipType] = useState('month')

    const subscriptionUrl = `${Config.apiBaseUrl}`

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${subscriptionUrl}/gym-membership/gym-membership` ,{cancelToken: axiosSource.token})
        .then(response => setSubscriptions(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSubscriptions ,subscriptionUrl ,userToken])

    const changePlan = () => {
        const data = {gym_membership_id: membershipId ,gym_membership_type: membershipType}
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${subscriptionUrl}/gym-membership/gym/gyms/renewal/${gymId}` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`Gym "${response?.data?.data?.name}" plan changed successfully`)
            props.cancel()
            props.reload()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <div className="header">
                        <div className="title">
                            <h4 className="heading"> Change Plan </h4>
                        </div>
                        <div className="header-icon" onClick={props.cancel}>
                            <img alt="Gymme" src="/assets/svg/times-solid-form.svg"/>
                        </div>
                    </div>
                    <form>
                        <div className="section" style={{marginBottom: 10}}>
                            {
                                subscriptions.map(subscription => {
                                    return (
                                        <div key={subscription.id} onClick={() => setMembershipId(subscription.id)}
                                            className={`subscription-card${subscription.id === membershipId ? '-active' : ''}`}>
                                            <div className={`subscription-details${subscription.id === membershipId ? '-active' : ''}`}>
                                                <img
                                                    src={subscription.icon}
                                                    style={{ width: "70px", height: "45px" }}
                                                    alt={subscription.name_en} />
                                                <p className="title"> { subscription.name_en } </p>
                                                {
                                                    subscription?.act_as_free === 1 ?
                                                        <p className="price"> Free </p>
                                                        :
                                                        <>
                                                            <p className="price"> {subscription.price} EPG/<span>Mo</span> </p>
                                                            <p className="price"> {subscription.yearly_price} EPG/<span>Year</span> </p>
                                                        </>
                                                }
                                            </div>
                                            <div className="subscription-features">
                                                <ul>
                                                {
                                                    subscription?.features?.map(feature => {
                                                        return (
                                                            <li>
                                                                <img className="feature-logo"
                                                                    src="/assets/svg/logo-icon.svg" alt={feature.name_en}/>
                                                                { feature.name_en }
                                                            </li>
                                                        )
                                                    })
                                                }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                            <div className="row">
                                <div className="pad0 col-md-6">
                                    <h3> Duration: </h3>
                                </div>
                                <div className="pad0 col-md-6">
                                    <DropDown
                                        styles={{margin: 0}}
                                        selectedName={membershipType}
                                        list={[ {name: "month" ,value: 'month'}, {name: "year" ,value: 'year'} ]}
                                        itemName="name"
                                        select={e => {
                                            setMembershipType(e.value)
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="confirm">
                            <button type="button" className="btn btn-default" onClick={props.cancel}>Cancel</button> 
                            <button disabled={disabled} type="button" className="btn btn-light" onClick={changePlan}>
                                Save changes
                            </button>
                        </div>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangePlanModal