import { Switch ,Route } from 'react-router-dom'
import Dashboard from '../dashboard/Dashboard'
import Earnings from '../dashboard/MainSection/Earning/Earnings'
import History from '../dashboard/MainSection/History/History'
import Profile from '../dashboard/Profile/Index'
import FoodComponent from '../dashboard/Bar/Foods/FoodData'
import SupplementsComponent from '../dashboard/Bar/Supplements/Supplements'
import DrinksComponent from '../dashboard/Bar/Drinks/Drinks'
import UnitsAndSizesComponent from '../dashboard/Bar/UnitsAndSizes/UnitsSizes'
import AccountRoles from '../dashboard/GymsSection/AccountRoles/AccountRoles'
import Accounts from '../dashboard/MoreSection/Accounts/Accounts'
import Expenses from '../dashboard/GymsSection/Expenses/Expenses'
import GymRequests from '../dashboard/GymmeSection/Requests/GymRequests'
import Gyms from '../dashboard/GymmeSection/Gyms/Index'
import Gym from '../dashboard/GymmeSection/GymDetails/Index'
import Subscriptions from '../dashboard/GymmeSection/Subscriptions/Index'
import HelpCenter from '../dashboard/MoreSection/HelpCenter/Index'
import ShowMessage from '../dashboard/MoreSection/HelpCenter/ShowMessage'
import Users from '../dashboard/GymsSection/Users/Index'
import Notifications from '../dashboard/GymsSection/Notifications/Index'
import BannerAds from '../dashboard/GymmeSection/BannerAds'

const PortalContent = (props) => {
    return (
        <Switch>
            {/* Main Section */}
            <Route path="/" exact>
                <Dashboard />
            </Route>
            <Route path="/dashboard" exact>
                <Dashboard />
            </Route>
            <Route path="/history" exact>
                <History />
            </Route>
            <Route path="/earnings" exact>
                <Earnings />
            </Route>
            {/* Main Section */}

            {/* Gymme Section */}
            <Route path="/gyms" exact>
                <Gyms />
            </Route>
            <Route path="/gyms/:id" exact>
                <Gym />
            </Route>
            <Route path="/requests" exact>
                <GymRequests />
            </Route>
            <Route path="/subscriptions" exact>
                <Subscriptions />
            </Route>
            <Route path="/banner-ads" exact>
                <BannerAds />
            </Route>
            {/* Gymme Section */}

            {/* Gyms Section */}
            <Route path="/users" exact>
                <Users />
            </Route>
            <Route path="/notifications" exact>
                <Notifications />
            </Route>
            <Route path="/expenses" exact>
                <Expenses />
            </Route>
            <Route path="/account-roles" exact>
                <AccountRoles />
            </Route>
            {/* Gyms Section */}

            {/* Bar Section */}
            <Route path="/food" exact>
                <FoodComponent />
            </Route>
            <Route path="/drinks" exact>
                <DrinksComponent />
            </Route>
            <Route path="/supplements" exact>
                <SupplementsComponent />
            </Route>
            <Route path="/units-sizes" exact>
                <UnitsAndSizesComponent />
            </Route>
            {/* Bar Section */}

            {/* More Section */}
            <Route path="/accounts" exact>
                <Accounts />
            </Route>
            <Route path="/help-center" exact>
                <HelpCenter />
            </Route>
            <Route path="/help-center/:id" exact>
                <ShowMessage />
            </Route>
            {/* More Section */}

            <Route path="/profile" exact>
                <Profile />
            </Route>
        </Switch>
    )
}

export default PortalContent