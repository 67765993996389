import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import AppContext from "../../../../../../partials/AppContext"
import Pagination from "../../../../../../Shared/Pagination"
import PrintButton from "../../../../../../Shared/PrintButton"
import Table from "../../../../../../Shared/Table"
import Thead from "../../../../../../Shared/Thead"
import SearchBox from '../../../../../../Shared/SearchBox'
import Add from "./Add"
import Edit from "./Edit"
import Delete from "./Delete"
import Row from "./Row"

const Index = ({setData ,data ,visible ,gymId}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/sessions/${gymId}`
    const dataList = data?.list,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        meta = data?.meta,
        headerTitle = data?.headerTitle,
        keyword = data?.keyword

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const [addClick ,setAddClick] = useState(undefined)
    const [editClick ,setEditClick] = useState(undefined)
    const [deleteClick ,setDeleteClick] = useState(undefined)

    const cancel = () => {
        setAddClick(undefined)
        setEditClick(undefined)
        setDeleteClick(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const responseData = response?.data?.data ,meta = response?.data?.meta
            setData({...data,
                headerTitle: `${responseData?.length} ${responseData?.length <= 1 ? 'Session' : 'Sessions'}`,
                list: responseData,
                meta: meta
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }
    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[userToken ,alert ,dataPerPage ,currentPage ,clearUserData])

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    const triggerSearch = () => {
        if (currentPage !== 1) setData({...data, currentPage: 1})
        else loadData()
    }

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setData({...data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (data.length === 1 && currentPage > 1) setData({...data, currentPage: currentPage - 1})
        else loadData()
    }

    return (
        <div style={{display: visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox
                        searchTrigger={triggerSearch}
                        setKeyword={value => setData({...data, keyword: value})}
                        keyword={keyword}
                    />
                    <button className="btn btn-light" onClick={() => {
                        cancel()
                        setAddClick(true)
                    }}> Add Session </button>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={[
                            {value: 'Name' ,colSpan: 3 ,width: '60%'},
                            {value: 'Price' ,width: '20%'},
                            {value: 'Actions' ,className: 'actions' ,width: '20%'}
                        ]}/>
                        <tbody>
                            { dataList?.map((row,index) => <Row key={index} row={row} cancel={cancel} edit={setEditClick} delete={setDeleteClick} />) }
                        </tbody>
                    </Table>
                    <Pagination
                        selectStyle={{width: '100%', maxWidth: '100%' ,minWidth: '100%'}}
                        meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => setData({...data, currentPage: page})}
                        changeDataPerPage={(n) => setData({...data, dataPerPage: n})}/>
                </div>
            </div>
            {addClick ? <Add cancel={cancel} gymId={gymId} reload={reloadForAdd} /> : ''}
            {
                editClick ?
                <Edit
                    _title={editClick?.title}
                    _price={editClick?.price}
                    id={editClick?.id}
                    gymId={gymId}
                    cancel={cancel}
                    reload={reloadForAdd}
                />
                : ''
            }
            {
                deleteClick ?
                <Delete
                    id={deleteClick?.id}
                    name={deleteClick?.name}
                    cancel={cancel}
                    reload={reloadForDelete}
                    gymId={gymId}
                />
                : ''
            }
        </div>
    )
}

export default Index