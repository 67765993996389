import { useAlert } from 'react-alert'
import { useContext, useEffect, useState } from 'react'
import axios from "../../../../Axios"
import Config from '../../../../Config'
import '../../../FormShared/form.css'
import ModalHeader from '../../../FormShared/ModalHeader'
import ModalButtons from '../../../FormShared/ModalButtons'
import AccountCommonInputs from './AccountCommonInputs'
import PhotoInput from './PhotoInput'
import AppContext from '../../../partials/AppContext'

const EditAccount = (props) => {
    const alert = useAlert()
    const [account ,setAccount] = useState({id: '' ,username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,photo: {} ,role: {id: '' ,name: ''} ,defaultImg : ''})
    const [errors ,setErrors] = useState({username: '' ,email: '' ,name: '' ,phone: '' ,password: '' ,role: '' ,photo: ''})
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    useEffect(() => {
        const axiosSource = axios.CancelToken.source()
        axios
        .setAuthToken(userToken)
        .get(`${Config.apiBaseUrl}/admin-accounts/admins/${props.accountId}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const _acc = response.data.data
            const _account = {
                id: _acc?.id,
                username: _acc?.username,
                email: _acc?.email,
                name: _acc?.name,
                phone: _acc?.phone,
                role: {id: '', name_en: ''}
            }
            try {
                _account.role.id = _acc.roles[0].id
                _account.role.name = _acc.roles[0].name_en
            } catch (err) {}
            _account.defaultImg = _acc?.photo
            setAccount(_account)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setAccount ,alert ,props])

    const updateAccountAttr = (value ,attr) => {
        const _account = {...account}
        _account[attr] = value
        setAccount(_account)
        const _errors = errors
        _errors[attr] = ''
        setErrors(_errors)
    }

    const addRole = (id ,name) => {
        setAccount({...account ,role: {id ,name}})
    }

    const updateAccount = () => {
        const formData = new FormData()
        Object.keys(account).forEach(attr => formData.append(attr ,account[attr]))
        formData.set('roles[0]' ,account.role.id)
        if (account.photo)
            formData.set('photo' ,account.photo)
        formData.set('_method' ,'put')
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${Config.apiBaseUrl}/admin-accounts/admins/${props.accountId}` ,formData)
        .then(response => {
            setDisabled(false)
            alert.success(`Account : ${response?.data?.data?.name} ,is updated successfully`)
            props.reloadData()
            props.cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errors = {...errors ,...validation}
                Object.keys(validation).forEach(_error => {
                    if (_error.includes('roles')) {
                        _errors.role = validation[_error]
                    }
                })
                setErrors(_errors)
            } else if (error?.response?.data?.message) alert.error(error.response.data.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Edit Account" cancel={props.cancel}/>
                    <form>
                        <PhotoInput updatePhoto={(img) => updateAccountAttr(img ,'photo')} defaultImg={account.defaultImg} error={errors.photo} />
                        <div className="section">
                            <AccountCommonInputs addRole={addRole} account={account} updateAccountAttr={updateAccountAttr} errors={errors} />
                        </div>
                        <ModalButtons disabled={disabled} cancel={props.cancel} confirm={updateAccount} confirmLabel="Update Account" />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default EditAccount