import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import Pagination from "../../../../Shared/Pagination"
import PrintButton from "../../../../Shared/PrintButton"
import SearchBox from "../../../../Shared/SearchBox"
import Table from "../../../../Shared/Table"
import Thead from "../../../../Shared/Thead"
import Accept from "./Accept"
import Reject from "./Reject"

const Index = ({visible ,data ,setData}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/member-request-gym`

    const [acceptGym ,setAcceptGym] = useState(undefined)
    const [rejectGym ,setRejectGym] = useState(undefined)

    const dataList = data?.list,
        keyword = data?.keyword,
        dataPerPage = data?.dataPerPage,
        currentPage = data?.currentPage,
        headerTitle = data?.headerTitle,
        meta = data?.meta

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()
    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        let urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}`
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const gymsCount = response?.data?.data?.length,
                gyms = response?.data?.data,
                headerTitle = `${gymsCount} Gym${gymsCount > 1 ? 's' : ''}`,
                meta = response?.data?.meta
            setData({
                ...data ,
                list: gyms,
                meta: meta,
                headerTitle: headerTitle
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setData ,clearUserData ,dataPerPage ,currentPage ,userToken ,entityUrl])

    const cancelModal = () => {
        setAcceptGym(undefined)
        setRejectGym(undefined)
    }

    const searchTrigger = () => {
        if (currentPage !== 1) setData({...data ,currentPage: 1})
        else loadData()
    }

    const headers = [
        {value: 'Gym`s Name'} ,{value: 'Request Date'},
        {value: 'Phone'} ,{value: 'Address'},
        {value: 'Requested By'} ,{value: 'Actions' ,className: 'actions'}
    ]

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    const reloadForAccept = () => {
        if (currentPage !== 1) setData({...data ,currentPage: 1})
        else loadData()
    }

    const reloadForReject = () => {
        if (dataList === 1 && currentPage > 1) setData({...data ,currentPage: currentPage - 1})
        else loadData()
    }

    return (
        <div style={{display: visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SearchBox searchTrigger={searchTrigger} setKeyword={v => setData({...data ,keyword: v})} keyword={keyword}/>
                </div>
            </div>
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={headers}/>
                        <tbody>
                            {dataList?.map?.((row ,index) => <tr key={index}>
                                <td> { row?.name } </td>
                                <td> { row?.date } </td>
                                <td> { row?.phone } </td>
                                <td> { row?.address } </td>
                                <td> { row?.member } </td>
                                <td>
                                    <button className="status-action-btn success"
                                        onClick={() => {
                                            cancelModal()
                                            setAcceptGym({id: row?.id ,name: row?.name})
                                        }}>
                                        <i className="fas fa-check"></i>
                                    </button>
                                    <button className="status-action-btn danger"
                                        onClick={() => {
                                            cancelModal()
                                            setRejectGym({id: row?.id ,name: row?.name})
                                        }}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </td>
                            </tr>)}
                        </tbody>
                    </Table>
                    <Pagination
                        changeCurrentPage={v => setData({...data ,currentPage: v})} currentPage={currentPage}
                        dataPerPage={dataPerPage} changeDataPerPage={v => setData({...data ,dataPerPage: v})} meta={meta}/>
                </div>
            </div>
            {acceptGym ? <Accept cancel={cancelModal} id={acceptGym?.id} name={acceptGym?.name} reload={reloadForAccept}/> : ''}
            {rejectGym ? <Reject cancel={cancelModal} id={rejectGym?.id} name={rejectGym?.name} reload={reloadForReject}/> : ''}
        </div>
    )
}

export default Index