import { Fragment, useContext, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import DangerousAlert from "../../../Shared/DangerousAlert"
import SuccessAlert from "../../../Shared/SuccessAlert"

const MessageActionConfirm = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)
    const message = props?.message,
        baseUrl = props?.baseUrl,
        id = props?.id,
        actionMessage = props?.actionMessage

    const changeMessageStatus = (status) => {
        if (status !== 1 && status !== 2) status = 1
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${baseUrl}/${id}` ,{_method: 'PUT' ,status})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.title} marked as ${status === 1 ? 'solved' : 'spam'}`)
            props?.loadData()
            props?.setActionMessage(undefined)
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <Fragment>
            {
                actionMessage && actionMessage.updateStatus === 1 ?
                    <div id="forms">
                        <SuccessAlert
                            disabled={disabled}
                            heading="Solve Message"
                            message={`Are you sure ,you want to make this message "${message.title}" ,marked as solved ?`}
                            confirmLabel="Its Solved"
                            confirm={() => changeMessageStatus(1)}
                            cancel={() => props?.setActionMessage(undefined)} />
                    </div>
                : ''
            }
            {
                actionMessage && actionMessage.updateStatus === 2 ?
                    <div id="forms">
                        <DangerousAlert
                            disabled={disabled}
                            heading="Spam Message"
                            message={`Are you sure ,you want to make this message "${message.title}" ,marked as spam ?`}
                            confirmLabel="Its Spam"
                            confirm={() => changeMessageStatus(2)}
                            cancel={() => props?.setActionMessage(undefined)} />
                    </div>
                : ''
            }
        </Fragment>
    )
}

export default MessageActionConfirm