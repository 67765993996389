import {BrowserRouter as Router ,Switch ,Route} from 'react-router-dom'
import Forget from './Forget'
import Login from './Login'
import Reset from './Reset'

const AuthTemplate = (props) => {
    return (
        <Router>
            <Switch>
                <Route path="/reset-password/:token" exact>
                    <Reset/>
                </Route>
                <Route path="/forget-password" exact>
                    <Forget/>
                </Route>
                <Route>
                    <Login/>
                </Route>
            </Switch>
        </Router>
    )
}

export default AuthTemplate