import { useContext, useEffect, useState } from 'react'
import Navbar from '../../../partials/Navbar'
import axios from "../../../../Axios"
import Config from '../../../../Config'
import SearchBox from '../../../Shared/SearchBox'
import PrintButton from '../../../Shared/PrintButton'
import RoleRow from './RoleRow'
import EditRole from './EditRole'
import DeleteRole from './DeleteRole'
import AddRole from './AddRole'
import { useAlert } from 'react-alert'
import AppContext from '../../../partials/AppContext'
import Thead from '../../../Shared/Thead'
import Table from '../../../Shared/Table'

const AccountRoles = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/admin-accounts/roles`
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    let [items ,setItems] = useState([])
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeAdd ,setInvokeAdd] = useState(undefined)

    const editItem = (itemId) => {
        setInvokeDelete(undefined)
        setInvokeEdit(itemId)
    }

    const deleteItem = (itemId ,itemName) => {
        setInvokeDelete({itemId ,itemName})
        setInvokeEdit(undefined)
    }

    const addItem = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(true)
    }

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(undefined)
    }

    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}?limit=100${keyword ? '&keyword=' + keyword : ''}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const rolesCount = response.data.data.length
            setItems(response.data.data)
            setHeaderTitle(`${rolesCount} Account Role${rolesCount > 1 ? 's' : ''}`)
        })
        .catch(err => {
            const error = {...err} ,response = error.response.data
            if (response.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setItems ,setHeaderTitle ,entityUrl ,alert])

    const searchTrigger = () => loadData()

    const headers = [{value: 'Role'} ,{value: 'Arabic Role'} ,{value: 'Added Date'} ,{value: 'Actions', className: 'actions'}]

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}` ,true ,userToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar   navbarTitle="Account Roles"/>
            </div>
            <div className="page-content">
                <div className="page-section header-box">
                    <div className="row header">
                        <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <button onClick={addItem} className="btn btn-light">Add Role</button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                { items.map(item => <RoleRow key={item.id} item={item} editItem={editItem} deleteItem={deleteItem}/>) }
                            </tbody>
                        </Table>
                    </div>
                </div>
                {invokeAdd ? <AddRole cancel={cancelModal} reloadData={loadData} /> : ''}
                {invokeEdit ? <EditRole itemId={invokeEdit} cancel={cancelModal} reloadData={loadData} /> : ''}
                {invokeDelete ?
                    <DeleteRole itemId={invokeDelete.itemId} cancel={cancelModal} reloadData={loadData}
                    itemName={invokeDelete.itemName} /> : ''
                }
            </div>
        </div>
    )
}

export default AccountRoles