import { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import AppContext from './AppContext'

const Aside = (props) => {
    const {toggleAsideMenu ,closeMenu} = useContext(AppContext)
    return (
        <div className="side-panel en" id="aside-menu">
            <button type="button" id="close-aside-menu" onClick={() => toggleAsideMenu()}>
                <i className="fas fa-times"></i>
            </button>
            <div className="logo">
                <img alt="logo" className="full-img" src="/assets/svg/logo.svg"/>
            </div>
            <section>
                <span className="title">Main</span>
                <NavLink onClick={closeMenu} className="section-link" to="/dashboard" exact activeClassName="active">
                    <i className="fab fa-cloudscale"></i>
                    <span>Dashboard</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/earnings" exact activeClassName="active">
                    <i className="fas fa-coins"></i>
                    <span>Earnings</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/history" exact activeClassName="active">
                    <i className="fas fa-history"></i>
                    <span>History</span>
                </NavLink>
            </section>
            <section>
                <span className="title">Gymme</span>
                <NavLink onClick={closeMenu} className="section-link" to="/gyms" exact activeClassName="active">
                    <i className="fas fa-dumbbell"></i>
                    <span>Gyms</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/requests" exact activeClassName="active">
                    <i className="fas fa-user-plus"></i>
                    <span>Requests</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/subscriptions" exact activeClassName="active">
                    <i className="fas fa-layer-group"></i>
                    <span>Subscriptions</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/banner-ads" exact activeClassName="active">
                    <i className="fa fa-adjust"></i>
                    <span>Banner Ads</span>
                </NavLink>
            </section>
            <section>
                <span className="title">Gyms</span>
                <NavLink onClick={closeMenu} className="section-link" to="/users" exact activeClassName="active">
                    <i className="fas fa-address-book"></i>
                    <span>Users</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/notifications" exact activeClassName="active">
                    <i className="fas fa-exclamation-circle"></i>
                    <span>Notifications</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/expenses" exact activeClassName="active">
                    <i className="fas fa-wallet"></i>
                    <span>Expenses</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/account-roles" exact activeClassName="active">
                    <i className="fas fa-eye"></i>
                    <span>Account Roles</span>
                </NavLink>
            </section>
            <section>
                <span className="title">Bar</span>
                <NavLink onClick={closeMenu} className="section-link" to="/food" exact activeClassName="active">
                    <i className="fas fa-hamburger"></i>
                    <span>Food</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/drinks" exact activeClassName="active">
                    <i className="fas fa-coffee"></i>
                    <span>Drinks</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/supplements" exact activeClassName="active">
                    <i className="fas fa-capsules"></i>
                    <span>Supplements</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/units-sizes" exact activeClassName="active">
                    <i className="fas fa-weight-hanging"></i>
                    <span>Units & Sizes</span>
                </NavLink>
            </section>
            <section>
                <span className="title">More</span>
                <NavLink onClick={closeMenu} className="section-link" to="/accounts" exact activeClassName="active">
                    <i className="fas fa-user-circle"></i>
                    <span>Accounts</span>
                </NavLink>
                <NavLink onClick={closeMenu} className="section-link" to="/help-center" exact activeClassName="active">
                    <i className="fas fa-question-circle"></i>
                    <span>Help Center</span>
                </NavLink>
            </section>
        </div>
    )
}

export default Aside