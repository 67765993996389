import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import AppContext from "../../../../../../partials/AppContext"
import DangerousAlert from "../../../../../../Shared/DangerousAlert"

const Delete = ({cancel ,reload ,subscription ,gymId}) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/subscription`
    const [disabled ,setDisabled] = useState(false)

    const deleteSubscription = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${gymId}/delete/${subscription.id}` ,{_method: 'DELETE'})
        .then(response => {
            setDisabled(false)
            alert.success(`Subscription ${response?.data?.data?.title} is deleted successfully`)
            reload()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                disabled={disabled}
                heading={`Delete "${subscription?.name}"`}
                message={`Are you sure you want to permanently delete this subscription "${subscription?.name}" ? Please note you can’t undo this action`}
                confirmLabel="Delete"
                confirm={deleteSubscription}
                cancel={cancel}
            />
        </div>
    )
}

export default Delete