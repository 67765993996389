import DataLine from "./DataLine"

const AdminData = ({admin ,openChangeModal ,openPhotoModal}) => {
    return (
        <div className="page-content">
            <div className="page-section content profile-card-content admin-profile-card">
                <div className="details-page-content">
                    <div className="details-page-section admin-profile">
                        <div className="details-img-container">
                            <div className="details-img">
                                <img alt={admin?.name} style={{width: '100%'}} id="gym-logo-img" src={admin?.photo} className="details-full-img"/>
                            </div>
                            <button id="cam-btn" className="details-cam-btn en">
                                <img alt={admin?.name} src="/assets/svg/img-change.svg" onClick={openPhotoModal}/>
                            </button>
                        </div>
                        
                        <div className="details-gym-info">
                            <div className="details-info-section">
                                <span className="details-info-title">main information</span>
                                <div className="details-info-data">
                                    <DataLine title="Name" value={admin.name} changeClick={() => openChangeModal('name')} />
                                    <DataLine title="Phone Number" value={admin.phone} changeClick={() => openChangeModal('phone')} />
                                    <DataLine title="Email" value={admin.email} changeClick={() => openChangeModal('email')} />
                                </div>
                            </div>
                            <div className="details-info-section">
                                <span className="details-info-title">Security</span>
                                <div className="details-info-data">
                                    <div className="details-data-line">
                                        <span className="details-data-line-title" style={{color: '#191919'}}>Password:********</span>
                                        <button className="details-data-line-control" onClick={() => openChangeModal('password')}>Change Password</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminData