const DataRow = (props) => {
    const data = props?.data
    return (
        <tr>
            <td> {data?.name_en} </td>
            <td> {data?.name_ar} </td>
            {
                data.category ? <td> {data?.category?.name_en} </td> : ''
            }
            <td> {data?.created} </td>
            <td className="actions">
                <button onClick={() => props.edit(data?.id)}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => props.delete(data?.id ,data?.name_en)}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default DataRow