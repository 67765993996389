import axios from "../../../../Axios"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Navbar from "../../../partials/Navbar"
import '../Requests/gym-details.css'
import '../../../FormShared/form.css'
import { useAlert } from "react-alert"
import { NavLink } from 'react-router-dom'
import DataLine from "./DataLine"
import ChangeModal from "./ChangeModal"
import ChangePlanModal from './ChangePlanModal'
import ChangePhoto from "./ChangePhoto"


const GymDetails = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const params = useParams()
    const [gym ,setGym] = useState({})
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/gym`
    const gymId = params.id
    const [isModalOpen ,setIsModalOpen] = useState(false)
    const [editedField ,setEditedField] = useState('')
    const [editedValue ,setEditedValue] = useState('')
    const [modalHeader ,setModalHeader] = useState('')
    const [error ,setError] = useState('')
    const [isChangePlanOpen ,setIsChangePlanOpen] = useState(false)
    const [photo ,setPhoto] = useState({})
    const [isPhotoModal ,setIsPhotoModal] = useState(false)
    const [disabled ,setDisabled] = useState(false)
    const [photoDisabled ,setPhotoDisabled] = useState(false)
    const axiosSource = axios.CancelToken.source()
    const loadGym = () => {
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${gymId}` ,{cancelToken: axiosSource.token})
        .then(response => setGym(response?.data?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadGym()
        return () => axiosSource.cancel()
    },[setGym ,clearUserData ,userToken ,entityUrl ,gymId])

    const openChangeModal = (fieldName) => {
        cancelModal()
        setIsModalOpen(true)
        setEditedField(fieldName)
        setEditedValue(gym[fieldName])
        setModalHeader(fieldName.split('_').join(' '))
        setError('')
    }

    const updateGym = () => {
        if (!editedValue) {
            setError('Field can`t be empty')
            return
        }
        const data = {}
        data[editedField] = editedValue
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .put(`${entityUrl}/gyms/${gymId}` ,data)
        .then(response => {
            setDisabled(false)
            alert.success(`Gym ${response?.data?.data?.name} updated successfully`)
            cancelModal()
            loadGym()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors[editedField]) {
                    setError(error?.response?.data?.errors[editedField].join(" ,"))
                }
            }
        })
    }

    const changeGymPlan = () => {
        cancelModal()
        setIsChangePlanOpen(true)
    }

    const cancelModal = () => {
        setIsModalOpen(false)
        setIsChangePlanOpen(false)
        setIsPhotoModal(false)
    }

    const updatePhoto = () => {
        if (!photo) {
            alert.error('Select Logo First')
            return
        }
        const formData = new FormData()
        formData.set('logo' ,photo)
        formData.set('_method' ,'PUT')
        setPhotoDisabled(true)
        axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/gyms/${gymId}` ,formData)
        .then(response => {
            setPhotoDisabled(false)
            alert.success(`Gym ${response?.data?.data?.name} updated successfully`)
            cancelModal()
            loadGym()
        })
        .catch(err => {
            setPhotoDisabled(false)
            const error = {...err}
            if (error?.response?.status === 401) clearUserData()
            if (error?.response?.data?.errors) {
                if (error?.response?.data?.errors?.logo) {
                    alert.error(error?.response?.data?.errors?.logo.join(" ,"))
                }
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
        })
    }

    const navbarTitle = <>
        <NavLink to="/gyms" exact> <i className="fas fa-arrow-left" style={{color: 'black'}}></i> </NavLink> {gym?.name}
    </>

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={navbarTitle} />
            </div>
            <div className="page-content">
                <div className="page-section content profile-card-content">
                    <div className="details-page-content">
                        <div className="details-page-section">
                            <div className="details-img-container">
                                <div className="details-img">
                                    <img alt={gym?.name} id="gym-logo-img" src={gym?.logo} className="details-full-img"/>
                                </div>
                                <button id="cam-btn" className="details-cam-btn en">
                                    <img alt={gym?.name} src="/assets/svg/img-change.svg" onClick={() => {cancelModal(); setIsPhotoModal(true)}}/>
                                </button>
                            </div>
                            <div className="details-gym-info">
                                <div className="details-info-section">
                                    <span className="details-info-title">main information</span>
                                    <div className="details-info-data">
                                        <DataLine title="Name" value={gym.name} changeClick={() => openChangeModal('name')} />
                                        <DataLine title="Phone Number" value={gym.phone} changeClick={() => openChangeModal('phone')} />
                                        <DataLine title="Email" value={gym.email} changeClick={() => openChangeModal('email')} />
                                        <DataLine title="Website" value={gym.website} changeClick={() => openChangeModal('website')} />
                                    </div>
                                </div>
                                <div className="details-info-section">
                                    <span className="details-info-title">plan details</span>
                                    <div className="details-info-data">
                                        <div className="details-data-line">
                                            <span className="details-data-line-title"> { gym?.subscription_name } </span>
                                            <span className="details-data-line-data"> { gym?.subscription_price } </span>
                                            <button className="details-data-line-control" onClick={() => changeGymPlan()}>Change</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="details-info-section">
                                    <span className="details-info-title">Social media</span>
                                    <div className="details-info-data">
                                        <DataLine title="Facebook" value={gym.facebook_url} changeClick={() => openChangeModal('facebook_url')} />
                                        <DataLine title="Twitter" value={gym.twitter_url} changeClick={() => openChangeModal('twitter_url')} />
                                        <DataLine title="Instagram" value={gym.instagram_url} changeClick={() => openChangeModal('instagram_url')} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isModalOpen ? 
                <ChangeModal
                    disabled={disabled}
                    modalHeader={modalHeader}
                    value={editedValue}
                    changeValue={(e) => setEditedValue(e.target.value)}
                    error={error}
                    cancel={() => setIsModalOpen(false)}
                    confirm={updateGym}/>
                : ''
            }
            {
                isChangePlanOpen ? 
                <ChangePlanModal 
                    gymId={gymId}
                    gymMembershipId={gym.subscription_id}
                    reload={loadGym}
                    cancel={() => setIsChangePlanOpen(false)}/>
                : ''
            }
            {
                isPhotoModal ?
                    <ChangePhoto
                        disabled={photoDisabled}
                        changePhoto={setPhoto}
                        cancel={cancelModal}
                        error=""
                        confirm={updatePhoto}
                    /> : ''
            }
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default GymDetails