import { useContext, useEffect, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../../../Axios"
import Config from "../../../../../../../Config"
import ModalButtons from "../../../../../../FormShared/ModalButtons"
import ModalHeader from "../../../../../../FormShared/ModalHeader"
import AppContext from "../../../../../../partials/AppContext"
import SubscriptionInputs from "./SubscriptionInputs"

const Add = ({cancel ,reload ,gymId}) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym/subscription`

    const [subscriptionData ,setSubscriptionData] = useState({
        title: '' ,title_ar: '' ,number_of_days: '' ,number_of_invitations: '' ,freezing_days: '' ,price: '' ,
        sessions: [
            // {session_id: '' ,quantity: '' ,real_name: 'Session'}
        ]
    })
    const [errors ,setErrors] = useState({
        title: '' ,title_ar: '' ,number_of_days: '' ,number_of_invitations: '' ,freezing_days: '' ,price: '' ,
        sessions: ''
    })
    const [disabled ,setDisabled] = useState(false)

    const [sessions ,setSessions] = useState([])
    useEffect(() => {
        const axiosSource = Axios.CancelToken.source()
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}/${gymId}/getSessions` ,{cancelToken: axiosSource.token})
        .then(response => setSessions(response?.data))
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
        return () => axiosSource.cancel()
    } ,[setSessions ,clearUserData ,alert ,userToken ,entityUrl])

    const saveChanges = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${gymId}/create` ,{...subscriptionData})
        .then(response => {
            setDisabled(false)
            alert.success(`${response?.data?.data?.title} added successfully`)
            reload()
            cancel()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err} ,validation = error?.response?.data?.errors
            if (validation) {
                const _errors = {...errors}
                Object.entries(validation).forEach(error => {
                    const key = error[0] ,value = error[1]
                    if (key.includes('sessions')) _errors.sessions = value
                    else _errors[key] = value
                })
                setErrors(_errors)
            } else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <div id="add-form" className="form-container">
                <div className="contain">
                    <ModalHeader title="Add Subscription" cancel={cancel}/>
                    <form>
                        <SubscriptionInputs
                            subscriptionData={subscriptionData}
                            setSubscriptionData={setSubscriptionData}
                            errors={errors}
                            sessions={[
                                ...sessions.filter(
                                    eachSession => !subscriptionData.sessions.some(subscEachSession => subscEachSession.session_id === eachSession.id)
                                )
                            ]}
                        />
                        <div className="col-md-12">
                            <ModalButtons disabled={disabled} cancel={cancel} confirm={saveChanges} confirmLabel="Add Subscription" />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Add