import './gym-details.css'

const GymDetails = (props) => {
    const request = props.request

    return (
        <div className="details-page-content">
            <div className="details-page-section">
                <div className="details-img-container">
                    <div className="details-img">
                        <img alt={request?.name} id="gym-logo-img" src={request?.logo} className="details-full-img"/>
                    </div>
                </div>
                <div className="details-gym-info">
                    <div className="details-info-section">
                        <span className="details-info-title">main information</span>
                        <div className="details-info-data">
                            <div className="details-data-line">
                                <span className="details-data-line-title">Name :</span>
                                <span className="details-data-line-data"> { request?.name } </span>
                            </div>
                            <div className="details-data-line">
                                <span className="details-data-line-title">Phone Number :</span>
                                <span className="details-data-line-data"> { request?.phone } </span>
                            </div>
                            <div className="details-data-line">
                                <span className="details-data-line-title">Website :</span>
                                <span className="details-data-line-data"> { request?.website } </span>
                            </div>
                        </div>
                    </div>
                    <div className="details-info-section">
                        <span className="details-info-title">plan details</span>
                        <div className="details-info-data">
                            <div className="details-data-line">
                                <span className="details-data-line-title"> { request?.subscription_name } </span>
                                <span className="details-data-line-data"> { request?.subscription_price } </span>
                            </div>
                        </div>
                    </div>
                    <div className="details-info-section">
                        <span className="details-info-title">Social media</span>
                        <div className="details-info-data">
                            <div className="details-data-line">
                                <span className="details-data-line-title">Facebook :</span>
                                <span className="details-data-line-data"> { request?.facebook_url } </span>
                                
                            </div>
                            <div className="details-data-line">
                                <span className="details-data-line-title">Twitter :</span>
                                <span className="details-data-line-data"> { request?.twitter_url } </span>
                                
                            </div>
                            <div className="details-data-line">
                                <span className="details-data-line-title">Instagram :</span>
                                <span className="details-data-line-data"> { request?.instagram_url } </span>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GymDetails