import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../../Axios"
import Config from "../../../../../Config"
import AppContext from "../../../../partials/AppContext"
import DangerousAlert from "../../../../Shared/DangerousAlert"

const Delete = ({cancel ,reload ,id ,name}) => {
    const alert = useAlert() ,{clearUserData ,userToken} = useContext(AppContext)
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/free-gym`
    const [disabled ,setDisabled] = useState(false)
    
    const confirm = () => {
        setDisabled(true)
        Axios
        .setAuthToken(userToken)
        .post(`${entityUrl}/${id}` ,{_method: 'DELETE'})
        .then(response => {
            setDisabled(false)
            alert.success(response?.data?.message)
            cancel()
            reload()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                heading={`Delete Free Gym`}
                message={`Are you sure you want to permanently delete this Free Gym "${name}"? Please note you can’t undo this action`}
                cancel={cancel}
                confirm={confirm}
                disabled={disabled}
                confirmLabel="Yes Delete"
            />
        </div>
    )
}

export default Delete