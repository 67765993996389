import { Fragment, useContext, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"
import FieldError from "../../../FormShared/FieldError"

const MessageReply = (props) => {
    const alert = useAlert()
    const {userToken ,clearUserData} = useContext(AppContext)
    const id = props?.id ,baseUrl = props?.baseUrl
    const [reply ,setReply] = useState({message: '' ,attachments: []})
    const [isDisabled ,setIsDisabled] = useState(false)
    const [errors ,setErrors] = useState({message: '' ,attachments: ''})

    const appendAttach = (event) => {
        setErrors({...errors ,attachments: ''})
        if (event.target.files) {
            const files = event.target.files ,attachments = reply.attachments
            Object.keys(files).forEach(index => attachments.push(files[index]))
            setReply({...reply ,attachments: attachments})
        }
    }

    const changeMessage = (e) => {
        setErrors({...errors ,message: ''})
        setReply({...reply ,message: e.target.value})
    }

    const removeImage = (removedIndex) => {
        const attachments = reply?.attachments?.filter((file ,index) => index !== removedIndex)
        setReply({...reply ,attachments: attachments})
    }

    const sendReply = () => {
        setIsDisabled(true)
        const formData = new FormData
        formData.set('message' ,reply.message)
        reply?.attachments?.map((file ,index) => formData.append(`attachments[${index}]` ,file))
        axios
        .setAuthToken(userToken)
        .post(`${baseUrl}/${id}` ,formData)
        .then(response => {
            alert.success(`Reply send successfully`)
            setIsDisabled(false)
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.errors) {
                const errors = {message: '', attachments: ''}
                if (error?.response?.data?.errors?.message) errors.message = error?.response?.data?.errors?.message
                else errors.attachments = `Attachments most be valid image(s)`
                setErrors(errors)
            }
            else if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
            setIsDisabled(false)
        })
    }

    return (
        <Fragment>
            <div className="section">
                <span className="gray-text"> Reply </span>
                <div>
                    <textarea placeholder="Write Reply"
                        className="rounded-box"
                        onChange={changeMessage}
                        value={reply.message}></textarea>
                    {errors.message ? <FieldError message={errors.message}/> : ''}
                </div>
                <div className="clickable" onClick={() => document.getElementById('files').click()}>
                    <i className="fas fa-paperclip"></i>
                    <span className="bold-text"> Attach images </span>
                    <span className="gray-text"> (Optional) </span>
                    <input id="files" onChange={appendAttach} accept=".png, .jpg, .jpeg"
                        type="file" multiple style={{display: 'none'}}/>
                    {errors.attachments ? <FieldError message={errors.attachments}/> : ''}
                </div>
                {
                    reply?.attachments?.map((attach ,index) => 
                        <div className="attachment-line" key={index}>
                            <div>
                                <img alt="Gymme" className="small-image" src={URL.createObjectURL(attach)}/>
                                <span> {attach?.name?.substring(0, 25) + '...'} </span>
                            </div>
                            <span className="red-text clickable" onClick={() => removeImage(index)}>
                                <i className="fas fa-times"></i>
                            </span>
                        </div>
                    )
                }
            </div>
            <div className="section">
                <div>
                    <button type="button" disabled={isDisabled} className="btn btn-light" onClick={sendReply}>
                        Send Reply
                    </button>
                </div>
            </div>
        </Fragment>
    )
}

export default MessageReply