import Input from "../../FormShared/Input"
import FieldError from "../../FormShared/FieldError"

const ChangeModal = (props) => {
    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <div className="header">
                        <div className="title">
                            <h4 className="heading">{`Change ${props.modalHeader}`}</h4>
                        </div>
                        <div className="header-icon" onClick={props.cancel}>
                            <img alt="Gymme" src="/assets/svg/times-solid-form.svg"/>
                        </div>
                    </div>
                    <form>
                        <div className="section" style={{marginBottom: 10, gap: 5, display: 'flex', flexDirection: 'column'}}>
                            {
                                props?.childrenFirst ? props.children : ''
                            }
                            <Input
                                value={props.value}
                                onChange={props.changeValue}
                                placeholder={props.placeholder ? props.placeholder : ''}
                                type={props.type ? props.type : 'text'}/>
                            {props.error ? <FieldError message={props.error} /> : ''}
                            {
                                !props?.childrenFirst ? props.children : ''
                            }
                        </div>
                        <div className="confirm">
                            <button type="button" className="btn btn-default" onClick={props.cancel}>Cancel</button> 
                            <button disabled={props.disabled ? props.disabled : false} type="button" className="btn btn-light" onClick={props.confirm}>
                                Save changes
                            </button>
                        </div>
                    </form>
            
                </div>
            </div>
        </div>
    )
}

export default ChangeModal