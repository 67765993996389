import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import PrintButton from "../../../Shared/PrintButton"
import Pagination from "../../../Shared/Pagination"
import Thead from "../../../Shared/Thead"
import Table from "../../../Shared/Table"
import SerachBox from "../../../Shared/SearchBox"
import AddDrink from "./AddDrink"
import AddBrand from "./AddBrand"
import EditDrink from "./EditDrink"
import EditBrand from "./EditBrand"
import BarDelete from "../BarDelete"
import GymSuggest from "../GymSuggest"
import DataRow from "../DataRow"

const ListData = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const entity = props.entity ,entityUpper = props?.entityUpper

    const baseUrl = `${Config.apiBaseUrl}/bar/drink-${entity}`

    const [invokeAdd ,setAdd] = useState(undefined)
    const [invokeEdit ,setEdit] = useState(undefined)
    const [invokeDelete ,setDelete] = useState(undefined)
    const [gymSuggest ,setGymSuggest] = useState({})

    const cancelModal = () => {
        setAdd(undefined)
        setEdit(undefined)
        setDelete(undefined)
    }

    const fireAdd = () => {
        cancelModal()
        setAdd(true)
    }

    const fireEdit = (id) => {
        cancelModal()
        setEdit(id)
    }

    const fireDelete = (id ,name) => {
        cancelModal()
        setDelete({id ,name})
    }
    
    const data = props?.data?.list,
        dataPerPage = props?.data?.dataPerPage,
        currentPage = props?.data?.currentPage,
        meta = props?.data?.meta,
        headerTitle = props?.data?.headerTitle,
        keyword = props?.data?.keyword

    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        axios
        .setAuthToken(userToken)
        .get(`${baseUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data?.data, meta = response?.data?.meta, gymSuggest = response?.data?.gym_suggest
            props?.setData({...props?.data,
                meta: meta,
                headerTitle: `${data?.length} Drink ${entity}`,
                list: data
            })
            executeScroll()
            if (gymSuggest && currentPage === 1)
                setGymSuggest({
                    category: gymSuggest?.category_name_en,
                    gym: gymSuggest?.gym?.name,
                    id: gymSuggest?.id
                })
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[alert ,userToken ,clearUserData ,currentPage ,dataPerPage])

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const setKeyword = (value) => props?.setData({...props?.data, keyword: value})
    const headers = [
        {value: entityUpper} ,{value: `Arabic ${entityUpper}`} ,{value: 'Added Date'} ,{value: 'Actions' ,className: 'actions'}
    ]

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else props?.setData({...props?.data, currentPage: 1})
    }

    const reloadForDelete = () => {
        if (data.length === 1 && currentPage > 1) props?.setData({...props?.data, currentPage: currentPage - 1})
        else loadData()
    }

    const callGymSuggest = (id ,type) => {
        if (gymSuggest && entity === 'category') {
            const apiUrl = `${baseUrl}/${type === 'add' ? '' : 'skip-'}gym-suggest/${id}`
            axios
            .setAuthToken(userToken)
            .post(apiUrl)
            .then(response => {
                if (type === 'skip') {
                    alert.show(response?.data?.message)
                    setGymSuggest(undefined)
                } else {
                    alert.success(`${response?.data?.data?.name_en} created successfuly`)
                    if (currentPage !== 1) props?.setData({...props?.data, currentPage: 1})
                    else loadData()
                }
            })
            .catch(err => {
                const error = {...err}
                if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
                else alert.error('Call technical support')
                if (error?.response?.status === 401) clearUserData()
            })
        }
    }

    const triggerSearch = () => {
        if (currentPage !== 1) props?.setData({...props?.data, currentPage: 1})
        else loadData()
    }

    const printClick = () => Config.printByToken(`${baseUrl}/${props.printUrl}?keyword=${keyword}` ,true ,userToken)
    
    return (
        <div style={{display: props.visible ? 'block' : 'none'}}>
            <div className="page-section header-box" ref={elRef}>
                <div className="row header">
                    <SerachBox searchTrigger={triggerSearch} setKeyword={setKeyword} keyword={keyword}/>
                    <button className="btn btn-light" onClick={fireAdd}>Add {entityUpper}</button>
                </div>
            </div>
            {gymSuggest && entity === 'category' ? <GymSuggest callGymSuggest={callGymSuggest} gym_suggest={gymSuggest}/> : ''}
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={headers}/>
                        <tbody>
                            { data?.map(row => <DataRow key={row?.id} data={row} edit={fireEdit} delete={fireDelete}/>) }
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => props?.setData({...props?.data, currentPage: page})}
                        changeDataPerPage={(n) => props?.setData({...props?.data, dataPerPage: n})}/>
                </div>
            </div>
            {invokeAdd && entity === 'category' ? <AddDrink loadData={reloadForAdd} cancel={cancelModal}/> : ''}
            {invokeAdd && entity === 'brand' ? <AddBrand loadData={reloadForAdd} cancel={cancelModal}/> : ''}
            {invokeEdit && entity === 'category' ? <EditDrink id={invokeEdit} loadData={loadData} cancel={cancelModal}/> : ''}
            {invokeEdit && entity === 'brand' ? <EditBrand id={invokeEdit} loadData={loadData} cancel={cancelModal}/> : ''}
            {invokeDelete ? <BarDelete data={invokeDelete} entity={entity} url={baseUrl} loadData={reloadForDelete} cancel={cancelModal}/> : ''}
        </div>
    )
}

export default ListData