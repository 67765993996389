import axios from "../../../../Axios"
import { useContext, useEffect, useRef, useState } from 'react'
import { useAlert } from 'react-alert'
import Config from '../../../../Config'
import AppContext from '../../../partials/AppContext'
import Navbar from '../../../partials/Navbar'
import FilterBox from '../FilterBox'
import PrintButton from '../../../Shared/PrintButton'
import Pagination from '../../../Shared/Pagination'
import Thead from "../../../Shared/Thead"
import Row from "./Row"
import Table from "../../../Shared/Table"

const Earnings = (props) => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/earnings-services/earnings-services`

    const [headerTitle ,setHeaderTitle] = useState('')
    const [keyword ,setKeyword] = useState('')
    const [earnings ,setEarnings] = useState([])
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(props.defaultPage ? props.defaultPage : 1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [month ,setMonth] = useState('')
    const [year ,setYear] = useState('')

    const elRef = useRef()
    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        const urlQuery = `?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}&month=${month}&year=${year}`
        axios
        .setAuthToken(userToken)
        .get(`${entityUrl}${urlQuery}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const earningsCount = response?.data?.data?.length,
                earnings = response?.data?.data,
                headerTitle = `${earningsCount} ${earningsCount > 1 ? 'Earnings' : 'Earning'}`,
                meta = response?.data?.meta
            setEarnings(earnings)
            setMeta(meta)
            setHeaderTitle(headerTitle)
            elRef.current.scrollIntoView()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[
        setEarnings ,setMeta ,setHeaderTitle ,clearUserData,
        entityUrl ,userToken ,alert ,dataPerPage ,currentPage ,month ,year
    ])

    const headers = [
        {width: "50%" ,value: 'Gym`s Name'}, {width: "17%" ,value: 'Payment Date'},
        {width: "17%", value: 'Subscription'}, {value: 'Price'}
    ]

    const triggerSearch = () => {
        if (currentPage !== 1) setCurrentPage(1)
        else loadData()
    }

    const printClick = () => Config.printByToken(`${entityUrl}/print?keyword=${keyword}&month=${month}&year=${year}` ,true ,userToken)

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Earnings" />
            </div>
            <div className="page-content">
                <div className="page-section header-box" ref={elRef}>
                    <FilterBox 
                        loadData={triggerSearch}
                        setKeyword={setKeyword}
                        keyword={keyword}
                        changeMonth={setMonth}
                        month={month}
                        changeYear={setYear}
                        year={year}/>
                </div>
                <div className="page-section content" style={{marginTop: 15}}>
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <PrintButton onClick={printClick}/>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                {earnings?.map(earning => <Row key={earning?.id} earning={earning}/>)}
                            </tbody>
                        </Table>
                        <Pagination
                            changeCurrentPage={setCurrentPage} currentPage={currentPage}
                            dataPerPage={dataPerPage} changeDataPerPage={setDataPerPage} meta={meta}/>
                    </div>
                </div>
            </div>
            <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
        </div>
    )
}

export default Earnings