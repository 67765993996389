import './form.css'

const RolePermittedEntities = (props) => {
    // V.I.Hint : this only work with entities like this [{module : 'very important field' ,screen ,view ,add ,edit ,delete}]

    const isPermissionExists = (moduleName ,action) => {
        const filtered = props.itemPermissions ? props.itemPermissions.filter(perm => {
            return perm.module === moduleName && perm[action] !== 'Off'
        }) : []
        return filtered.length > 0
    }

    const injectPermission = (entity ,clickedAction) => {
        if (!props.itemPermissions) return
        const permIndex = props.itemPermissions.findIndex(perm => perm.module === entity.module)
        const permissions = props.itemPermissions
        let permission = {}
        if (permIndex === -1) {
            permission.module = entity.module
            permission.screen = entity.name
            permission.add = clickedAction === 'add' ? 'On' : 'Off'
            permission.delete = clickedAction === 'delete' ? 'On' : 'Off'
            permission.edit = clickedAction === 'edit' ? 'On' : 'Off'
            permission.view = clickedAction === 'view' ? 'On' : 'Off'
            permissions.push(permission)
        } else {
            permission = props.itemPermissions[permIndex]
            permission[clickedAction] = permission[clickedAction] === 'Off' ? 'On' : 'Off'
            permissions[permIndex] = permission
        }
        props.updateItemPermission(permissions)
    }

    return (
        <div className="row">
            {
                props?.entities?.map(entity => {
                    return (
                        <div className="col-sm-4 pad0 box" key={Math.random()}>
                            <div className="input-label">
                                <label htmlFor="branch-adding"> {entity.name} </label>
                            </div>
                            {
                                Object.keys(entity.actions).map(action => {
                                    const permitted = isPermissionExists(entity.module ,action)
                                    return (
                                        <div className="element" key={Math.random()}>
                                            <span>{action.toUpperCase()}</span>
                                            <div
                                                onClick={() => injectPermission(entity ,action)}
                                                className={`toggle ${permitted ? 'active' : ''}`}></div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}

export default RolePermittedEntities