import { NavLink } from "react-router-dom"

const Row = (props) => {
    const data = props?.data, typeIncluded = props?.typeIncluded, sourceIncluded = props?.sourceIncluded

    const attrs = ['title' ,'email' ,'phone' ,'source' ,'type']
    
    const url = `/help-center/${data.id}?screen=${props.screen ? props.screen : ''}&page=${props.page ? props.page : ''}`

    return (
        <tr>
            <td>
                <NavLink exact to={url}>
                    {data.name}
                </NavLink>
            </td>
            {
                attrs.map(attr => {
                    if (attr === 'source' && !sourceIncluded) return;
                    if (attr === 'type' && !typeIncluded) return;
                    return (
                        <td> { data[attr] } </td>
                    )
                })
            }
            {
                props?.changeStatus ?
                <td className="actions">
                    <button onClick={() => props?.invokeChangeStatus(data ,true)}>
                        <i className="fas fa-check-circle"></i>
                    </button>
                    <button onClick={() => props?.invokeChangeStatus(data ,false)}>
                        <i className="fas fa-exclamation-circle"></i>
                    </button>
                </td>
                :
                <td className="actions">
                    <NavLink exact to={url}>
                        <i className="fas fa-envelope"></i>
                    </NavLink>
                </td>
            }
        </tr>
    )
}

export default Row