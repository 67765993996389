import { useState } from "react"
import Navbar from "../../../partials/Navbar"
import CrudIndexDS from "../../../Shared/CrudIndexDS"
import ListData from "./ListData"

const UnitsSizes = (props) => {
    const [showSection ,setShowSection] = useState('unit')

    const [unitIndexData ,setUnitIndexData] = useState({...CrudIndexDS})
    const [sizeIndexData ,setSizeIndexData] = useState({...CrudIndexDS})

    const UnitsIndexComponent = <ListData
        entity='unit'
        entityUpper='Unit'
        entityPlural='units'
        printUrl='print'
        visible={showSection === 'unit'}
        data={unitIndexData}
        setData={setUnitIndexData}
    />,
    SizesIndexComponent = <ListData
        entity='size'
        entityUpper='Size'
        entityPlural='sizes'
        printUrl='print'
        visible={showSection === 'size'}
        data={sizeIndexData}
        setData={setSizeIndexData}
    />
    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Units & Sizes"/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'unit' ? 'active' : ''}
                    onClick={() => setShowSection('unit')}>
                    Units
                </button>
                <button className={showSection === 'size' ? 'active' : ''}
                    onClick={() => setShowSection('size')}>
                    Sizes
                </button>
            </div>
            <div className="page-content">
                {UnitsIndexComponent}
                {SizesIndexComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default UnitsSizes