import axios from "axios";

const Axios = axios
Axios.defaults.headers = {
    'token-remember': localStorage.getItem('userToken') ? 'yes' : 'no',
    'accept': 'application/json' // this header to solve 302 redirect when submit any invalid request
}

Axios.setAuthToken = token => {
    Axios.defaults.headers = {
        ...Axios.defaults.headers, authToken: token
    }
    return Axios
}

export default Axios