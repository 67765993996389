import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import Axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import Navbar from "../../../partials/Navbar"
import Pagination from "../../../Shared/Pagination"
import SearchBox from "../../../Shared/SearchBox"
import Table from "../../../Shared/Table"
import Thead from "../../../Shared/Thead"
import Add from "./Add"
import Delete from "./Delete"
import Edit from "./Edit"
import Row from "./Row"

const BannerAds = () => {
    const {clearUserData ,userToken} = useContext(AppContext)
    const alert = useAlert()
    const entityUrl = `${Config.apiBaseUrl}/gym-membership/banner-ads`
    const [keyword ,setKeyword] = useState('')
    const [headerTitle ,setHeaderTitle] = useState('')
    const [dataPerPage ,setDataPerPage] = useState(10)
    const [currentPage ,setCurrentPage] = useState(1)
    const [meta ,setMeta] = useState({links: [] ,last_page: 1})
    const [bannerAds ,setBannerAds] = useState([])
    const [invokeEdit ,setInvokeEdit] = useState(undefined)
    const [invokeDelete ,setInvokeDelete] = useState(undefined)
    const [invokeAdd ,setInvokeAdd] = useState(undefined)

    const elRef = useRef()

    const editBanner = (itemId) => {
        cancelModal()
        setInvokeEdit(itemId)
    }

    const deleteBanner = (id ,name) => {
        cancelModal()
        setInvokeDelete({id ,name})
    }

    const addBanner = () => {
        cancelModal()
        setInvokeAdd(true)
    }

    const cancelModal = () => {
        setInvokeDelete(undefined)
        setInvokeEdit(undefined)
        setInvokeAdd(undefined)
    }

    const axiosSource = Axios.CancelToken.source()
    const loadData = () => {
        Axios
        .setAuthToken(userToken)
        .get(`${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}` ,{cancelToken: axiosSource.token})
        .then(response => {
            const rolesCount = response.data.data.length
            setBannerAds(response.data.data)
            setHeaderTitle(`${rolesCount} Banner Ad${rolesCount > 1 ? 's' : ''}`)
            setMeta(response?.data?.meta)
            elRef.current.scrollIntoView()
        })
        .catch(err => {
            const error = {...err} ,response = error?.response?.data
            if (response?.message) alert.error(response.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[setBannerAds ,setHeaderTitle ,entityUrl ,alert ,dataPerPage ,currentPage ,userToken ,clearUserData])

    const searchTrigger = () => loadData()

    const reloadForAdd = () => {
        if (currentPage === 1) loadData()
        else setCurrentPage(1)
    }

    const reloadForDelete = () => {
        if (bannerAds?.length <= 1 && currentPage > 1) setCurrentPage(currentPage - 1)
        else loadData()
    }

    const headers = [
        {value: 'Photo'},
        {value: 'English Description'},
        {value: 'Ads For'},
        {value: "Date"},
        {value: 'Actions', className: 'actions'}
    ]

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Banner Ads"/>
            </div>
            <div className="page-content">
                <div className="page-section header-box" ref={elRef}>
                    <div className="row header">
                        <SearchBox searchTrigger={searchTrigger} setKeyword={setKeyword} keyword={keyword}/>
                        <button onClick={addBanner} className="btn btn-light">Add Banner</button>
                    </div>
                </div>
                <div className="page-section content">
                    <div className="table-section">
                        <div className="table-header">
                            <span className="table-header-title">{headerTitle}</span>
                            <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                                <button type="button" onClick={loadData}>
                                    <i className="fas fa-redo"></i>
                                </button>
                            </div>
                        </div>
                        <Table>
                            <Thead headers={headers}/>
                            <tbody>
                                { bannerAds.map(
                                    banner => <Row
                                        key={banner.id}
                                        banner={banner}
                                        editBanner={() => editBanner(banner?.id)}
                                        deleteBanner={() => deleteBanner(banner?.id ,banner?.description_en?.substring?.(0 ,50))}
                                    />
                                ) }
                            </tbody>
                        </Table>
                        <Pagination
                            changeCurrentPage={setCurrentPage} currentPage={currentPage}
                            dataPerPage={dataPerPage} changeDataPerPage={setDataPerPage} meta={meta}/>
                    </div>
                </div>
                {invokeAdd ? <Add entityUrl={entityUrl} cancel={cancelModal} reloadData={reloadForAdd}/> : null}
                {invokeDelete ? <Delete
                    entityUrl={entityUrl}
                    cancel={cancelModal}
                    reloadData={reloadForDelete}
                    id={invokeDelete?.id}
                    name={invokeDelete?.name}
                /> : null}
                {invokeEdit ? <Edit
                    id={invokeEdit}
                    entityUrl={entityUrl}
                    cancel={cancelModal}
                    reloadData={loadData}
                /> : null}
            </div>
        </div>
    )
}

export default BannerAds