import SearchBox from "../../../Shared/SearchBox"
import DropDown from "../../../FormShared/DropDown"

const Filters = (props) => {
    const types = [
        {name: 'Type' ,value: ''},
        {name: 'Problem' ,value: 'problem'},
        {name: 'Suggestion' ,value: 'suggestion'},
    ] ,
    from = [
        {name: 'From' ,value: ''},
        {name: 'Gym' ,value: 'gym'},
        {name: 'Website' ,value: 'website'},
    ]

    const changeType = (type) => {
        props?.setType(type)
    }

    const changeFrom = (from) => {
        props?.setFrom(from)
    }

    return (
        <div className="page-section header-box" ref={props?.elRef}>
            <div className="row header">
                <SearchBox searchTrigger={props?.searchTrigger} setKeyword={props?.setKeyword} keyword={props?.keyword}/>
                {
                    props?.forType ?
                    <DropDown
                        selectedName={props?.selectedType?.name}
                        list={types}
                        itemName="name"
                        select={changeType} />
                    :''
                }
                {
                    props?.forFrom ?
                    <DropDown
                        selectedName={props?.selectedFrom?.name}
                        list={from}
                        itemName="name"
                        select={changeFrom} />
                    : ''
                }
            </div>
        </div>
    )
}

export default Filters