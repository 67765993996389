const Pagination = (props) => {
    const currentPage  = props.currentPage ? props.currentPage : 1
    const paginationLinks = props?.meta?.links ,lastPage = props?.meta?.last_page
    try {
        delete paginationLinks[0]
        delete paginationLinks[paginationLinks.length - 1]
    } catch (err) {}

    const getNextPage = () => {
        if (lastPage > currentPage) props.changeCurrentPage(currentPage + 1)
    }

    const getPreviousPage = () => {
        if (currentPage > 1) props.changeCurrentPage(currentPage - 1)
    }

    const buttons = paginationLinks?.map(page => {
        return (
            page.label === '...' ? 
            <button
                key={Math.random()}>
                {page.label}
            </button>
            :
            <button
                key={Math.random()}
                onClick={() => props.changeCurrentPage(parseInt(page.label))}
                className={currentPage === parseInt(page.label) ? 'current' : ''}>
                {parseInt(page.label)}
            </button>
        )
    })

    return (
        <div className="controls">
            <div className="no-of-pages">
                <div className="show-button">
                    <span>Show:</span>
                    <div id="show" className="dropdown form-group custom-dropdown-js">
                        <button className="rounded-box" type="button"
                            style={{...props?.selectStyle}}
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span className="dropdown-title">{props.dataPerPage}</span>
                            <img alt="Gymme" src="/assets/svg/chevron-down-solid-dark.svg"/>
                        </button>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <button onClick={() => props.changeDataPerPage(10)} className="dropdown-item">10</button>
                            <button onClick={() => props.changeDataPerPage(25)} className="dropdown-item">25</button>
                            <button onClick={() => props.changeDataPerPage(50)} className="dropdown-item">50</button>
                            <button onClick={() => props.changeDataPerPage(100)} className="dropdown-item">100</button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pagination" className="pagination">
                <button
                    onClick={getPreviousPage}
                    id="pagination-previousButton"
                    className={currentPage === 1 ? "inactive-pagination-button" : "active-pagination-button"}>
                    <img src="/assets/svg/previous-button.svg" alt="Gymme"/>
                </button>
                <span>
                    {buttons}
                </span>
                <button
                    onClick={getNextPage}
                    id="pagination-nextButton"
                    className={currentPage === lastPage ? "inactive-pagination-button" : "active-pagination-button"}>
                    <img src="/assets/svg/next-button.svg" alt="Gymme"/>
                </button>
            </div>
        </div>
    )
}

export default Pagination