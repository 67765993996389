import { useState } from "react"
import Navbar from "../../../partials/Navbar"
import CrudIndexDS from "../../../Shared/CrudIndexDS"
import Subscribed from './Subscribed/Gyms'
import Free from './Free/Index'
import Requests from './MemberRequestGym/Index'

const Index = () => {
    const [showSection ,setShowSection] = useState('Subscribed')

    const [subscribedData ,setSubscribedData] = useState({...CrudIndexDS})
    const [freeData ,setFreeData] = useState({...CrudIndexDS})
    const [requestsData ,setRequestsData] = useState({...CrudIndexDS})

    const SubscribedComponent = <Subscribed
        visible={showSection === 'Subscribed'}
        data={subscribedData}
        setData={setSubscribedData}
    />,
    FreeComponent = <Free
        visible={showSection === 'Free'}
        data={freeData}
        setData={setFreeData}
    />,
    RequestsComponent = <Requests
        visible={showSection === 'Requested'}
        data={requestsData}
        setData={setRequestsData}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle={showSection}/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'Subscribed' ? 'active' : ''}
                    onClick={() => setShowSection('Subscribed')}>
                    Subscribed
                </button>
                <button className={showSection === 'Free' ? 'active' : ''}
                    onClick={() => setShowSection('Free')}>
                    Free
                </button>
                <button className={showSection === 'Requested' ? 'active' : ''}
                    onClick={() => setShowSection('Requested')}>
                    Requested
                </button>
            </div>
            <div className="page-content">
                {SubscribedComponent}
                {FreeComponent}
                {RequestsComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index