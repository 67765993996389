import { useState } from "react"
import Navbar from "../../../partials/Navbar"
import CrudIndexDS from "../../../Shared/CrudIndexDS"
import useQuery from "../../../Shared/useQuery"
import ListData from "./ListData"

const Index = (props) => {
    const params = useQuery() ,screen = params.get('screen') ,page = params.get('page')

    const currentPage =
        screen === 'gym' || screen === 'website' || screen === 'solved' || screen === 'spam' ?
        screen : 'gym'

    const [showSection ,setShowSection] = useState(currentPage)

    const changeShownIndex = (index) => {
        setShowSection(index)
    }

    const getCrudIndexDs = (dsForScreen) => {
        if (dsForScreen === screen) return {...CrudIndexDS ,currentPage: parseInt(page)}
        return {...CrudIndexDS}
    }

    const [gymIndexData ,setGymIndexData] = useState(getCrudIndexDs('gym'))
    const [websiteIndexData ,setWebsiteIndexData] = useState(getCrudIndexDs('website'))
    const [solvedIndexData ,setSolvedIndexData] = useState(getCrudIndexDs('solved'))
    const [spamIndexData ,setSpamIndexData] = useState(getCrudIndexDs('spam'))

    const GymIndexComponent = <ListData
        entityUrl='pendingGymList'
        filterByType={true}
        printUrl='printPendingGymList'
        includeSource={true}
        includeType={true}
        visible={showSection === 'gym'}
        data={gymIndexData}
        setData={setGymIndexData}
        changeStatus={true}
        screen='gym'
    />,
    WebsiteIndexComponent = <ListData
        entityUrl='pendingWebsiteList'
        filterByType={true}
        printUrl='printPendingWebsiteList'
        includeType={true}
        visible={showSection === 'website'}
        data={websiteIndexData}
        setData={setWebsiteIndexData}
        changeStatus={true}
        screen='website'
    />,
    SolvedIndexComponent = <ListData
        entityUrl='solvedList'
        filterByFrom={true}
        printUrl='printSolvedList'
        includeSource={true}
        visible={showSection === 'solved'}
        data={solvedIndexData}
        setData={setSolvedIndexData}
        changeStatus={false}
        screen='solved'
    />,
    SpamIndexComponent = <ListData
        entityUrl='spamList'
        filterByFrom={true}
        printUrl='printSpamList'
        includeSource={true}
        visible={showSection === 'spam'}
        data={spamIndexData}
        setData={setSpamIndexData}
        changeStatus={false}
        screen='spam'
    />


    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Help Center"/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'gym' ? 'active' : ''}
                    onClick={() => changeShownIndex('gym')}>
                    Gym
                </button>
                <button className={showSection === 'website' ? 'active' : ''}
                    onClick={() => changeShownIndex('website')}>
                    Website
                </button>
                <button className={showSection === 'solved' ? 'active' : ''}
                    onClick={() => changeShownIndex('solved')}>
                    Solved
                </button>
                <button className={showSection === 'spam' ? 'active' : ''}
                    onClick={() => changeShownIndex('spam')}>
                    Spam
                </button>
            </div>
            <div className="page-content">
                {GymIndexComponent}
                {WebsiteIndexComponent}
                {SolvedIndexComponent}
                {SpamIndexComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Index