import { useState } from "react"
import Navbar from "../../../partials/Navbar"
import CrudIndexDS from "../../../Shared/CrudIndexDS"
import ListData from "./ListData"

const Drinks = (props) => {
    const [showSection ,setShowSection] = useState('category')

    const [categoriesIndexData ,setCategoriesIndexData] = useState({...CrudIndexDS})
    const [brandIndexData ,setBrandIndexData] = useState({...CrudIndexDS})

    const CategoryIndexComponent = <ListData
        entity='category'
        entityUpper='Categories'
        printUrl='print'
        visible={showSection === 'category'}
        data={categoriesIndexData}
        setData={setCategoriesIndexData}
    />,
    BrandsIndexComponent = <ListData
        entity='brand'
        entityUpper='Brands'
        printUrl='print'
        visible={showSection === 'brands'}
        data={brandIndexData}
        setData={setBrandIndexData}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Drinks"/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'category' ? 'active' : ''}
                    onClick={() => setShowSection('category')}>
                    Category
                </button>
                <button className={showSection === 'brands' ? 'active' : ''}
                    onClick={() => setShowSection('brands')}>
                    Brands
                </button>
            </div>
            <div className="page-content">
                {CategoryIndexComponent}
                {BrandsIndexComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Drinks