import { useContext, useEffect, useRef, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import Config from "../../../../Config"
import AppContext from "../../../partials/AppContext"
import PrintButton from "../../../Shared/PrintButton"
import Pagination from "../../../Shared/Pagination"
import Filters from "./Filters"
import MessageActionConfirm from "./MessageActionConfirm"
import Row from './Row'
import Thead from "../../../Shared/Thead"
import Table from "../../../Shared/Table"

const ListData = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)

    const baseUrl = `${Config.apiBaseUrl}/help-center/help`
    const entityUrl = props.entityUrl
    const [type ,setType] = useState({name: 'Type' ,value: ''})
    const [from ,setFrom] = useState({name: 'From' ,value: ''})
    const [message ,setMessage] = useState(undefined)
    
    const data = props?.data?.list,
        dataPerPage = props?.data?.dataPerPage,
        currentPage = props?.data?.currentPage,
        meta = props?.data?.meta,
        headerTitle = props?.data?.headerTitle,
        keyword = props?.data?.keyword
    const axiosSource = axios.CancelToken.source()
    const loadData = () => {
        let url = `${baseUrl}/${entityUrl}?keyword=${keyword}&limit=${dataPerPage}&page=${currentPage}`
        if (props.filterByType) url += `&type=${type?.value}`
        if (props.filterByFrom) url += `&from=${from?.value}`

        axios
        .setAuthToken(userToken)
        .get(url ,{cancelToken: axiosSource.token})
        .then(response => {
            const data = response?.data?.data, meta = response?.data?.meta
            
            props?.setData({...props?.data,
                meta: meta,
                headerTitle: `${data?.length} ${data?.length > 1 ? 'Messages' : 'Message'}`,
                list: data
            })
            executeScroll()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    useEffect(() => {
        loadData()
        return () => axiosSource.cancel()
    },[entityUrl ,alert ,userToken ,clearUserData ,currentPage ,dataPerPage ,type ,from])

    const searchTrigger = () => {
        if (currentPage !== 1) props?.setData({...props?.data ,currentPage: 1})
        else loadData()
    }

    const elRef = useRef()
    const executeScroll = () => elRef.current.scrollIntoView()

    const changeStatus = (message ,isSolved = true) => setMessage({...message ,updateStatus: isSolved ? 1 : 2})

    const setKeyword = (value) => {
        props?.setData({...props?.data, keyword: value})
    }

    const headers = [ {value: 'Name'} ,{value: 'Title'} ,{value: 'Email'} ,{value: 'Phone Number'} ]
    if (props.includeSource) headers.push({value: 'Source'})
    if (props.includeType) headers.push({value: 'Type'})
    headers.push({value: 'Actions' ,className: 'actions'})

    const printClick = () => Config.printByToken(`${baseUrl}/${props.printUrl}?keyword=${keyword}` ,true ,userToken)
    
    return (
        <div style={{display: props.visible ? 'block' : 'none'}}>
            <Filters
                elRef={elRef}
                searchTrigger={searchTrigger}
                setKeyword={setKeyword}
                keyword={keyword}
                selectedType={type}
                setType={setType}
                forType={props.filterByType ? true : false}

                selectedFrom={from}
                setFrom={setFrom}
                forFrom={props.filterByFrom ? true : false}
                />
            
            <div className="page-section content">
                <div className="table-section">
                    <div className="table-header">
                        <span className="table-header-title">{headerTitle}</span>
                        <div style={{display: 'flex', flexDirection: 'row', gap: 20}}>
                            <button type="button" onClick={loadData}>
                                <i className="fas fa-redo"></i>
                            </button>
                            <PrintButton onClick={printClick}/>
                        </div>
                    </div>
                    <Table>
                        <Thead headers={headers}/>
                        <tbody>
                            {data?.map(message => <Row
                                screen={props?.screen}
                                page={currentPage}
                                key={message?.id} data={message}
                                sourceIncluded={props.includeSource ? true : false}
                                typeIncluded={props.includeType ? true : false}
                                changeStatus={props?.changeStatus}
                                invokeChangeStatus={changeStatus} reloadData={loadData}
                            />)}
                        </tbody>
                    </Table>
                    <Pagination meta={meta} currentPage={currentPage} dataPerPage={dataPerPage}
                        changeCurrentPage={(page) => props?.setData({...props?.data, currentPage: page})}
                        changeDataPerPage={(n) => props?.setData({...props?.data, dataPerPage: n})}/>
                </div>
            </div>
            <MessageActionConfirm
                message={message}
                actionMessage={message}
                setActionMessage={setMessage}
                baseUrl={baseUrl}
                id={message?.id}
                loadData={loadData} />
        </div>
    )
}

export default ListData