import { Fragment } from "react"
import Input from "../../../FormShared/Input"
import FieldError from "../../../FormShared/FieldError"

const FeatureInputs = (props) => {
    const features = props?.features
    const errors = props?.errors
    const setFeatures = props?.setFeatures
    const changeFeature = props?.changeFeature
    return (
        <Fragment>
            <div className="row" style={{margin: 0, display: 'flex', flexDirection: 'column'}}>
                <h5 style={{margin: 0}}>Features</h5>
                {errors ? <FieldError message={errors && Array.isArray(errors) ? errors.join(",") : errors}/> : ''}
            </div>
            {
                features?.map((feature ,index) => 
                    <div className="row" key={index} style={{position: 'relative'}}>
                        <div className="col-sm-6 pl-0">
                            <Input placeholder="English Feature" value={feature?.name_en}
                                onChange={(e) => changeFeature(index ,e.target.value ,'name_en')}/>
                        </div>
                        <div className="col-sm-6 pr-0">
                            <Input placeholder="الخاصية بالعربي" isAr={true} value={feature?.name_ar}
                                onChange={(e) => changeFeature(index ,e.target.value ,'name_ar')}/>
                        </div>
                        <i style={{position: 'absolute', top: -5, right: 5}}
                            onClick={() => {
                                const tempFeatures = features.filter((_feature ,_index) => _index !== index)
                                setFeatures([...tempFeatures])
                            }}
                            className="fa fa-times clickable red-text"></i>
                    </div>
                )
            }
            <button type="button" style={{fontSize: 14 ,color: 'rgba(224, 63, 63, 1)'}}
                onClick={() => setFeatures([...features ,{name_en: '' ,name_ar: ''}])}>
                + Add Feature
            </button>
        </Fragment>
    )
}

export default FeatureInputs