const BarInput = ({changeBar ,chosenBar}) => {
    return (
        <>
            <div style={{display: 'flex' ,flexDirection: 'row' ,marginTop: 5}}>
                <label class="checkbox" style={{width: '50%'}}>
                    <div class="checkbox-btn">
                        <input type="checkbox"
                            checked={chosenBar?.includes('Drinks')}
                            onChange={() => changeBar('Drinks')}/>
                        <span class="input-checkbox"></span>
                    </div>
                    <h6 class="checkbox-text">Drinks</h6>
                </label>
                <label class="checkbox">
                    <div class="checkbox-btn">
                        <input type="checkbox"
                            checked={chosenBar?.includes('Supplement')}
                            onChange={() => changeBar('Supplement')}/>
                        <span class="input-checkbox"></span>
                    </div>
                    <h6 class="checkbox-text">Supplement</h6>
                </label>
            </div>
            <label class="checkbox" style={{width: '50%'}}>
                <div class="checkbox-btn">
                    <input type="checkbox"
                        checked={chosenBar?.includes('Food')}
                        onChange={() => changeBar('Food')}/>
                    <span class="input-checkbox"></span>
                </div>
                <h6 class="checkbox-text">Food</h6>
            </label>
        </>
    )
}

export default BarInput