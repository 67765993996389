import { useContext, useState } from "react"
import { useAlert } from "react-alert"
import axios from "../../../../Axios"
import AppContext from "../../../partials/AppContext"

const SubscriptionCard = (props) => {
    const {userToken ,clearUserData} = useContext(AppContext)
    const alert = useAlert()
    const subscription = props.subscription
    const price = subscription?.offer ? subscription?.offer.price_after_discount : subscription?.price
    const oldPrice = subscription?.offer ? true : false
    const addOffer = () => {
        props?.setActionMenu(undefined)
        props?.setMembershipOffer({price: subscription?.price ,yearly_price: subscription?.yearly_price ,id: subscription.id ,status: true})
        props?.setOffer({id: undefined ,start: '' ,end: '' ,discount: '' ,yearly_discount: ''})
    }
    const editOffer = () => {
        props?.setActionMenu(undefined)
        props?.setMembershipOffer({price: subscription?.price ,yearly_price: subscription?.yearly_price ,id: subscription.id ,status: true})
        props?.setOffer({
            id: subscription?.offer?.id,
            start: subscription?.offer?.offer_start,
            end: subscription?.offer?.offer_end,
            discount: subscription?.offer?.discount_percentage,
            yearly_discount: subscription?.offer?.yearly_discount_percentage,
        })
    }

    const invokeEdit = () => {
        props?.setActionMenu(undefined)
        props?.edited(subscription.id)
    }

    const invokeDelete = () => {
        props?.setActionMenu(undefined)
        props?.deleted({name: subscription.name_en ,id: subscription.id})
    }

    const swapStatus = () => {
        if (!props.entityUrl) return
        axios
        .setAuthToken(userToken)
        .post(`${props.entityUrl}/${subscription.id}/changeStatus` ,{status: !subscription.status})
        .then(response => {
            props?.setActionMenu(undefined)
            alert.success(`Subscription ${subscription.name_en} status changed successfully`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const actAsFree = () => {
        axios
        .setAuthToken(userToken)
        .put(`${props.entityUrl}/act-as-free/${subscription.id}`)
        .then(response => {
            props?.setActionMenu(undefined)
            alert.success(`Subscription ${subscription.name_en} seted as free successfully`)
            props.cancel()
            props.reloadData()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            if (error?.response?.status === 401) clearUserData()
        })
    }

    const toggleMenuAction = () => {
        props?.setActionMenu(props.actionMenu === subscription.id ? undefined : subscription.id)
    }

    const cancelOffer = () => {
        props?.setActionMenu(undefined)
        props?.setOffer({
            name: `${subscription?.offer?.discount_percentage} % Extra Discount`,
            id: subscription?.offer?.id,
            start: subscription?.offer?.offer_start,
            end: subscription?.offer?.offer_end,
            discount: subscription?.offer?.discount_percentage,
            yearly_discount: subscription?.offer?.yearly_discount_percentage,
            isCanceled: true
        })
    }

    return (
        <div className="membership-card">
            <div className="membership-actions" style={{display: props?.actionMenu === subscription.id ? 'block' : 'none'}}>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={subscription?.offer ? editOffer : addOffer}>
                    <i className="fas fa-percentage"></i>
                    <span> {subscription?.offer ? 'Edit' : 'Add'} Offer </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={invokeEdit}>
                    <i className="fas fa-pen"></i>
                    <span> Edit </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={subscription?.offer ? cancelOffer : invokeDelete}>
                    <i className="fas fa-trash"></i>
                    <span> {subscription?.offer ? 'Cancel Offer' : 'Delete'} </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={swapStatus}>
                    {
                        subscription.status === 1 ?
                        <>
                            <i className="fas fa-minus-circle"></i>
                            <span> Disable it </span>
                        </>
                        :
                        <>
                            <i className="fas fa-check-circle"></i>
                            <span> Enable it </span>
                        </>
                    }
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={actAsFree}>
                    <i className="fas fa-check-circle"></i>
                    <span> Set as free </span>
                </button>
            </div>
            <img alt={subscription?.name_en} src="/assets/svg/offer.svg"
                className={`offer-badge en ${subscription?.offer ? '' : 'no-display'}`} />
            <button className="dots en" type="button" onClick={toggleMenuAction}>
                <img alt={subscription?.name_en} src="/assets/svg/dots.svg" className="full-img"/>
            </button>
            <span className="subscription-title"> { subscription?.name_en } </span>
            <span className="duration"> {subscription?.duration} Month </span>
            <div className="main-title">
                <span className="price"> {price} EGP </span>
                {
                    oldPrice ? <span className="old-price"> {subscription?.price} EGP </span> : ''
                }
            </div>

            {subscription?.act_as_free ? <span className="duration"> Free Subscription </span> : null}
            <ul className="options">
                <li> Yearly Price: { subscription?.yearly_price } EGP </li>
                <li> { subscription?.max_number_of_members } Members </li>
                <li> { subscription?.max_number_of_employees } Employees </li>
                <li> { subscription?.max_number_of_branches } Branches </li>
                {
                    subscription?.features?.map(feature => <li key={feature.id} > {feature.name_en} </li> )
                }
            </ul>
            <span className={`duration-left ${subscription?.offer ? '' : 'no-display'}`}> {subscription?.offer?.days_left} Days </span>
        </div>
    )
}

export default SubscriptionCard