import { useState } from "react"
import Navbar from "../../../partials/Navbar"
import CrudIndexDS from "../../../Shared/CrudIndexDS"
import ListData from "./ListData"

const Expenses = (props) => {
    const [showSection ,setShowSection] = useState('category')

    const changeShownIndex = (index) => {
        setShowSection(index)
    }

    const [categoryIndexData ,setCategoryIndexData] = useState({...CrudIndexDS})
    const [subCategoryIndexData ,setSubCategoryIndexData] = useState({...CrudIndexDS})

    const CategoryIndexComponent = <ListData
        entityUrl='categories'
        entityUpper='Category'
        visible={showSection === 'category'}
        data={categoryIndexData}
        setData={setCategoryIndexData}
        headers={[
            {value: 'Category'}, {value: 'Arabic Category'}, {value: 'Added Date'}, {value: 'Actions' ,className: 'actions'}
        ]}
    />,
    SubCategoryIndexComponent = <ListData
        entityUrl='sub-categories'
        entityUpper='Sub Category'
        visible={showSection === 'sub-category'}
        data={subCategoryIndexData}
        setData={setSubCategoryIndexData}
        headers={[
            {value: 'Sub Category'}, {value: 'Arabic Sub Category'}, {value: 'Category'},
            {value: 'Added Date'}, {value: 'Actions' ,className: 'actions'}
        ]}
    />

    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Expenses"/>
            </div>
            <div className="pages-navbar pad0">
                <button className={showSection === 'category' ? 'active' : ''}
                    onClick={() => changeShownIndex('category')}>
                    Category
                </button>
                <button className={showSection === 'sub-category' ? 'active' : ''}
                    onClick={() => changeShownIndex('sub-category')}>
                    Sub-Category
                </button>
            </div>
            <div className="page-content">
                {CategoryIndexComponent}
                {SubCategoryIndexComponent}
                <div className="page-footer">© All Right Reserved © {new Date().getFullYear()}</div>
            </div>
        </div>
    )
}

export default Expenses