import {md5} from 'hash-wasm'
import axios from './Axios'

const isProduction = process.env.NODE_ENV === 'production'

const Config = {
  publicUrl: isProduction
    ? "https://backend.gymmeclub.com"
    : "http://127.0.0.1:8000",
  apiBaseUrl: isProduction
    ? "https://backend.gymmeclub.com/api"
    : "http://127.0.0.1:8000/api",
  getHashForPrintToken: () => {
    const currentDate = new Date(),
      month =
        currentDate.getMonth() + 1 < 10
          ? `0${currentDate.getMonth() + 1}`
          : currentDate.getMonth() + 1,
      date =
        currentDate.getDate() < 10
          ? `0${currentDate.getDate()}`
          : currentDate.getDate(),
      hash = `${currentDate.getFullYear()}-${month}-${date}-print-token-auth`;
    return md5(hash);
  },
  globalPrint: (entityPrintUrl, userToken = undefined) => {
    Config.getHashForPrintToken().then((result) => {
      axios
        .post(`${Config.apiBaseUrl}/print-token`, { hash: result })
        .then((response) =>
          window.open(
            `${entityPrintUrl}?token=${
              userToken ? userToken : response.data.token
            }`,
            "_blank"
          )
        )
        .catch((err) => {
          console.log(err);
          alert("error");
        });
    });
  },
  filteredPrint: (url) => window.open(url, "_blank"),
  printByToken: (url, hasParameters = false, userToken) => {
    Config.getHashForPrintToken().then((result) => {
      axios
        .post(`${Config.apiBaseUrl}/print-token`, {
          hash: result,
          identifier: userToken,
        })
        .then((response) => {
          const token = response?.data?.token;
          let _url = url;
          if (hasParameters) _url += `&token=${token}`;
          else _url += `?token=${token}`;
          window.open(_url, "_blank");
        })
        .catch((err) => alert("Call technical support please."));
    });
  },
  getDaysArray: function(start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= end;
      dt.setDate(dt.getDate() + 1)
    ) {
      const d = new Date(dt);
      arr.push({ date: d, humanRead: d.toDateString() });
    }
    return arr;
  },
  monthsNameValue: [
    { name: "Month", value: "" },
    { name: "January", value: "01" },
    { name: "February", value: "02" },
    { name: "March", value: "03" },
    { name: "April", value: "04" },
    { name: "May", value: "05" },
    { name: "June", value: "06" },
    { name: "July", value: "07" },
    { name: "August", value: "08" },
    { name: "September", value: "09" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ],
  yearsNameValue: () => {
    const years = [{ name: "Year", value: "" }];
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      years.push({ name: currentYear - i, value: currentYear - i });
    }
    return years;
  },
};

export default Config
