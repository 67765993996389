import Navbar from '../partials/Navbar'

const Dashboard = (props) => {
    return (
        <div className="page">
            <div className="main-header">
                <Navbar navbarTitle="Dashboard"/>
            </div>
        </div>
    )
}

export default Dashboard