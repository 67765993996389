import { useState } from "react"
import FieldError from "../../FormShared/FieldError"
import Input from "../../FormShared/Input"
import ModalButtons from "../../FormShared/ModalButtons"
import ModalHeader from "../../FormShared/ModalHeader"

const BarForm = (props) => {
    const [disabled ,setDisabled] = useState(false)
    const changeNameEn = (e) => {
        props.setError('name_en' ,'')
        props.setData('name_en' ,e.target.value)
    }

    const changeNameAr = (e) => {
        props.setError('name_ar' ,'')
        props.setData('name_ar' ,e.target.value)
    }

    const confirmAction = async () => {
        setDisabled(true)
        props.confirmAction()
        setTimeout(() => setDisabled(false) ,3000)
    }

    return (
        <div id="forms">
            <div id="small-modal-form" className="form-container">
                <div className="contain">
                    <ModalHeader title={`${props.title ? props.title : 'Add'}`} cancel={props.cancel}/>
                    <form>
                        <div className="section">
                            <div className="row">
                                <div className="col-sm-12 pad0">
                                    <Input
                                        placeholder={props.placeholder_en ? props.placeholder_en : "English Name"}
                                        value={props?.data?.name_en}
                                        onChange={changeNameEn}/>
                                    {props?.error.name_en ? <FieldError message={props?.error.name_en}/> : ''}
                                </div>
                                <div className="col-sm-12 pad0 ar">
                                    <Input
                                        placeholder={props.placeholder_ar ? props.placeholder_ar : "الإسم بالعربي"}
                                        value={props?.data?.name_ar}
                                        onChange={changeNameAr}/>
                                    {props?.error.name_ar ? <FieldError ar={true} message={props?.error.name_ar}/> : ''}
                                </div>
                            </div>
                        </div>
                        <ModalButtons
                            disabled={disabled}
                            cancel={props.cancel}
                            confirm={confirmAction}
                            confirmLabel={props.confirmLabel ? props.confirmLabel : 'Add Data'} />
                    </form>
                </div>
            </div>
        </div>
    )
}

export default BarForm