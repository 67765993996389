import axios from "../../../../Axios"
import { useContext ,useState } from 'react'
import { useAlert } from "react-alert"
import AppContext from '../../../partials/AppContext'
import SuccessAlert from "../../../Shared/SuccessAlert"

const ApproveRequest = (props) => {
    const alert = useAlert()
    const {clearUserData ,userToken} = useContext(AppContext)
    const [disabled ,setDisabled] = useState(false)

    const confirmApproveRequest = () => {
        setDisabled(true)
        axios
        .setAuthToken(userToken)
        .put(`${props.entityUrl}/approve/${props.invokeApprove.requestId}`)
        .then(response => {
            setDisabled(false)
            alert.success(`Gym "${response?.data?.data?.name}" request is approved successfully`)
            props.cancel()
            props.loadData()
        })
        .catch(err => {
            setDisabled(false)
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <SuccessAlert
                disabled={disabled}
                heading={`Approve "${props.invokeApprove.requestName}"`}
                message={`Are you sure you want to accept this gym "${props.invokeApprove.requestName}" ? Make sure to receive the payment in cash`}
                confirmLabel="Yes Accept"
                confirm={() => confirmApproveRequest()}
                cancel={props.cancel}
            />
        </div>
    )
}

export default ApproveRequest