const ModalButtons = (props) => {
    return (
        <div className="confirm">
            <button type="button"
                className="btn btn-default"
                onClick={props.cancel}>
                Cancel
            </button> 
            <button type="button"
                className="btn btn-light"
                disabled={props.disabled ? props.disabled : false}
                onClick={props.confirm}>
                {props.confirmLabel ? props.confirmLabel : 'Confirm'}
            </button> 
        </div>
    )
}

export default ModalButtons