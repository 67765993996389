import { useContext } from "react"
import { useAlert } from "react-alert"
import axios from "../../../Axios"
import AppContext from "../../partials/AppContext"
import DangerousAlert from "../../Shared/DangerousAlert"

const BarDelete = (props) => {
    const alert = useAlert() ,{clearUserData ,userToken} = useContext(AppContext)
    const data = props?.data

    const confirmDelete = () => {
        axios
        .setAuthToken(userToken)
        .post(`${props?.url}/${data?.id}` ,{_method: 'DELETE'})
        .then(response => {
            alert.success(`${response?.data?.data?.name_en} deleted successfully`)
            props?.cancel()
            props?.loadData()
        })
        .catch(err => {
            const error = {...err}
            if (error?.response?.data?.message) alert.error(error?.response?.data?.message)
            else alert.error('Sorry we can`t proceed this process now ,try again later or call technical support')
            if (error?.response?.status === 401) clearUserData()
        })
    }

    return (
        <div id="forms">
            <DangerousAlert
                heading={`Delete ${props?.entity}`}
                message={`Are you sure you want to permanently delete this "${data?.name}"? Please note you can’t undo this action`}
                cancel={props?.cancel}
                confirm={confirmDelete}
                confirmLabel="Yes Delete"
            />
        </div>
    )
}

export default BarDelete