import { useContext } from 'react'
import Config from '../../Config'
import AppContext from '../partials/AppContext'

const PrintButton = (props) => {
    const {userToken} = useContext(AppContext)
    const url = props.entityPrintUrl
    const onClick = props.onClick ? props.onClick : () => Config.globalPrint(`${url}${props.notIncludePrint ? '' : '/print'}` ,userToken)
    return (
        <button
            className="btn btn-dark btn-small"
            onClick={onClick}>
            <img alt="Gymme" src="/assets/svg/print-solid.svg"/>
            <span>Print</span>
        </button>
    )
}

export default PrintButton