const RoleRow = (props) => {
    return (
        <tr>
            <td> {props.item.name_en} </td>
            <td> {props.item.name_ar} </td>
            <td> {props.item.created} </td>
            <td className="actions">
                <button onClick={() => props.editItem(props.item.id)}>
                    <img alt="Gymme" src="/assets/svg/pen-solid.svg"/>
                </button>
                <button onClick={() => props.deleteItem(props.item.id ,props.item.name_en)}>
                    <img alt="Gymme" src="/assets/svg/delete.svg"/>
                </button>
            </td>
        </tr>
    )
}

export default RoleRow