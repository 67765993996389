const DataLine = (props) => {
    return (
        <div className="details-data-line">
            <span className="details-data-line-title">{ props.title } :</span>
            <span className="details-data-line-data"> { props.value } </span>
            <button className="details-data-line-control" onClick={props.changeClick}>Change</button>
        </div>
    )
}

export default DataLine