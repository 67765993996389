const ClassCard = props => {
    const classData = props.classData

    const toggleMenuAction = () => {
        props?.setActionMenu(props.actionMenu === classData.id ? undefined : classData.id)
    }

    return (
        <div className="membership-card" style={{position: 'relative' ,paddingBottom: 75 ,width: 270}} key={Math.random()}>
            <div className="membership-actions" style={{display: props?.actionMenu === classData.id ? 'block' : 'none'}}>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={() => {
                    props?.setActionMenu(undefined)
                    props?.editClass(classData?.id)
                }}>
                    <i className="fas fa-pen"></i>
                    <span> Edit </span>
                </button>
                <button type="button" className="small-btn default-small-btn menu-action-hover" onClick={() => {
                    props?.setActionMenu(undefined)
                    props?.deleteClass(classData)
                }}>
                <i className="fas fa-trash"></i>
                    <span> Delete </span>
                </button>
            </div>
            <button className="dots en" type="button" onClick={toggleMenuAction}>
                <img alt={classData?.title} src="/assets/svg/dots.svg" className="full-img"/>
            </button>
            <span className="subscription-title"> { classData?.title } </span>
            <div className="main-title">
                <span className="price"> {classData?.price} EGP </span>
            </div>

            <ul className="options">
                <li> { classData?.number_of_members } Members </li>
                <li> Starts { classData?.class_start } </li>
                <li> End { classData?.class_end } </li>
                {
                    classData?.times?.map(
                        classTime => <li
                            key={classTime.id}
                        >
                            {`${classTime?.day} ${classTime?.start} ${classTime?.end}`}
                        </li>
                    )
                }
            </ul>
        </div>
    )
}

export default ClassCard